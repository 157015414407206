import React from 'react';
import RenderDemandes from '../../components/RenderDemandes/RenderDemandes.js';

const Demandes = (params = null) => {
    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Demandes</h5>
            </div>
            <RenderDemandes
                params={params}
            />
        </div>
    );
}

export default Demandes;
