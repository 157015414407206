import * as LaboService from '../../services/labos.service';
import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Form, FormGroup } from "react-bootstrap";

const SearchLabo = ({ onSubmitHandler, formLabel, defaultValue = null, multipleSelection = false, required = false }) => {
    const [labos, setLabos] = useState([]);
    const [isLabosSearchLoading, setIsLabosSearchLoading] = useState(false);
    const [labo, setLabo] = useState(multipleSelection ? (defaultValue || []) : defaultValue || null);
    const [validated, validate] = useState(false);

    const setSelectionOnChange = (options, callback) => {
        if (options instanceof Array) {
            if (multipleSelection) {
                callback(options);
            } else {
                callback(options.length > 0 ? options[0] : null);
            }
        } else {
            callback(null);
        }
    };

    const defaultFilterBy = () => true;

    const handlers = {
        search: (query, type) => {
            switch (type.toLowerCase()) {
                case 'labo':
                case 'labos':
                    setIsLabosSearchLoading(true);
                    LaboService.queryByName(query)
                        .then(setLabos)
                        .finally(() => setIsLabosSearchLoading(false));
                    break;
            }
        }
    };

    const renderDisplaySelection = (id, content) => {
        return (
            <div className="row">
                <span className="col-2 text-end fw-bold">({id})</span>
                <span className="col-10">&nbsp;{content}</span>
            </div>
        );
    };

    useEffect(() => {
        if (defaultValue && !labo) {
            setLabo(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (labo) {
            onSubmitHandler(labo);
        }
    }, [labo]);

    return (
        <FormGroup className="mb-1">
            <Form.Label>{formLabel}</Form.Label>
            <AsyncTypeahead id="create-or-update-form-labos-search" name="IdLab" emptyLabel="Aucun résultat."
                placeholder="Rechercher par nom" searchText="..." options={labos}
                onChange={(opts) => setSelectionOnChange(opts, setLabo)}
                labelKey="nom" isLoading={isLabosSearchLoading} filterBy={defaultFilterBy}
                onSearch={(q) => handlers.search(q, 'labos')} required={required}
                renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], opt['nom'])}
                inputProps={{
                    className: "searchLaboInput",
                    required: required ? (multipleSelection ? (labo.length === 0) : (labo === null)) : false // Required if no selection (because the insert or update form can't handle object with null value)
                }}
                defaultSelected={defaultValue ? (multipleSelection ? defaultValue : [defaultValue]) : []}
                multiple={multipleSelection} />
        </FormGroup>
    );
};

export default SearchLabo;
