import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import SearchCat from '../SearchCat/SearchCat';
import { axiosCore } from '../../utils/http-core-service';
import OpenLink from '../OpenLink/OpenLink';

const CouleursChatons = () => {
    const [etalon, setEtalon] = useState(null);
    const [femelle, setFemelle] = useState(null);
    const [nbGeneration, setNbGeneration] = useState(5);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callCouleursChatons = (e, etalon, femelle, nbGeneration) => {
        e.preventDefault();
        try {
            const identP = etalon.numTatouage;
            const identM = femelle.numTatouage;
            setLoading(true);
            axiosCore.get(`https://api2.loof.asso.fr/api/v2-couleurs-chatons/${identP}/${identM}?nbGen=${nbGeneration}`)
                .then(response => {
                    setResult(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    setError(err.response.data.error);
                    setLoading(false);
                });
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (result) {
            console.log(result);
        }
    }, [result]);

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3 justify-content-between'>
                <div className='d-flex align-items-center'>
                    <h5 className='mb-0'>
                        <NavLink className='link' to={`/dossiers`}>Dossiers</NavLink>
                    </h5>
                    <div className='mx-2'> &gt; </div>
                    <h5 className='mb-0'>Service couleurs chatons</h5>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    <NavLink className='btn btn-outline-primary' to={`/dossiers`}>Retour</NavLink>
                </div>
            </div>
            <div>
                <small className="text-danger">Les deux parents doivent avoir un numéro d'identification</small>
                <form className="row g-3 whiteBox searchBox align-items-start" onSubmit={(e) => callCouleursChatons(e, etalon, femelle, nbGeneration)}>
                    <div className="col-md-4">
                        <SearchCat
                            onSubmitHandler={setEtalon}
                            formLabel="Rechercher un étalon"
                            name={true}
                            id={true}
                            numOrigin={true}
                            numIdentification={true}
                            sex='M'
                        />
                    </div>
                    <div className="col-md-4">
                        <SearchCat
                            onSubmitHandler={setFemelle}
                            formLabel="Rechercher une femelle"
                            name={true}
                            id={true}
                            numOrigin={true}
                            numIdentification={true}
                            sex='F'
                        />
                    </div>
                    <div className="col-md-4 d-flex flex-column gap-2">
                        <label htmlFor="nbGenRange" className="form-label">Sélectionner le nombre de génération (2 à 5)</label>
                        <input type="range" className="form-range" min="2" max="5" id="nbGenRange" value={nbGeneration} onChange={(e) => setNbGeneration(e.target.value)} />
                        <small>{nbGeneration} générations</small>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        {loading && <span className="spinner-border spinner-border"></span>}
                        &emsp;<button type="submit" className="btn btn-primary">Calculer</button>
                    </div>
                </form>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            {result && result.error && <div className="alert alert-danger">{result.error}</div>}
            {result && result.pere && result.mere && result.result && <div className="whiteBox mt-3 d-flex flex-column gap-3 align-items-start">
                <h5>Résultat sur {result.nbGen} générations</h5>
                <div className="d-flex gap-3 justify-content-evenly w-100">
                    <div>
                        <h6>Etalon</h6>
                        <p>Nom: {result.pere.nom}</p>
                        <p>Identification: {result.pere.identification}</p>
                        <p>Numéro d'origine: {result.pere.numOrigin}</p>
                        <p>Date de naissance: {result.pere.dateNaissance}</p>
                        <p>Race: {result.pere.race.intitule}</p>
                        <p>Robe: {result.pere.robe.intitule}</p>
                        <p>EMS: {result.pere.EMS}</p>
                        <OpenLink
                            url={`/chats/${etalon.id}`}
                            content={`Voir la fiche de l'étalon`}
                        />
                    </div>
                    <div>
                        <h6>Femelle</h6>
                        <p>Nom: {result.mere.nom}</p>
                        <p>Identification: {result.mere.identification}</p>
                        <p>Numéro d'origine: {result.mere.numOrigin}</p>
                        <p>Date de naissance: {result.mere.dateNaissance}</p>
                        <p>Race: {result.mere.race.intitule}</p>
                        <p>Robe: {result.mere.robe.intitule}</p>
                        <p>EMS: {result.mere.EMS}</p>
                        <OpenLink
                            url={`/chats/${femelle.id}`}
                            content={`Voir la fiche de la femelle`}
                        />
                    </div>
                </div>
                <hr className='w-100' />
                <h6>Résultats</h6>
                {result.additionalInfo && <p><b>Informations supplémentaires : </b><br /><i>{result.additionalInfo}</i></p>}
                <div>
                    {result.result.error && <p>{result.result.error}</p>}
                    {!result.result.error && result.result.map((res, index) => {
                        return (
                            <div key={index} className="border p-2 my-2">
                                <p>Couleur possible : <b>{res.possibleColor}</b></p>
                                <p>EMS : <b>[{res.EMS}]</b></p>
                                {res.additionalInfo && <p>Informations supplémentaires : <b>{res.additionalInfo}</b></p>}
                                {res.fatherCouldCarry && res.fatherCouldCarry.length > 0 && <p>Le père pourrait être porteur de : <br />{res.fatherCouldCarry.map((carry, index) => {
                                    return (
                                        <b key={index}>&emsp;&emsp;{carry.acronym} : {carry.name} [{carry.locus}]<br /></b>
                                    );
                                })}</p>}
                                {res.motherCouldCarry && res.motherCouldCarry.length > 0 && <p>La mère pourrait être porteuse de : <br />{res.motherCouldCarry.map((carry, index) => {
                                    return (
                                        <b key={index}>&emsp;&emsp;{carry.acronym} : {carry.name} [{carry.locus}]<br /></b>
                                    );
                                })}</p>}
                            </div>
                        );
                    })}
                </div>
            </div>}
        </div>
    );
}

export default CouleursChatons;
