import React, { useEffect } from 'react';
import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../../../components/IsLoading/IsLoading';
import { axiosCore } from '../../../../utils/http-core-service';
import TableTemplate from '../../../../components/TableTemplate/TableTemplate';
import OpenLink from '../../../../components/OpenLink/OpenLink';

const Portees = () => {
    const { id } = useParams();
    const [chat, setChat] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [children, setChildren] = useState([]);
    const [parent, setParent] = useState({});

    const askChildren = async () => {
        try {
            const response = await axiosCore.get(`/api/reproducteur/${id}/portees`);
            setChildren(response.data.children);
            setParent(response.data.parent);
            console.log("response.data: ", response.data);
            setIsLoading(false);
        } catch (error) {
            if (!error.response) {
                console.log("Error fetching data: ", error.message);
                setIsLoading(false);
                setError(error.message);
                return;
            }
            console.log("Error fetching data: ", error.response.data.error);
            console.log("Error fetching data: ", error);
            setIsLoading(false);
            setError(error.response.data.error);
        }
    }

    useEffect(() => {
        if (isNaN(id)) {
            console.log("id is null");
            setError("Ce chat n'existe pas");
            setIsLoading(false);
            return;
        }
        askChildren();
    }, []);

    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && children && !error &&
                <>
                    <div className="fil-arenae d-flex align-items-center mb-3 justify-content-between">
                        <div className="d-flex align-items-center">
                            <h5 className='mb-0 d-flex align-items-center'>
                                <NavLink className='link' to={`/chats`}>Chat</NavLink>
                                <div className='mx-2'> &gt; </div>
                                <NavLink className='link' to={`/chats/${id}`}>Fiche Chat</NavLink>
                            </h5>
                            <div className='mx-2'> &gt; </div>
                            <h5 className='mb-0'>Portées</h5>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <NavLink className="btn btn-outline-primary" to={`/chats/${id}/portees`}>Portées</NavLink>
                            <NavLink className="btn btn-outline-danger" to={`/chats/${id}/`}>Retour</NavLink>
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <div id='topBox' className="whiteBox">
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h5><b>Nom du chat / N°origine</b> {parent.fullName} / {parent.numOrigin}</h5>
                                <h6 className='mb-1'><b>ID: </b> {parent.id}</h6>
                                <h6 className='mb-1'><b>Création en date du : </b> {parent.dateSaisieChat} | {parent.prenomCreateur} {parent.nomCreateur} <b>({parent.initialesCreateur})</b></h6>
                                <h6 className='mb-0'><b>Modification en date du : </b> {parent.dateMajChat} | {parent.prenomMaj} {parent.nomMaj} <b>({parent.initialesMaj})</b></h6>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column gap-2">
                        <div className="whiteBox">
                            <h5 className='mb-2'><b>Chatons de {parent.fullName}</b></h5>
                            <TableTemplate
                                theadData={[
                                    { label: 'Nom', key: 'fullName', render: (value, row) => <OpenLink url={`/chats/${row.id}`} content={value} /> },
                                    { label: 'Pedigree', key: 'numOrigin'},
                                    { label: 'Date de naissance', key: 'dateNaissance'},
                                    { label: 'Dossier', key: 'numDossier'},
                                    { label: 'Sexe', key: 'sexe'},
                                    { label: 'Race', key: 'intituleRa'},
                                    { label: 'Robe', key: 'intituleRo'},
                                    { label: 'Robe autre parent', key: 'robeOtherParent'},
                                    { label: 'Reproducteur', key: 'repro', render: (value) => value ? 'Oui' : ''},
                                ]}
                                tbodyData={children[0]?.id ? children : []}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default Portees;
