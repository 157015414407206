import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import LogoLOOF from './assets/logoLOOF.png';

const Header = ({ pedigreeInfos, pedColor, pedType }) => {
  const titre = (!isEmpty(pedigreeInfos) && pedigreeInfos.titre) || '';
  const nom = (!isEmpty(pedigreeInfos) && pedigreeInfos.nomChat) || '';
  const qualification = (!isEmpty(pedigreeInfos) && pedigreeInfos.qualification) || '';
  const raceChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.intituleRace) || '';
  const couleurChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.intituleRobe) || '';
  const commentairesRobe = (!isEmpty(pedigreeInfos) && pedigreeInfos.commentairesRobe) || '';
  const codeChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.codeEMS) || '';
  const dob = (!isEmpty(pedigreeInfos) && pedigreeInfos.dateNaissanceChat) || '';
  const sexeChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.sexeChat) || '';
  const numLOOF = (!isEmpty(pedigreeInfos) && pedigreeInfos.numOrigineChat) || '';
  const id = (!isEmpty(pedigreeInfos) && pedigreeInfos.numIdentificationChat) || '';
  const couleurYeux = (!isEmpty(pedigreeInfos) && pedigreeInfos.couleurYeux) || '';

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      width: '100%',
    },
    logo: {
      marginRight: 5,
    },
    detailColumn: {
      width: '100%',
    },
    beforeNom: {
      color: pedColor,
      fontSize: 10,
      textTransform: 'capitalize',
    },
    name: {
      fontSize: 13,
      fontFamily: 'Lato Bold',
      textTransform: 'uppercase',
      marginBottom: 5,
    },
    titre: {
      fontSize: 10,
      justifySelf: 'flex-end',
      marginBottom: 5,
    },
    qualification: {
      fontFamily: 'Lato Bold',
      color: 'red',
      fontSize: 11,
      textTransform: 'uppercase',
      alignSelf: 'flex-end',
      marginBottom: 5,
    },
    generalInfos: {
      flexDirection: 'row',
      gap: 10,
      width: '100%',
    },
    section: {
      marginBottom: 10,
    },
    row: {
      flexDirection: 'row',
      fontSize: 10,
      marginBottom: 2,
      flexWrap: 'wrap',
    },
    title: {
      color: pedColor,
      fontFamily: 'Lato Bold',
      marginRight: 5,
    },
    value: {
      fontFamily: 'Lato Bold',
    },
    spaceBetween: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    link: {
      color: 'black',
      textDecoration: 'none',
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.logo}>
        <Image src={LogoLOOF} style={{ height: 75 }} />
      </View>
      <View style={styles.detailColumn}>
        <View style={styles.spaceBetween}>
          <Text style={styles.name}><Text style={styles.beforeNom}>Nom / Name : </Text>{nom}</Text>
        </View>
        <View style={styles.generalInfos}>
          <View style={{...styles.section, width: '59%'}}>
            <View style={styles.row}>
              <Text style={styles.title}>Race / Breed :</Text>
              <Text style={styles.value}>{raceChat}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.title}>Sexe / Sex :</Text>
              <Text style={styles.value}>{sexeChat}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.title}>Couleur / Colour :</Text>
              <Text style={styles.value}>{couleurChat} {commentairesRobe && `(${commentairesRobe})`}</Text>
            </View>
          </View>
          <View style={{...styles.section, width: '22%'}}>
            <View style={styles.row}>
              <Text style={styles.title}>N° :</Text>
              <Text style={styles.value}>{numLOOF}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.title}>Id :</Text>
              <Text style={styles.value}>{id.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.title}>Yeux / Eyes :</Text>
              <Text style={styles.value}>{couleurYeux.charAt(0).toUpperCase() + couleurYeux.toLowerCase().slice(1)}</Text> {/* capitalize first letter */}
            </View>
          </View>
          <View style={{...styles.section, width: '19%'}}>
            <View style={styles.row}>
              {sexeChat === 'Mâle' ? (
                  <Text style={styles.title}>Né le / DoB :</Text>
              ) : (
                  <Text style={styles.title}>Née le / DoB :</Text>
              )}
              <Text style={styles.value}>{dob}</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.row}>
                <Text style={styles.title}>Code :</Text>
                <Text style={styles.value}>{codeChat}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Header;
