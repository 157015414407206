import React from 'react';
import './Header.css';
import {ReactComponent as Logo} from '../../assets/images/logoLOOF.svg';
import {ReactComponent as LogoRoyalCanin} from '../../assets/images/logoRoyalCanin.svg';
import { decodeToken } from "react-jwt";
import { useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import {axiosCore} from "../../utils/http-core-service";

const Header = () => {
    const token = localStorage.getItem('token');
    const user = decodeToken(token);

    const [isUserDropdownActive, setIsUserDropdownActive] = React.useState(false);
    const handleUserDropdown = () => {
        setIsUserDropdownActive(!isUserDropdownActive)
    };

    const handleLogout = async () => {
        try {
            const response = await axiosCore.post(`/${process.env.REACT_APP_API_ROUTE_LOGOUT}`, {
                token: localStorage.getItem('token'),
                user: user.username,
            });
            localStorage.removeItem('token');
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const handleLeftNavDropdown = ($id, $dataPath) => {
        const allDropdowns = document.querySelectorAll('.dropdown');
        allDropdowns.forEach(dropdown => {
            if (dropdown.id !== $id) {
                dropdown.classList.remove('show');
                dropdown.previousElementSibling.classList.remove('dropdown-selected');
            }
        });
        document.getElementById($id).classList.toggle('show');
        document.querySelector(`button[data-path="${$dataPath}"]`).classList.toggle('dropdown-selected');
    };

    useEffect(() => {
        const path = window.location.pathname;
        console.log(path);
        const activeButton = document.querySelector(`button[data-path="${path}"]`);
        if (activeButton) {
            if (activeButton.classList.contains('dropdown-content')) {
                activeButton.parentElement.classList.add('show');
                activeButton.parentElement.previousElementSibling.classList.add('dropdown-selected');
            }
            activeButton.classList.add('active');
        } else if (path === '/') {
            document.querySelector(`a[data-path="/accueil"]`).classList.add('active');
        }
    }, []);

    return (
        <div>
            <div className="topNav">
                <div className="user">
                    <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 448 512" onClick={handleUserDropdown} style={{cursor: 'pointer'}}><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                    {isUserDropdownActive && (
                        <div className="dropdownContent">
                            <p>Connecté en tant que <b>{user.username}</b></p>
                            <button onClick={handleLogout} className="logout">Déconnexion</button>
                        </div>
                    )}
                </div>
            </div>
            <div className="leftNav">
                <div className="logo">
                    <a href="/">
                        <Logo />
                    </a>
                </div>
                <div className="menu">
                    <NavLink to={`/accueil`} data-path="/accueil" className={({ isActive }) => isActive ? "active menuItem": "menuItem"}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg>
                        <p>Accueil</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/demandes`} data-path="/demandes">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/></svg>
                        <p>Demandes</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/eleveurs`} data-path="/eleveurs">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z"/></svg>
                        <p>Éleveurs</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/affixes`} data-path="/affixes">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M192 128c0-17.7 14.3-32 32-32s32 14.3 32 32v7.8c0 27.7-2.4 55.3-7.1 82.5l-84.4 25.3c-40.6 12.2-68.4 49.6-68.4 92v71.9c0 40 32.5 72.5 72.5 72.5c26 0 50-13.9 62.9-36.5l13.9-24.3c26.8-47 46.5-97.7 58.4-150.5l94.4-28.3-12.5 37.5c-3.3 9.8-1.6 20.5 4.4 28.8s15.7 13.3 26 13.3H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H460.4l18-53.9c3.8-11.3 .9-23.8-7.4-32.4s-20.7-11.8-32.2-8.4L316.4 198.1c2.4-20.7 3.6-41.4 3.6-62.3V128c0-53-43-96-96-96s-96 43-96 96v32c0 17.7 14.3 32 32 32s32-14.3 32-32V128zm-9.2 177l49-14.7c-10.4 33.8-24.5 66.4-42.1 97.2l-13.9 24.3c-1.5 2.6-4.3 4.3-7.4 4.3c-4.7 0-8.5-3.8-8.5-8.5V335.6c0-14.1 9.3-26.6 22.8-30.7zM24 368c-13.3 0-24 10.7-24 24s10.7 24 24 24H64.3c-.2-2.8-.3-5.6-.3-8.5V368H24zm592 48c13.3 0 24-10.7 24-24s-10.7-24-24-24H305.9c-6.7 16.3-14.2 32.3-22.3 48H616z"/></svg>
                        <p>Affixes</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/certificats`} data-path="/certificats">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M211 7.3C205 1 196-1.4 187.6 .8s-14.9 8.9-17.1 17.3L154.7 80.6l-62-17.5c-8.4-2.4-17.4 0-23.5 6.1s-8.5 15.1-6.1 23.5l17.5 62L18.1 170.6c-8.4 2.1-15 8.7-17.3 17.1S1 205 7.3 211l46.2 45L7.3 301C1 307-1.4 316 .8 324.4s8.9 14.9 17.3 17.1l62.5 15.8-17.5 62c-2.4 8.4 0 17.4 6.1 23.5s15.1 8.5 23.5 6.1l62-17.5 15.8 62.5c2.1 8.4 8.7 15 17.1 17.3s17.3-.2 23.4-6.4l45-46.2 45 46.2c6.1 6.2 15 8.7 23.4 6.4s14.9-8.9 17.1-17.3l15.8-62.5 62 17.5c8.4 2.4 17.4 0 23.5-6.1s8.5-15.1 6.1-23.5l-17.5-62 62.5-15.8c8.4-2.1 15-8.7 17.3-17.1s-.2-17.4-6.4-23.4l-46.2-45 46.2-45c6.2-6.1 8.7-15 6.4-23.4s-8.9-14.9-17.3-17.1l-62.5-15.8 17.5-62c2.4-8.4 0-17.4-6.1-23.5s-15.1-8.5-23.5-6.1l-62 17.5L341.4 18.1c-2.1-8.4-8.7-15-17.1-17.3S307 1 301 7.3L256 53.5 211 7.3z"/></svg>
                        <p>Certificats</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/dossiers`} data-path="/dossiers">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z"/></svg>
                        <p>Dossiers</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/chats`} data-path="/chats">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M320 192h17.1c22.1 38.3 63.5 64 110.9 64c11 0 21.8-1.4 32-4v4 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V339.2L280 448h56c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-53 0-96-43-96-96V192.5c0-16.1-12-29.8-28-31.8l-7.9-1c-17.5-2.2-30-18.2-27.8-35.7s18.2-30 35.7-27.8l7.9 1c48 6 84.1 46.8 84.1 95.3v85.3c34.4-51.7 93.2-85.8 160-85.8zm160 26.5v0c-10 3.5-20.8 5.5-32 5.5c-28.4 0-54-12.4-71.6-32h0c-3.7-4.1-7-8.5-9.9-13.2C357.3 164 352 146.6 352 128v0V32 12 10.7C352 4.8 356.7 .1 362.6 0h.2c3.3 0 6.4 1.6 8.4 4.2l0 .1L384 21.3l27.2 36.3L416 64h64l4.8-6.4L512 21.3 524.8 4.3l0-.1c2-2.6 5.1-4.2 8.4-4.2h.2C539.3 .1 544 4.8 544 10.7V12 32v96c0 17.3-4.6 33.6-12.6 47.6c-11.3 19.8-29.6 35.2-51.4 42.9zM432 128a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm48 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>
                        <p>Chats</p>
                    </NavLink>
                    <button className="menuItem" onClick={() => handleLeftNavDropdown('dropdown-referentiels', "/referentiels")} data-path="/referentiels">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M249.6 471.5c10.8 3.8 22.4-4.1 22.4-15.5V78.6c0-4.2-1.6-8.4-5-11C247.4 52 202.4 32 144 32C93.5 32 46.3 45.3 18.1 56.1C6.8 60.5 0 71.7 0 83.8V454.1c0 11.9 12.8 20.2 24.1 16.5C55.6 460.1 105.5 448 144 448c33.9 0 79 14 105.6 23.5zm76.8 0C353 462 398.1 448 432 448c38.5 0 88.4 12.1 119.9 22.6c11.3 3.8 24.1-4.6 24.1-16.5V83.8c0-12.1-6.8-23.3-18.1-27.6C529.7 45.3 482.5 32 432 32c-58.4 0-103.4 20-123 35.6c-3.3 2.6-5 6.8-5 11V456c0 11.4 11.7 19.3 22.4 15.5z"/></svg>
                        <p>Référentiels</p>
                    </button>
                    <div id="dropdown-referentiels" className="dropdown" dropdown-parent="/referentiels">
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/referentiels/livres-genealogiques`}  data-path="/referentiels/livres-genealogiques">
                            <p>LG</p>
                        </NavLink>
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/referentiels/pays`}  data-path="/referentiels/pays">
                            <p>Pays</p>
                        </NavLink>
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/referentiels/yeux`}  data-path="/referentiels/yeux">
                            <p>Yeux</p>
                        </NavLink>
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/referentiels/titres`}  data-path="/referentiels/titres">
                            <p>Titres</p>
                        </NavLink>
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/referentiels/maladies`}  data-path="/referentiels/maladies">
                            <p>Maladies</p>
                        </NavLink>
                    </div>
                    <button className="menuItem" onClick={() => handleLeftNavDropdown('dropdown-expositions', "/expositions")} data-path="/expositions">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"/></svg>
                        <p>Expositions</p>
                    </button>
                    <div id="dropdown-expositions" className="dropdown" dropdown-parent="/expositions">
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/expositions/expositions`}  data-path="/expositions/expositions">
                            <p>Expositions</p>
                        </NavLink>
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/expositions/clubs`}  data-path="/expositions/clubs">
                            <p>Clubs</p>
                        </NavLink>
                    </div>
                    <button className="menuItem" onClick={() => handleLeftNavDropdown('dropdown-adherents', "/adherents")} data-path="/adherents">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H544c53 0 96-43 96-96V96c0-53-43-96-96-96H96zM64 96c0-17.7 14.3-32 32-32H544c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V96zm159.8 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 309.3c0 14.7 11.9 26.7 26.7 26.7h56.1c8-34.1 32.8-61.7 65.2-73.6c-7.5-4.1-16.2-6.4-25.3-6.4H149.3C119.9 256 96 279.9 96 309.3zM461.2 336h56.1c14.7 0 26.7-11.9 26.7-26.7c0-29.5-23.9-53.3-53.3-53.3H421.3c-9.2 0-17.8 2.3-25.3 6.4c32.4 11.9 57.2 39.5 65.2 73.6zM372 289c-3.9-.7-7.9-1-12-1H280c-4.1 0-8.1 .3-12 1c-26 4.4-47.3 22.7-55.9 47c-2.7 7.5-4.1 15.6-4.1 24c0 13.3 10.7 24 24 24H408c13.3 0 24-10.7 24-24c0-8.4-1.4-16.5-4.1-24c-8.6-24.3-29.9-42.6-55.9-47zM512 176a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM320 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"/></svg>
                        <p>Adhérents</p>
                    </button>
                    <div id="dropdown-adherents" className="dropdown" dropdown-parent="/adherents">
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/adherents/clubs`}  data-path="/adherents/clubs">
                            <p>Clubs</p>
                        </NavLink>
                    </div>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/races`} data-path="/races">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M18.137 21.824c-5.645 1.549-9.088 7.132-8.092 10.944.998 3.813 4.766-.53 10.411-2.08 5.643-1.551 11.024.281 10.028-3.532-.997-3.813-6.702-6.883-12.347-5.332m-4.369-1.777c1.701-.632 1.918-4.417 1.012-6.969-.906-2.55-3.02-4.103-4.721-3.469-1.7.635-2.344 3.217-1.438 5.767.906 2.551 3.445 5.306 5.147 4.671m6.94-1.969c1.803-.162 2.967-3.759 2.736-6.465-.23-2.704-1.879-4.763-3.682-4.604-1.803.163-3.079 2.486-2.848 5.19.232 2.704 1.989 6.041 3.794 5.879m7.97-7.914c-1.809 0-3.275 2.2-3.275 4.914 0 2.716 1.467 6.195 3.275 6.195 1.81 0 3.276-3.479 3.276-6.195.001-2.715-1.466-4.914-3.276-4.914m-25.36 6.963c-1.568.926-1.766 3.584-.439 5.936 1.324 2.35 4.293 4.612 5.86 3.685 1.568-.927 1.141-4.693-.184-7.043-1.325-2.352-3.671-3.505-5.237-2.578m42.547 27.402c-5.645-1.551-11.352 1.52-12.348 5.332-.998 3.813 4.385 1.981 10.029 3.532 5.645 1.55 9.412 5.893 10.408 2.08.998-3.812-2.446-9.394-8.089-10.944m4.369-1.774c1.699.632 4.24-2.123 5.146-4.674.906-2.551.262-5.133-1.438-5.767-1.701-.635-3.813.919-4.719 3.47s-.69 6.336 1.011 6.971m-6.939-1.973c1.803.162 3.561-3.174 3.791-5.878s-1.043-5.028-2.846-5.19c-1.803-.16-3.451 1.9-3.684 4.605-.23 2.705.936 6.301 2.739 6.463m-7.973 3.196c1.811 0 3.277-3.479 3.277-6.195 0-2.714-1.467-4.914-3.277-4.914s-3.277 2.2-3.277 4.916c0 2.714 1.469 6.193 3.277 6.193m25.361-4.146c-1.568-.927-3.912.226-5.236 2.577-1.323 2.351-1.751 6.117-.186 7.044 1.568.927 4.537-1.335 5.861-3.685 1.324-2.351 1.127-5.009-.439-5.936"/></svg>
                        <p>Races</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/robes`} data-path="/robes">
                        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 1.003a7 7 0 0 0-7 7v.43c.09 1.51 1.91 1.79 3 .7a1.87 1.87 0 0 1 2.64 2.64c-1.1 1.16-.79 3.07.8 3.2h.6a7 7 0 1 0 0-14l-.04.03zm0 13h-.52a.58.58 0 0 1-.36-.14.56.56 0 0 1-.15-.3 1.24 1.24 0 0 1 .35-1.08 2.87 2.87 0 0 0 0-4 2.87 2.87 0 0 0-4.06 0 1 1 0 0 1-.9.34.41.41 0 0 1-.22-.12.42.42 0 0 1-.1-.29v-.37a6 6 0 1 1 6 6l-.04-.04zM9 3.997a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 7.007a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-7-5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm7-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM13 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>
                        <p>Robes</p>
                    </NavLink>
                    <button className="menuItem" onClick={() => handleLeftNavDropdown('dropdown-laboratoires', "/laboratoires")} data-path="/laboratoires">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 0H160 128C110.3 0 96 14.3 96 32s14.3 32 32 32V196.8c0 11.8-3.3 23.5-9.5 33.5L10.3 406.2C3.6 417.2 0 429.7 0 442.6C0 480.9 31.1 512 69.4 512H378.6c38.3 0 69.4-31.1 69.4-69.4c0-12.8-3.6-25.4-10.3-36.4L329.5 230.4c-6.2-10.1-9.5-21.7-9.5-33.5V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288zM192 196.8V64h64V196.8c0 23.7 6.6 46.9 19 67.1L309.5 320h-171L173 263.9c12.4-20.2 19-43.4 19-67.1z"/></svg>
                        <p>Laboratoires</p>
                    </button>
                    <div id="dropdown-laboratoires" className="dropdown" dropdown-parent="/laboratoires">
                        <NavLink className={({ isActive }) => isActive ? "active menuItem dropdown-content": "menuItem dropdown-content"}  to={`/laboratoires/list`}  data-path="/laboratoires/list">
                            <p>Laboratoires</p>
                        </NavLink>
                    </div>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/administration/utilisateurs`} data-path="/administration/utilisateurs">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                        <p>Utilisateurs</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/agria`} data-path="/agria">
                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><g id="Layer"><path className="s0" d="M18 .6v13.9h18.1v36H50V.6z"/><path className="s0" d="M.1.6v49.9h32.1V36.7H14V.6z"/><path d="M18 18.5h14.2v14.2H18z"/></g></svg>
                        <p>Agria</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/royal-canin`} data-path="/royal-canin">
                        <LogoRoyalCanin />
                        <p>Royal Canin</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active menuItem": "menuItem"} to={`/statistiques`} data-path="/statistiques">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/></svg>
                        <p>Statistiques</p>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default Header;
