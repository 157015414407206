import React from 'react';
import { useState } from 'react';

const OpenLink = ({ url, content, divClassName = '', size = 'sm' }) => {
    const [fillButton, setFillButton] = useState('black');

    return (
        <div className={divClassName}>
            <a href={url}>
                {content}
            </a>
            &nbsp;
            {size === 'sm' &&
                <button className={`btn btn-outline-primary rounded-5 btn-sm`} onClick={() => window.open(url, '_blank')} onMouseEnter={() => setFillButton('white')} onMouseLeave={() => setFillButton('black')} style={{ fontSize: '0.1rem' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={10} fill={fillButton}><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                </button>
            }
            {(size === 'md' || size === 'lg') &&
                <button className={`btn btn-outline-primary rounded-5 btn-${size}`} onClick={() => window.open(url, '_blank')} onMouseEnter={() => setFillButton('white')} onMouseLeave={() => setFillButton('black')} >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill={fillButton}><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                </button>
            }
        </div>
    );
}

export default OpenLink;