import React from 'react';
import { useEffect } from 'react';
import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Bracket from './assets/Bracket.png';
import { isEmpty } from 'lodash';
import { useFetcher } from 'react-router-dom';

const Content = ({ pedigreeInfos }) => {
  const father = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.catInfos) || {};
  const ff = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.catInfos) || {};
  const fm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.catInfos) || {};
  const fff = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.father.catInfos) || {};
  const ffm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.mother.catInfos) || {};
  const fmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.father.catInfos) || {};
  const fmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.mother.catInfos) || {};
  const ffff = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.father.father.catInfos) || {};
  const fffm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.father.mother.catInfos) || {};
  const ffmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.mother.father.catInfos) || {};
  const ffmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.mother.mother.catInfos) || {};
  const fmff = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.father.father.catInfos) || {};
  const fmfm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.father.mother.catInfos) || {};
  const fmmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.mother.father.catInfos) || {};
  const fmmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.mother.mother.catInfos) || {};

  const mother = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.catInfos) || {};
  const mf = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.catInfos) || {};
  const mm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.catInfos) || {};
  const mff = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.father.catInfos) || {};
  const mfm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.mother.catInfos) || {};
  const mmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.father.catInfos) || {};
  const mmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.mother.catInfos) || {};
  const mfff = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.father.father.catInfos) || {};
  const mffm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.father.mother.catInfos) || {};
  const mfmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.mother.father.catInfos) || {};
  const mfmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.mother.mother.catInfos) || {};
  const mmff = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.father.father.catInfos) || {};
  const mmfm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.father.mother.catInfos) || {};
  const mmmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.mother.father.catInfos) || {};
  const mmmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.mother.mother.catInfos) || {};

  const getTitreWithDist = (titre, federation, titreDist) => {
    const distsForThisFed = titreDist.find((dist) => dist.federation === federation);
    const dists = distsForThisFed ? distsForThisFed.titres.map((dist) => dist.titreAbrev).join(', ') : '';

    const titreAbrev = titre?.titres?.[0]?.titreAbrev || '';

    if (federation === 'Autre') {
      if (titreAbrev && dists) return `${titreAbrev} - ${dists}`;
      if (titreAbrev) return `${titreAbrev}`;
      if (dists) return `${dists}`;
      return '';
    }

    if (titreAbrev && dists) return `${titreAbrev} - ${dists} (${federation})`;
    if (titreAbrev) return `${titreAbrev} (${federation})`;
    if (dists) return `${dists} (${federation})`;
    return '';
  };

  const getTitres = (titres, titreDist) => {
    if (!titres || titres.length === 0) return '';

    const sortedTitres = titres.sort((a, b) => {
      if (a.federation === 'LOOF') return -1; // "LOOF" comes first
      if (b.federation === 'LOOF') return 1; // "LOOF" comes first
      return 0;
    });

    const allTitres = sortedTitres
      .map((titre) => getTitreWithDist(titre, titre.federation, titreDist))
      .filter((tmp) => tmp !== '')
      .join(' / ');

    return allTitres;
  };

  const putBrackets = (number, generation) => {
    const brackets = [];
    const height = 310 / number; // 310 is calculated manually because the app doesn't accept % for height
    for (let i = 0; i < number; i++) {
      brackets.push(
        <Image src={Bracket} style={{ height, marginRight: 5 }} key={i} />,
      );
    }
    return brackets;
  };

  const putCat = (cat) => {
    const titres = cat.titres;
    const titreDist = cat.titreDist;

    return (
      <View>
        <Text>{getTitres(titres, titreDist)}</Text>
        <Text style={styles.nomChat}>{cat.nomChat}</Text>
        <Text>{cat.intituleRace} {cat.intituleRobe} {cat.commentairesRobe && `(${cat.commentairesRobe})`} ({cat.codeEMS})</Text>
        <Text>
          {[
            cat.numOrigineChat && `${cat.numOrigineChat}`,
            cat.numIdentificationChat && `${cat.numIdentificationChat}`,
            cat.dateNaissanceChat && `${cat.dateNaissanceChat}`
          ]
            .filter(Boolean)
            .join(' - ')}
        </Text>
        <Text>{cat.genetiqueChaton}</Text>
        <Text>{cat.tests && cat.tests.map(test => `${test.sigle} : ${test.resultat}`).join(', ')}</Text>
        <Text style={styles.qualification}>{cat.qualification}</Text>
      </View>
    );
  }

  const putGeneration = (cat, generation, parentType = null) => {
    if (generation === 1) {
      return (
        <View style={styles.parentContainer}>
          <View style={styles.parents}>
            <Text style={styles.parentType}>{parentType}</Text>
            {putCat(cat)}
          </View>
        </View>
      );
    } else if (generation === 2) {
      return (
        <View style={styles.parentContainer}>
          <View style={styles.parents}>
            {putCat(cat)}
          </View>
        </View>
      );
    } else if (generation === 3) {
      return (
        <View style={styles.parentContainer}>
          <View style={{ ...styles.parents, ...styles.reduceText }}>
            <Text>{getTitres(cat.titres, cat.titreDist)}</Text>
            <Text style={styles.nomChat}>{cat.nomChat}</Text>
            <Text>{cat.intituleRace} {cat.intituleRobe} {cat.commentairesRobe && `(${cat.commentairesRobe})`} ({cat.codeEMS})</Text>
            <Text>
              {[
                cat.numOrigineChat && `${cat.numOrigineChat}`,
                cat.numIdentificationChat && `${cat.numIdentificationChat}`,
                cat.dateNaissanceChat && `${cat.dateNaissanceChat}`
              ]
                .filter(Boolean)
                .join(' - ')}
            </Text>
            <Text>
              {cat.genetiqueChaton}
              {(cat.genetiqueChaton && cat.tests.length > 0) && "/"}
              {cat.tests && cat.tests.map(test => `${test.sigle} : ${test.resultat}`).join(', ')}
              {((cat.tests && cat.tests.length > 0) && cat.qualification) && ' - '}
              {cat.qualification && <Text style={styles.qualification}>{cat.qualification}</Text>}
            </Text>
          </View>
        </View>
      );
    } else if (generation === 4) {
      return (
        <View style={styles.parentContainer}>
          <View style={{ ...styles.parents, ...styles.reduceText }}>
            <Text>{getTitres(cat.titres, cat.titreDist)} <Text style={styles.nomChat}>{cat.nomChat}</Text></Text>
            <Text>({cat.codeEMS}) {cat.commentairesRobe && `(${cat.commentairesRobe})`} {cat.numOrigineChat}</Text>
            <Text>
              {cat.genetiqueChaton}
              {(cat.genetiqueChaton && cat.tests.length > 0) && "/"}
              {cat.tests && cat.tests.map(test => `${test.sigle} : ${test.resultat}`).join(', ')}
              {((cat.tests && cat.tests.length > 0) && cat.qualification) && ' - '}
              {cat.qualification && <Text style={styles.qualification}>{cat.qualificationNQ}</Text>}
            </Text>
          </View>
        </View>
      );
    }
  };


  return (
    <View style={styles.container}>
      {/* 1ère gen. */}
      <View style={styles.generations}>
        <View style={styles.titleGeneration}>
          <Text>Parents</Text>
        </View>
        {putGeneration(father, 1, 'Père / Sire')}
        {putGeneration(mother, 1, 'Mère / Dam')}
      </View>

      <View style={styles.brackets}>
        <View style={styles.titleGeneration}></View> {/* Empty div */}
        {putBrackets(2)}
      </View>

      {/* 2e gen. */}
      <View style={styles.generations}>
        <View style={styles.titleGeneration}>
          <Text>2e Génération</Text>
        </View>
        <View style={styles.parentContainer}>
          {putGeneration(ff, 2)}
          {putGeneration(fm, 2)}
        </View>
        <View style={styles.parentContainer}>
          {putGeneration(mf, 2)}
          {putGeneration(mm, 2)}
        </View>
      </View>

      <View style={styles.brackets}>
        <View style={styles.titleGeneration}></View> {/* Empty div */}
        {putBrackets(4)}
      </View>

      {/* 3e gen. */}
      <View style={styles.generations}>
        <View style={styles.titleGeneration}>
          <Text>3e Génération</Text>
        </View>
        <View style={styles.parentContainer}>
          {putGeneration(fff, 3)}
          {putGeneration(ffm, 3)}
          {putGeneration(fmf, 3)}
          {putGeneration(fmm, 3)}
        </View>
        <View style={styles.parentContainer}>
          {putGeneration(mff, 3)}
          {putGeneration(mfm, 3)}
          {putGeneration(mmf, 3)}
          {putGeneration(mmm, 3)}
        </View>
      </View>

      <View style={styles.brackets}>
        <View style={styles.titleGeneration}></View> {/* Empty div */}
        {putBrackets(8)}
      </View>

      {/* 4e gen. */}
      <View style={styles.generations}>
        <View style={styles.titleGeneration}>
          <Text>4e Génération</Text>
        </View>
        <View style={{ ...styles.parentContainer, gap: 3, marginBottom: 3 }}>
          {putGeneration(ffff, 4)}
          {putGeneration(fffm, 4)}
          {putGeneration(ffmf, 4)}
          {putGeneration(ffmm, 4)}
          {putGeneration(fmff, 4)}
          {putGeneration(fmfm, 4)}
          {putGeneration(fmmf, 4)}
          {putGeneration(fmmm, 4)}
        </View>
        <View style={{ ...styles.parentContainer, gap: 3 }}>
          {putGeneration(mfff, 4)}
          {putGeneration(mffm, 4)}
          {putGeneration(mfmf, 4)}
          {putGeneration(mfmm, 4)}
          {putGeneration(mmff, 4)}
          {putGeneration(mmfm, 4)}
          {putGeneration(mmmf, 4)}
          {putGeneration(mmmm, 4)}
        </View>
      </View>
    </View>
  );
};

export default Content;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  generations: {
    height: '100%',
    width: '25%',
  },
  titleGeneration: {
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Lato Bold',
    marginBottom: 5,
  },
  parentContainer: {
    height: '50%',
    width: '98%',
    justifyContent: 'center',
    fontSize: 7,
  },
  parents: {
    justifyContent: 'center',
  },
  parentType: {
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  nomChat: {
    textTransform: 'uppercase',
  },
  qualification: {
    textTransform: 'uppercase',
    color: 'red',
  },
  reduceText: {
    fontSize: 6,
  },
  brackets: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
});
