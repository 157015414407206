import React, { useEffect, useState } from 'react';
import ReactPDF, { pdf, PDFViewer } from '@react-pdf/renderer';
import { generatePedigree as generatePedigreeOld } from '../../../components/GeneratePedigree/old/GeneratePedigree';
import { generatePedigree as generatePedigreeNew } from '../../../components/GeneratePedigree/new/GeneratePedigree';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';
import LogoICAD from '../../../assets/images/LogoICAD';
import { axiosCore } from "../../../utils/http-core-service";
import OpenLink from '../../../components/OpenLink/OpenLink';
import { putComment, showICAD } from '../../../utils/utils';
import TableTemplate from '../../../components/TableTemplate/TableTemplate';

const FicheChat = () => {
    const { id } = useParams();
    const [pedigreeInfos, setPedigreeInfos] = useState([]);
    const [chat, setChat] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const emptyInfo = <i class="text-muted">Non renseigné</i>;
    const [allTitles, setAllTitles] = useState([]);
    const [isTitlesCollapsed, setIsTitlesCollapsed] = useState(true);
    const [isCertsCollapsed, setIsCertsCollapsed] = useState(true);
    const [pedColor, setPedColor] = useState('');
    const [pedType, setPedType] = useState('');
    const [icadResult, setIcadResult] = useState(null);
    const [origins, setOrigins] = useState({
        "RIEX": "#aace7b",
        "RIA": "#f1864c",
        "RF": "#fbb800",
        "LOOF": "#0097ce",
        "Cert": "#831f81",
        "MONACO": "#d70924",
    });

    const openNewPedigreeInNewTab = async () => {
        pdf(generatePedigreeNew({ pedigreeInfos, pedColor, pedType })).toBlob().then((blob) => {
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        });
    };

    const askPedigree = async () => {
        try {
            const response = await axiosCore.get(`/api/reproducteur/${id}`);
            setPedigreeInfos(response.data);
            setIsLoading(false);
        } catch (error) {
            if (!error.response) {
                console.log("Error fetching data: ", error.message);
                setIsLoading(false);
                setError(error.message);
                return;
            }
            console.log("Error fetching data: ", error.response.data.error);
            console.log("Error fetching data: ", error);
            setIsLoading(false);
            setError(error.response.data.error);
        }
    }

    const findPedType = (numOrigineChat) => {
        const type = Object.keys(origins).find((key) => numOrigineChat.includes(key));
        if (type === "LOOF") {
            return "Pedigree";
        } else if (type === "RIEX") {
            return "Pedigree RIEX";
        } else if (type === "RIA") {
            return "Pedigree RIA";
        } else if (type === "RF") {
            return "Registre de filiation";
        }
        return "Pedigree";
    }

    const findOrigin = (numOrigineChat) => {
        let origin = Object.keys(origins).find((key) => numOrigineChat.includes(key));

        //Cas particulier monaco
        if (pedigreeInfos?.breederInfos?.villeEl === "MONACO") {
            origin = "MONACO";
        }

        const color = origins[origin];
        if (color) {
            return color;
        } else {
            return "#0097ce";
        }
    }

    useEffect(() => {
        if (isNaN(id)) {
            console.log("id is null");
            setError("Ce chat n'existe pas");
            setIsLoading(false);
            return;
        }
        askPedigree();
    }, []);

    useEffect(() => {
        if (pedigreeInfos && pedigreeInfos.catInfos) {
            const origin = findOrigin(pedigreeInfos.catInfos.numOrigineChat);
            setChat(pedigreeInfos.catInfos);
            mergeTitles(pedigreeInfos.catInfos.titres, pedigreeInfos.catInfos.titreDistFiche, pedigreeInfos.catInfos.excellents, pedigreeInfos.catInfos.bests);
            console.log("pedigreeInfos: ", pedigreeInfos);
            setPedColor(origin);
            const type = findPedType(pedigreeInfos.catInfos.numOrigineChat);
            setPedType(type);
        }
    }, [pedigreeInfos]);

    useEffect(() => {
        console.log("pedColor: ", pedColor);
    }, [pedColor]);

    const mergeTitles = (titres, titresDist, excellents, bests) => {
        const merged = [];
        for (let i = 0; i < titres.length; i++) {
            let federation = titres[i].federation;
            let titles = titres[i].titres;
            let titlesDist = titresDist.find((el) => el.federation === federation)?.titres;
            let excellent = excellents.find((el) => el.federation === federation)?.excellents.map((el) => {
                let ville = el.ville ? el.ville : el.Ville ? el.Ville : 'Non renseigné';
                return {
                    ...el,
                    ville: ville
                }
            });
            let best = bests.find((el) => el.federation === federation)?.bests.map((el) => {
                let ville = el.ville ? el.ville : el.Ville ? el.Ville : 'Non renseigné';
                return {
                    ...el,
                    ville: ville
                }
            });
            merged.push({ federation, titles, titlesDist, excellent, best });
        }
        const noTitles = merged.find((el) => el.titles.length > 0);
        if (!noTitles) {
            setAllTitles([]);
            return;
        }
        setAllTitles(merged);
    }

    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {/* Only for debugging */}
            {/* {!error &&
                <PDFViewer width="100%" height="800px">
                    {generatePedigreeNew({ pedigreeInfos, pedColor, pedType })}
                    {generatePedigreeOld({ pedigreeInfos, pedColor, pedType })}
                </PDFViewer>
            } */}
            {!isLoading && chat && !error &&

                <div>
                    <div className="fil-arenae d-flex align-items-center mb-3 justify-content-between">
                        <div className="d-flex align-items-center">
                        <h5 className='mb-0'>
                            <NavLink className='link' to={`/chats`}>Chat</NavLink>
                        </h5>
                        <div className='mx-2'> &gt; </div>
                        <h5 className='mb-0'>Fiche chat</h5>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <NavLink className="btn btn-outline-primary" to={`/chats/${id}/portees`}>Portées</NavLink>
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <div id='topBox' className="whiteBox">
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h5><b>Nom du chat / N°origine</b> {chat.nomChat} / {chat.numOrigineChat}</h5>
                                <h6 className='mb-1'><b>ID: </b> {chat.id}</h6>
                                <h6 className='mb-1'><b>Création en date du : </b> {chat.dateSaisieChat} | {chat.prenomCreateur} {chat.nomCreateur} <b>({chat.initialesCreateur})</b></h6>
                                <h6 className='mb-0'><b>Modification en date du : </b> {chat.dateMajChat} | {chat.prenomMaj} {chat.nomMaj} <b>({chat.initialesMaj})</b></h6>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Race :	</b></h6>
                                                    <div className='col-8'>{chat.intituleRace ? chat.intituleRace : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Yeux :	</b></h6>
                                                    <div className='col-8'>{chat.couleurYeux ? chat.couleurYeux : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Date de naissance : </b></h6>
                                                    <div className='col-8'>{chat.dateNaissanceChat ? chat.dateNaissanceChat : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Date de décès : </b></h6>
                                                    <div className='col-8'>{chat.dateDeces ? chat.dateDeces : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Favori : </b></h6>
                                                    <div className='col-8'>{chat.favorite === false ? 'Non' : chat.favorite === true ? 'Oui' : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Demande DNA : </b></h6>
                                                    <div className='col-8'>{chat.genetiqueChaton ? chat.genetiqueChaton : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Blacklisté : </b></h6>
                                                    <div className='col-8'>{chat.isBlacklisted === false ? 'Non' : chat.isBlacklisted === true ? 'Oui' : emptyInfo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Robe :	</b></h6>
                                                    <div className='col-8'>{chat.intituleRobe ? chat.intituleRobe : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Commentaire robe<br />(visible sur les pedigrees) :	</b></h6>
                                                    <div className='col-8'>{chat.commentaireRobe ? chat.commentaireRobe : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Sexe :	</b></h6>
                                                    <div className='col-8'>{chat.sexeChat ? chat.sexeChat : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Identification : </b></h6>
                                                    <div className='col-8 d-flex align-items-center'>
                                                        <span>{Number(chat.numIdentificationChat)?.toLocaleString()} </span>
                                                        <button type="button" className="btn btn-sm"
                                                            onClick={() => icadResult !== null ? showICAD({ infos: icadResult }) : showICAD({ numIdentification: chat.numIdentificationChat, setIcadResult : setIcadResult })}>
                                                            <LogoICAD width="auto" height="20px" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Date d'envoi du pedigree : </b></h6>
                                                    <div className='col-8'>{chat.dateEnvoiPedigree ? chat.dateEnvoiPedigree : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Mention DSN<br />(visible sur Pedigree) :	</b></h6>
                                                    <div className='col-8'>{chat.mentionPedigree ? chat.mentionPedigree : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Commentaire reproducteur<br />(visible sur Pedigree) :	</b></h6>
                                                    <div className='col-8'>{chat.commentairePedigree ? putComment(chat.commentairePedigree) : emptyInfo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header text-center text-white">Commentaire reproducteur</h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc commentaire */}
                                            <div className='form-line d-flex justify-content-start gap-5'>
                                                <div className='row form-line-item'>
                                                    <div className='col-12'>{chat.commentairesChat ? putComment(chat.commentairesChat) : emptyInfo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4 mt-0'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header text-center text-white">
                                        Titres ({
                                            // Filter and map the titles
                                            allTitles
                                                .filter(federation =>
                                                    federation?.titles?.length > 0 ||
                                                    federation?.titlesDist?.length > 0 ||
                                                    federation?.excellent?.length > 0 ||
                                                    federation?.best?.length > 0
                                                )
                                                .map(federation => {
                                                    // Calculate the total count of titles
                                                    const titleCount =
                                                        (federation.titles?.length || 0) +
                                                        (federation.titlesDist?.length || 0) +
                                                        (federation.excellent?.length || 0) +
                                                        (federation.best?.length || 0);

                                                    // Return the formatted string if there are titles
                                                    return titleCount > 0
                                                        ? `${federation.federation} : ${titleCount}`
                                                        : null;
                                                })
                                                .filter(entry => entry !== null) // Remove null entries
                                                .join(', ') || 'Aucun'
                                        })
                                    </h5>
                                    <button className="btn btn-outline-primary btn-sm" onClick={() => setIsTitlesCollapsed(!isTitlesCollapsed)}>
                                        {isTitlesCollapsed ? 'Afficher' : 'Masquer'} les titres
                                    </button>
                                    <div className="card-body" id="Titles" style={{ display: isTitlesCollapsed ? 'none' : 'block' }}>
                                        <div id='bottomBox' className=""> {/* Bloc titres */}
                                            <div className='form-line d-flex justify-content-start gap-5'>
                                                <div className='form-line-item d-flex gap-2 flex-column w-100'>
                                                    {allTitles.map((federation, index) => {
                                                        if (federation?.titles?.length > 0 || federation?.titlesDist?.length > 0 || federation?.excellent?.length > 0 || federation?.best?.length > 0) {
                                                            return (
                                                                <>
                                                                    <h4 className='text-center mb-0'>{federation.federation}</h4>
                                                                    <hr className='w-100 mt-0' />
                                                                    <div className='d-flex gap-2'>
                                                                        {federation.titles?.length > 0 &&
                                                                            <div className='d-flex gap-2 flex-column w-100'>
                                                                                <h6 className='mb-0 text-center'>Titres ({federation.titles.length})</h6>
                                                                                <hr className='w-100 mt-0 mb-0' />
                                                                                <TableTemplate
                                                                                    key={index}
                                                                                    theadData={[
                                                                                        { key: 'titreFull', label: 'Titre' },
                                                                                        { key: 'dateObtention', label: 'Date d\'obtention' }
                                                                                    ]}
                                                                                    tbodyData={federation.titles}
                                                                                    limit={null}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {federation.titlesDist?.length > 0 &&
                                                                            <div className='d-flex gap-2 flex-column w-100'>
                                                                                <h6 className='mb-0 text-center'>Distinctions ({federation.titlesDist.length})</h6>
                                                                                <hr className='w-100 mt-0 mb-0' />
                                                                                <TableTemplate
                                                                                    key={index}
                                                                                    theadData={[
                                                                                        { key: 'titreFull', label: 'Distinction' },
                                                                                        { key: 'dateObtention', label: 'Date d\'obtention' }
                                                                                    ]}
                                                                                    tbodyData={federation.titlesDist}
                                                                                    limit={null}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {federation.excellent?.length > 0 &&
                                                                            <div className='d-flex gap-2 flex-column w-100'>
                                                                                <h6 className='mb-0 text-center'>Excellents ({federation.excellent.length})</h6>
                                                                                <hr className='w-100 mt-0 mb-0' />
                                                                                <TableTemplate
                                                                                    key={index}
                                                                                    theadData={[
                                                                                        { key: 'caType', label: 'Type' },
                                                                                        { key: 'date', label: 'Date' },
                                                                                        { key: 'ville', label: 'Ville' }
                                                                                    ]}
                                                                                    tbodyData={federation.excellent}
                                                                                    limit={null}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {federation.best?.length > 0 &&
                                                                            <div className='d-flex gap-2 flex-column w-100'>
                                                                                <h6 className='mb-0 text-center'>Bests ({federation.best.length})</h6>
                                                                                <hr className='w-100 mt-0 mb-0' />
                                                                                <TableTemplate
                                                                                    key={index}
                                                                                    theadData={[
                                                                                        { key: 'caType', label: 'Type' },
                                                                                        { key: 'class', label: 'Classe' },
                                                                                        { key: 'date', label: 'Date' },
                                                                                        { key: 'ville', label: 'Ville' }
                                                                                    ]}
                                                                                    tbodyData={federation.best}
                                                                                    limit={null}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    }
                                                    )}
                                                    {allTitles.length > 0 &&
                                                        <button className="btn btn-outline-primary btn-sm" onClick={() => setIsTitlesCollapsed(!isTitlesCollapsed)}>
                                                            {isTitlesCollapsed ? 'Afficher' : 'Masquer'} les titres
                                                        </button>
                                                    }
                                                    {allTitles.length === 0 && emptyInfo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header text-center text-white">Certificats d'aptitude ({chat.certs?.length})</h5>
                                    <button className="btn btn-outline-primary btn-sm" onClick={() => setIsCertsCollapsed(!isCertsCollapsed)}>
                                        {isCertsCollapsed ? 'Afficher' : 'Masquer'} les certificats d'aptitude
                                    </button>
                                    <div className="card-body" id="Certs" style={{ display: isCertsCollapsed ? 'none' : 'block' }}>
                                        <div id='bottomBox' className=""> {/* Bloc commentaire */}
                                            <div className='form-line d-flex justify-content-start gap-5'>
                                                <div className='form-line-item d-flex gap-2 flex-column w-100'>
                                                    {console.log("certificatsAptitude: ", chat.certs)}
                                                    {chat.certs?.length > 0 &&
                                                        <TableTemplate
                                                            theadData={[
                                                                { key: 'caType', label: 'Type' },
                                                                { key: 'date', label: 'Date' },
                                                                { key: 'ville', label: 'Ville' },
                                                                { key: 'pays', label: 'Pays' }
                                                            ]}
                                                            tbodyData={chat.certs}
                                                            limit={null}
                                                        />
                                                    }
                                                    {chat.certs?.length > 0 &&
                                                        <button className="btn btn-outline-primary btn-sm" onClick={() => setIsCertsCollapsed(!isCertsCollapsed)}>
                                                            {isCertsCollapsed ? 'Afficher' : 'Masquer'} les certificats d'aptitude
                                                        </button>
                                                    }
                                                    {chat.certs?.length === 0 && emptyInfo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line d-flex justify-content-start gap-5'>
                                                <div className='form-line-item d-flex gap-2 flex-column'>
                                                    <h6><b>Père du reproducteur : </b></h6>
                                                    <OpenLink
                                                        url={`/chats/${pedigreeInfos.ancestors.father?.catInfos?.id}`}
                                                        content={pedigreeInfos.ancestors.father?.catInfos?.nomChat}
                                                        divClassName=''
                                                    />

                                                    <div>{pedigreeInfos.ancestors.father?.catInfos?.numOrigineChat}</div>
                                                    <div>{pedigreeInfos.ancestors.father?.catInfos?.titrePed}</div>
                                                </div>
                                                <div className='form-line-item d-flex gap-2 flex-column'>
                                                    <h6><b>Mère du reproducteur : </b></h6>
                                                    <OpenLink
                                                        url={`/chats/${pedigreeInfos.ancestors.mother?.catInfos?.id}`}
                                                        content={pedigreeInfos.ancestors.mother?.catInfos?.nomChat}
                                                        divClassName=''
                                                    />
                                                    <div>{pedigreeInfos.ancestors.mother?.catInfos?.numOrigineChat}</div>
                                                    <div>{pedigreeInfos.ancestors.mother?.catInfos?.titrePed}</div>
                                                </div>
                                                <div className='form-line-item d-flex gap-2 flex-column'>
                                                    <h6><b>Propriétaire du reproducteur : </b></h6>
                                                    <OpenLink
                                                        url={`/eleveurs/${pedigreeInfos?.breederInfos?.history[0]?.idEl}`}
                                                        content={`${pedigreeInfos?.breederInfos?.history[0]?.prenomEl} ${pedigreeInfos?.breederInfos?.history[0]?.nomEl}`}
                                                        divClassName=''
                                                    />
                                                    <div className='d-flex gap-2'>
                                                        {pedigreeInfos?.breederInfos?.history.slice(1).map((el, index) => {
                                                            return (
                                                                <small key={index} className='text-muted'><i>&emsp;{el.prenomEl} {el.nomEl} ({el.dateModif})</i></small>
                                                            );
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='form-line-item d-flex gap-2 flex-column'></div> {/* Empty div to add a line under the last div */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {error && <div className="alert alert-danger">{error}</div>}
            {!isLoading && chat && !error &&
                <>
                    <div className='d-flex gap-3 justify-content-end mb-3'>
                        <button className='btn btn-primary btn-sm' onClick={openNewPedigreeInNewTab}>Générer le nouveau pedigree</button>
                    </div>
                </>
            }
        </div>
    );
}

export default FicheChat;
