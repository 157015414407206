import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
import { useState, useEffect } from 'react';
import Insert from '../../components/DataManagement/Insert.js';
import { NavLink } from 'react-router-dom';
import {axiosCore} from "../../utils/http-core-service";

const Labo = () => {
    const header = [
        {
            "key": "id",
            "label": "ID",
        },
        {
            "key": "nom",
            "label": "NOM",
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    <NavLink className='btn btn-outline-primary rounded-5' to={`/laboratoires/detail/${row.id}`}>
                        Voir la fiche
                    </NavLink>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isInsertPopupOpen, setInsertPopupOpen] = useState(false);

    const [search, setSearch] = useState('');

    const getAll = async (search = '') => {
        setIsLoading(true);
        try {
            let response = null;
            let url = `/api/labo/list`;

            if (search !== '') {
                url += `?intitule=${search}`;
            }
            response = await axiosCore.get(url);
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
        } catch (error) {
            console.log(error);
        }
    };

    const getData = () => {
        getAll(search);
    }

    const openInsertPopup = () => {
        setInsertPopupOpen(true);
    };

    const closeInsertPopup = () => {
        setInsertPopupOpen(false);
        getAll(search);
    };

    useEffect(() => {
        getAll();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    const handleSearch = (event) => {
        event.preventDefault();
        getAll(search)
    }

    const reinitSearch = () => {
        setSearch('')
        getAll()
    }

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Labo</h5>
            </div>
            <div className="searchBox whiteBox request">
                <form onSubmit={handleSearch}>
                    <div className="row">
                        <div className="col-3">
                            <p>Nom de la labo</p>
                            <input type="text" id="search" name="search" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className="d-flex mt-4 justify-content-end">
                        <button className='btn btn-primary mx-3' type='submit'>Rechercher</button>
                        <button className='btn btn-danger' type='button' onClick={reinitSearch}>Voir tout</button>
                    </div>
                </form>
            </div>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des labos</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary" onClick={openInsertPopup}>Insérer</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'pays trouvés' : 'pay trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                    <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
                {isInsertPopupOpen && (
                    <Insert
                        onClose={closeInsertPopup}
                        title="Insérer une labo"
                        insertDatas={[
                            {"key": "nom", "label": "Nom", "required": true},
                            {"key": "sigle", "label": "Sigle", "required": true},
                            {"key": "adresse", "label": "Adresse", "required": true},
                            {"key": "telephone", "label": "Téléphone", "required": true},
                            {"key": "email", "label": "Email", "required": true},
                        ]}
                        insertRoute={`api/labo/insert`}
                    />
                )}
            </div>
        </div>
    );
}

export default Labo;
