import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePageClick }) => {
	const MAX_PAGES_DISPLAYED = 5;

	const renderPageNumbers = () => {
		const pages = [];
		let startPage, endPage;

		if (totalPages <= MAX_PAGES_DISPLAYED) {
			startPage = 1;
			endPage = totalPages;
		} else {
			const halfMax = Math.floor(MAX_PAGES_DISPLAYED / 2);
			if (currentPage <= halfMax) {
				startPage = 1;
				endPage = MAX_PAGES_DISPLAYED;
			} else if (currentPage + halfMax >= totalPages) {
				startPage = totalPages - MAX_PAGES_DISPLAYED + 1;
				endPage = totalPages;
			} else {
				startPage = currentPage - halfMax;
				endPage = currentPage + halfMax;
			}
		}

		for (let i = startPage; i <= endPage; i++) {
			pages.push(
				<li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`} aria-current="page">
					<a className="page-link" onClick={() => handlePageClick(i)} style={{ cursor: 'pointer', userSelect: 'none' }}>
						{i}
					</a>
				</li>
			);
		}

		if (totalPages > MAX_PAGES_DISPLAYED) {
			if (startPage > 1) {
				pages.unshift(
					<li key="ellipsis-start" className="page-item disabled">
						<span className="page-link" style={{ userSelect: 'none', backgroundColor: 'white' }}>...</span>
					</li>
				);
			}

			if (endPage < totalPages) {
				pages.push(
					<li key="ellipsis-end" className="page-item disabled">
						<span className="page-link" style={{ userSelect: 'none', backgroundColor: 'white' }}>...</span>
					</li>
				);
			}
		}

		return (
			<ul className="pagination">
				<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
						<a className="page-link" aria-label="Previous" onClick={() => handlePageClick(1)} disabled={currentPage === 1} style={{ cursor: 'pointer', userSelect: 'none' }}>
								<span aria-hidden="true">Début</span>
						</a>
				</li>
				<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
					<a className="page-link" aria-label="Previous" onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1} style={{ cursor: 'pointer', userSelect: 'none' }}>
						<span aria-hidden="true">&laquo;</span>
					</a>
				</li>
				{pages}
				<li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
					<a className="page-link" aria-label="Next" onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages} style={{ cursor: 'pointer', userSelect: 'none' }}>
						<span aria-hidden="true">&raquo;</span>
					</a>
				</li>
				<li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
						<a className="page-link" aria-label="Next" onClick={() => handlePageClick(totalPages)} disabled={currentPage === totalPages} style={{ cursor: 'pointer', userSelect: 'none' }}>
								<span aria-hidden="true">Fin</span>
						</a>
				</li>
			</ul>
		);
	};

	return <nav>{renderPageNumbers()}</nav>;
};

export default Pagination;
