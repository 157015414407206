import React, { useState, useEffect } from 'react';
import { axiosCore } from '../../../utils/http-core-service';
import OpenLink from '../../OpenLink/OpenLink';

const getDemDSNBirthday = async (value) => {
    try {
        let response = await axiosCore.get(`/api/demandes/dsn/${value}`);
        if (response.data.birthday) {
            return new Date(response.data.birthday).toLocaleDateString('fr-FR');
        } else {
            return '-';
        }
    } catch (error) {
        console.error(error);
        return '-';
    }
};

const getDemPedNumDossier = async (value) => {
    try {
        let response = await axiosCore.get(`/api/demandes/pedigree/${value}`);
        try {
            let response2 = await axiosCore.get(`/api/saillie/${response.data.idSalience}/numDossier`);
            return response2.data;
        } catch (error) {
            console.error(error);
        }
    } catch (error) {
        console.error(error);
        return '-';
    }
};

const getDemCatNom = async (value) => {
    try {
        let response = await axiosCore.get(`/api/demandes/cat/${value}`);
        try {
            let response2 = await axiosCore.get(`/api/reproducteur/${response.data.cat}/nomChat`);
            return response2.data;
        } catch (error) {
            console.error(error);
        }
    } catch (error) {
        console.error(error);
        return '-';
    }
};

const getDemAffixSend = async (value) => {
    try {
        let response = await axiosCore.get(`/api/demandes/affix/${value}`);
        try {
            let response2 = await axiosCore.get(`/api/affixe/${response.data.affix}`);
            return `Envoyé le ${response2.data.dateEnvoiAffixe}`;
        } catch (error) {
            console.error(error);
        }
    } catch (error) {
        console.error(error);
        return '-';
    }
};

const RenderDemandesInformation = ({ value, data }) => {
    const [info, setInfo] = useState('-');

    useEffect(() => {
        let isMounted = true;
        if (data) {
            switch (data.type) {
                case 'dsn':
                    if (data.requestDSN) {
                        getDemDSNBirthday(data.requestDSN.id).then(info => {
                            if (isMounted) {
                                setInfo(info);
                            }
                        });
                    }
                    break;
                case 'pedigree':
                    if (data.requestPedigree) {
                        getDemPedNumDossier(data.requestPedigree.id).then(info => {
                            if (isMounted) {
                                setInfo(info + (data.loofInfo != "NULL" && data.loofInfo ? " - AR " + new Date(data.loofInfo).toLocaleDateString('fr-FR') : ""));
                            }
                        });
                    }
                    break;
                case 'cat':
                    if (data.requestCat) {
                        getDemCatNom(data.requestCat.id).then(info => {
                            if (isMounted) {
                                setInfo(info);
                            }
                        });
                    }
                    break;
                case 'affix':
                    if (data.requestAffix) {
                        getDemAffixSend(data.requestAffix.id).then(info => {
                            if (isMounted) {
                                setInfo(info);
                            }
                        });
                    }
                    break;
                default:
                    setInfo("-");
            }
        }
        return () => {
            isMounted = false;
        };
    }, [data]);

    return info;
};

export default RenderDemandesInformation;