import {axiosCore} from "../../utils/http-core-service";

export const deleteData = async (url, id, itemName, { getData }) => {
    if (!window.confirm("Êtes-vous sûr de vouloir supprimer l'\élément " + itemName + " ?")) {
        return;
    }
    if (`${process.env.REACT_APP_INSERT_AUTHORIZATION}` === 'false') {
        window.alert('Le changement de données est désactivé pour cette démo.');
        return;
    }
    try {
        const response = await axiosCore.delete(`/${url}/${id}`);
        getData();
    } catch (error) {
        console.log(error);
    }
};
