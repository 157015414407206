import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Bracket from './assets/Bracket.png';
import { isEmpty } from 'lodash';

const Content = ({ pedigreeInfos, pedColor, pedType }) => {
  const getTitreWithDist = (titre, federation, titreDist) => {
    const distsForThisFed = titreDist.find((dist) => dist.federation === federation);
    const dists = distsForThisFed ? distsForThisFed.titres.map((dist) => dist.titreAbrev).join(', ') : '';

    const titreAbrev = titre?.titres?.[0]?.titreAbrev || '';

    if (federation === 'Autre') {
      if (titreAbrev && dists) return `${titreAbrev} - ${dists}`;
      if (titreAbrev) return `${titreAbrev}`;
      if (dists) return `${dists}`;
      return '';
    }

    if (titreAbrev && dists) return `${titreAbrev} - ${dists} (${federation})`;
    if (titreAbrev) return `${titreAbrev} (${federation})`;
    if (dists) return `${dists} (${federation})`;
    return '';
  };

  const getTitres = (titres, titreDist) => {
    if (!titres || titres.length === 0) return '';

    const sortedTitres = titres.sort((a, b) => {
      if (a.federation === 'LOOF') return -1; // "LOOF" comes first
      if (b.federation === 'LOOF') return 1; // "LOOF" comes first
      return 0;
    });

    const allTitres = sortedTitres
      .map((titre) => getTitreWithDist(titre, titre.federation, titreDist))
      .filter((tmp) => tmp !== '')
      .join(' / ');

    return allTitres;
  };

  const putBrackets = (number) => {
    const brackets = [];
    const height = 380 / number; // 310 is calculated manually because the app doesn't accept % for height
    for (let i = 0; i < number; i++) {
      brackets.push(
        <Image src={Bracket} style={{ height, marginRight: 5, marginBottom: 8 }} key={i} />,
      );
    }
    return brackets;
  };

  const putCat = (cat, parentType = null) => {
    const titres = cat.titres;
    const titreDist = cat.titreDist;
    return (
      <View style={parentType === null ? {...styles.cat, ...styles.reduceTextSecondG} : {...styles.cat}}>
        {parentType && <Text style={styles.parentType}>{parentType}</Text>}
        <Text styles={{overflowWrap: 'break-word'}}>{getTitres(titres, titreDist)}</Text>
        <Text styles={{overflowWrap: 'break-word'}} style={styles.nomChat}>{cat.nomChat}</Text>
        <Text styles={{overflowWrap: 'break-word'}}>{cat.intituleRace} {cat.intituleRobe} {cat.commentairesRobe && `(${cat.commentairesRobe})`} ({cat.codeEMS})</Text>
        <Text styles={{overflowWrap: 'break-word'}}>
          {[
            cat.numOrigineChat && `${cat.numOrigineChat}`,
            cat.numIdentificationChat && `${cat.numIdentificationChat}`,
            cat.dateNaissanceChat && `${cat.dateNaissanceChat}`
          ]
            .filter(Boolean)
            .join(' - ')}
        </Text>
        <Text styles={{overflowWrap: 'break-word'}}>{cat.genetiqueChaton}</Text>
        <Text styles={{overflowWrap: 'break-word'}}>{cat.tests && cat.tests.map(test => `${test.sigle} : ${test.resultat}`).join(', ')}</Text>
        <Text  styles={{overflowWrap: 'break-word'}} style={styles.qualification}>{cat.qualification}</Text>
      </View>
    );
  }

  const putGeneration = (cat, generation, parentType = null) => {
    if (generation === 1) {
      return (
        putCat(cat, parentType)
      );
    } else if (generation === 2) {
      return (
        putCat(cat)
      );
    } else if (generation === 3) {
      return (
        <View style={{ ...styles.cat, ...styles.reduceText }}>
          <Text styles={{overflowWrap: 'break-word'}}>{getTitres(cat.titres, cat.titreDist)}</Text>
          <Text styles={{overflowWrap: 'break-word'}} style={styles.nomChat}>{cat.nomChat}</Text>
          <Text styles={{overflowWrap: 'break-word'}}>({cat.codeEMS}) {cat.commentairesRobe && `(${cat.commentairesRobe})`}</Text>
          <Text styles={{overflowWrap: 'break-word'}}>
            {[
              cat.numOrigineChat && `${cat.numOrigineChat}`,
              cat.numIdentificationChat && `${cat.numIdentificationChat}`,
              cat.dateNaissanceChat && `${cat.dateNaissanceChat}`
            ]
              .filter(Boolean)
              .join(' - ')}
          </Text>
          <Text styles={{overflowWrap: 'break-word'}}>
            {cat.genetiqueChaton}
            {(cat.genetiqueChaton && cat.tests.length > 0) && "/"}
            {cat.tests && cat.tests.map(test => `${test.sigle} : ${test.resultat}`).join(', ')}
          </Text>
          {cat.qualification && <Text style={styles.qualification}>{cat.qualification}</Text>}
        </View>
      );
    } else if (generation === 4) {
      return (
        <View style={{ ...styles.cat, ...styles.reduceText }}>
          <Text styles={{overflowWrap: 'break-word'}}>{getTitres(cat.titres, cat.titreDist)} <Text style={styles.nomChat}>{cat.nomChat}</Text></Text>
          <Text styles={{overflowWrap: 'break-word'}}>({cat.codeEMS}) {cat.commentairesRobe && `(${cat.commentairesRobe})`} {cat.numOrigineChat}</Text>
          <Text styles={{overflowWrap: 'break-word'}}>
            {cat.genetiqueChaton}
            {(cat.genetiqueChaton && cat.tests.length > 0) && "/"}
            {cat.tests && cat.tests.map(test => `${test.sigle} : ${test.resultat}`).join(', ')}
          </Text>
          {cat.qualification && <Text style={styles.qualification}>{cat.qualificationNQ}</Text>}
        </View>
      );
    }
  };

  const father = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.catInfos) || {};
  const ff = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.catInfos) || {};
  const fm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.catInfos) || {};
  const fff = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.father.catInfos) || {};
  const ffm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.mother.catInfos) || {};
  const fmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.father.catInfos) || {};
  const fmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.mother.catInfos) || {};
  const ffff = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.father.father.catInfos) || {};
  const fffm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.father.mother.catInfos) || {};
  const ffmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.mother.father.catInfos) || {};
  const ffmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.father.mother.mother.catInfos) || {};
  const fmff = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.father.father.catInfos) || {};
  const fmfm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.father.mother.catInfos) || {};
  const fmmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.mother.father.catInfos) || {};
  const fmmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.father.mother.mother.mother.catInfos) || {};

  const mother = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.catInfos) || {};
  const mf = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.catInfos) || {};
  const mm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.catInfos) || {};
  const mff = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.father.catInfos) || {};
  const mfm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.mother.catInfos) || {};
  const mmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.father.catInfos) || {};
  const mmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.mother.catInfos) || {};
  const mfff = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.father.father.catInfos) || {};
  const mffm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.father.mother.catInfos) || {};
  const mfmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.mother.father.catInfos) || {};
  const mfmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.father.mother.mother.catInfos) || {};
  const mmff = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.father.father.catInfos) || {};
  const mmfm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.father.mother.catInfos) || {};
  const mmmf = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.mother.father.catInfos) || {};
  const mmmm = (!isEmpty(pedigreeInfos) && pedigreeInfos.mother.mother.mother.mother.catInfos) || {};

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    generations: {
      height: '100%',
      width: '25%',
    },
    parentContainer: {
      height: '50%',
      width: '98%',
      justifyContent: 'center',
      fontSize: 7,
    },
    parents: {
      justifyContent: 'center',
      padding: 3,
      border: 1,
      borderColor: pedColor,
    },
    parentType: {
      textTransform: 'uppercase',
      marginBottom: 5,
      fontFamily: 'Lato Bold',
    },
    nomChat: {
      textTransform: 'uppercase',
    },
    qualification: {
      textTransform: 'uppercase',
      color: 'red',
    },
    reduceText: {
      fontSize: 5,
    },
    reduceTextSecondG: {
      fontSize:6.5,
    },
    brackets: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: 10,
      maxWidth: 15,
    },
    titlePedigree: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Lato Bold',
      fontSize: 23,
      color: pedColor,
      textTransform: 'uppercase',
      textAlign: 'center'
    },
    generation: {
      height: '100%',
      width: '25%',
      display: 'flex',
      flexDirection: 'column',
    },
    titleGeneration: {
      textAlign: 'center',
      fontSize: 9,
      fontFamily: 'Lato Bold',
      marginBottom: 5,
    },
    ancestorsContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    cat: {
      fontSize: 8,
      width: '98%',
    },
    exponent: {
      position: 'relative',
      top: '-0.5em',
      fontSize: '80%'
    },
});

  return (
    <View style={styles.container}>
      {/* 1ère gen. */}
      <View style={styles.generation}>
        <View style={styles.titleGeneration}>
          <Text>Parents</Text>
        </View>
        <View style={styles.titlePedigree}>
          <Text>{pedType}</Text>
        </View>
        <View style={styles.ancestorsContainer}>
          {putGeneration(father, 1, 'Père / Sire')} {/* Père */}
          {putGeneration(mother, 1, 'Mère / Dam')} {/* Mère */}
        </View>
      </View>

      <View style={styles.brackets}>
        <View style={styles.titleGeneration}></View> {/* Empty div */}
          {putBrackets(2)}
      </View>

      {/* 2e gen. */}
      <View style={styles.generation}>
        <View style={styles.titleGeneration}>
          <Text>2e Génération</Text>
        </View>
        <View style={{marginTop: 20, height: 390 / 2}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(ff, 2)} {/* Père -> Père */}
            {putGeneration(fm, 2)} {/* Père -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 30, height: 390 / 2}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(mf, 2)} {/* Mère -> Père */}
            {putGeneration(mm, 2)} {/* Mère -> Mère */}
          </View>
        </View>
      </View>

      <View style={styles.brackets}>
        <View style={styles.titleGeneration}></View> {/* Empty div */}
        {putBrackets(4)}
      </View>

      {/* 3e gen. */}
      <View style={styles.generation}>
        <View style={styles.titleGeneration}>
          <Text>3e Génération</Text>
        </View>
        <View style={{height: 390 / 4}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(fff, 3)} {/* Père -> Père -> Père */}
            {putGeneration(ffm, 3)} {/* Père -> Père -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 16, height: 390 / 4}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(fmf, 3)} {/* Père -> Mère -> Père */}
            {putGeneration(fmm, 3)} {/* Père -> Mère -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 16, height: 380 / 4}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(mff, 3)} {/* Mère -> Père -> Père */}
            {putGeneration(mfm, 3)} {/* Mère -> Père -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 16, height: 380 / 4}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(mmf, 3)} {/* Mère -> Mère -> Père */}
            {putGeneration(mmm, 3)} {/* Mère -> Mère -> Mère */}
          </View>
        </View>
      </View>

      <View style={styles.brackets}>
        <View style={styles.titleGeneration}></View> {/* Empty div */}
        <View style={{height: "100%", marginTop: 11}}>{putBrackets(8)}</View>
      </View>

      {/* 4e gen. */}
      <View style={styles.generation}>
        <View style={styles.titleGeneration}>
          <Text>4e Génération</Text>
        </View>
        <View style={{height: 400 / 8}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(ffff, 4)} {/* Père -> Père -> Père -> Père */}
            {putGeneration(fffm, 4)} {/* Père -> Père -> Père -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 5, height: 400 / 8}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(ffmf, 4)} {/* Père -> Père -> Mère -> Père */}
            {putGeneration(ffmm, 4)} {/* Père -> Père -> Mère -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 5, height: 400 / 8}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(fmff, 4)} {/* Père -> Mère -> Père -> Père */}
            {putGeneration(fmfm, 4)} {/* Père -> Mère -> Père -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 5, height: 400 / 8}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(fmmf, 4)} {/* Père -> Mère -> Mère -> Père */}
            {putGeneration(fmmm, 4)} {/* Père -> Mère -> Mère -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 5, height: 400 / 8}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(mfff, 4)} {/* Mère -> Père -> Père -> Père */}
            {putGeneration(mffm, 4)} {/* Mère -> Père -> Père -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 5, height: 400 / 8}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(mfmf, 4)} {/* Mère -> Père -> Mère -> Père */}
            {putGeneration(mfmm, 4)} {/* Mère -> Père -> Mère -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 5, height: 400 / 8}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(mmff, 4)} {/* Mère -> Mère -> Père -> Père */}
            {putGeneration(mmfm, 4)} {/* Mère -> Mère -> Père -> Mère */}
          </View>
        </View>
        <View style={{marginTop: 5, height: 400 / 8}}>
          <View style={styles.ancestorsContainer}>
            {putGeneration(mmmf, 4)} {/* Mère -> Mère -> Mère -> Père */}
            {putGeneration(mmmm, 4)} {/* Mère -> Mère -> Mère -> Mère */}
          </View>
        </View>
      </View>
    </View>
  );
};

export default Content;
