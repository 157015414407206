// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBox .searchBoxInput p {
    margin: 0;
    margin-bottom: 5px;
}

.searchBox .searchBoxInput {
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
}

.searchBox .searchBoxInput input,
.searchBox .searchBoxInput select {
    padding-left: 10px;
    width: 95%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    font-size: 16px;
}

.searchBox .searchBoxInput input:focus,
.searchBox .searchBoxInput select:focus {
    outline: none;
}

.searchBox .searchBoxInput input::placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.searchBox .checkBox input {
    width: 20px;
}

.searchBox button.searchButton {
    margin-top: 20px;
    margin-left: auto;
    margin-right: 3.5%;
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #476ADC;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.searchBox button:hover {
    background-color: #2F4CBB;
}

.searchBox button:active {
    background-color: #1F3A9D;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Administration/Utilisateurs/Utilisateurs.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;IAEI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,qCAAqC;IACrC,eAAe;AACnB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".searchBox .searchBoxInput p {\n    margin: 0;\n    margin-bottom: 5px;\n}\n\n.searchBox .searchBoxInput {\n    width: calc(50% - 20px);\n    margin-right: 20px;\n    margin-bottom: 20px;\n}\n\n.searchBox .searchBoxInput input,\n.searchBox .searchBoxInput select {\n    padding-left: 10px;\n    width: 95%;\n    height: 35px;\n    border-radius: 5px;\n    border: 1px solid rgba(0, 0, 0, 0.25);\n    font-size: 16px;\n}\n\n.searchBox .searchBoxInput input:focus,\n.searchBox .searchBoxInput select:focus {\n    outline: none;\n}\n\n.searchBox .searchBoxInput input::placeholder {\n    color: rgba(0, 0, 0, 0.25);\n}\n\n.searchBox .checkBox input {\n    width: 20px;\n}\n\n.searchBox button.searchButton {\n    margin-top: 20px;\n    margin-left: auto;\n    margin-right: 3.5%;\n    width: 300px;\n    height: 40px;\n    border-radius: 5px;\n    border: none;\n    background-color: #476ADC;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.searchBox button:hover {\n    background-color: #2F4CBB;\n}\n\n.searchBox button:active {\n    background-color: #1F3A9D;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
