import React, {useEffect, useState} from 'react';
import TableTemplate from '../TableTemplate/TableTemplate';
import {
    Bar,
    BarChart, CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {axiosCore} from "../../utils/http-core-service";

const StatistiqueDSNMois = () => {
    const todayYear = new Date().getFullYear();
    const getYearsPeriod = () => {
        const howManyYearsWeWant = 5;
        let years = []

        for (let i = 0; i < howManyYearsWeWant; i++) {
            years.push((todayYear - i).toString());
        }

        console.log(years)
        return years;
    };

    const years = getYearsPeriod();
    const header = [
        {
            "key": "seuil_depasse",
            "label": "Seuil Dépassé"
        },
        {
            "key": "DSN01",
            "label": "Janvier"
        },
        {
            "key": "DSN02",
            "label": "Février"
        },
        {
            "key": "DSN03",
            "label": "Mars"
        },

        {
            "key": "DSN04",
            "label": "Avril"
        },
        {
            "key": "DSN05",
            "label": "Mai"
        },
        {
            "key": "DSN06",
            "label": "Juin"
        },
        {
            "key": "DSN07",
            "label": "Juillet"
        },
        {
            "key": "DSN08",
            "label": "Août"
        },
        {
            "key": "DSN09",
            "label": "Septembre"
        }
        ,
        {
            "key": "DSN10",
            "label": "Octobre"
        },
        {
            "key": "DSN11",
            "label": "Novembre"
        },
        {
            "key": "DSN12",
            "label": "Décembre"
        }
    ]

    // Couleurs de chart
    const chartBarColors = [
        "#2094fa",
        "#457BD0",
        "#6A63A7",
        "#904A7D",
        "#B53153",
        "#DA192A",
        "#ff0000",
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Map les données du back en données utilisables par les charts
    const mapApiDataToChartsFormat = (data) => {
        console.log(data);
        const map = [
            {name: "Janvier", value: []},
            {name: "Février", value: []},
            {name: "Mars", value: []},
            {name: "Avril", value: []},
            {name: "Mai", value: []},
            {name: "Juin", value: []},
            {name: "Juillet", value: []},
            {name: "Août", value: []},
            {name: "Septembre", value: []},
            {name: "Octobre", value: []},
            {name: "Novembre", value: []},
            {name: "Décembre", value: []},
        ];

        data.map((item) => {
            map[0].value.push(item.DSN01);
            map[1].value.push(item.DSN02);
            map[2].value.push(item.DSN03);
            map[3].value.push(item.DSN04);
            map[4].value.push(item.DSN05);
            map[5].value.push(item.DSN06);
            map[6].value.push(item.DSN07);
            map[7].value.push(item.DSN08);
            map[8].value.push(item.DSN09);
            map[9].value.push(item.DSN10);
            map[10].value.push(item.DSN11);
            map[11].value.push(item.DSN12);
        })

        return map;
    }

    const getStats = async (year) => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/stats-nbr-dsn-saisie/${year}`;
        try {
            const response = await axiosCore.get(url);
            setData(response.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    // Au chargement de la page, récupère les données de l'année en cours
    useEffect(() => {
        getStats(todayYear);
    }, []);

    return (
        <div>
            <h4 className='stat-title'>
                Nombre DSN saisis par mois
            </h4>

            <div className='stat-filter d-flex justify-content-end'>
            <select defaultValue={todayYear} className="form-select form-select-sm me-2" style={{maxWidth: '15rem'}} onChange={e => getStats(e.target.value)} >
                {years.map((year, index) => (
                <option key={index} value={year}>
                    {year}
                </option>
                ))}
            </select>
            </div>
            {(data && data.length > 0 && !isLoading) && (
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={mapApiDataToChartsFormat(data)}>
                        <XAxis dataKey={'name'}/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="4"/>
                        <Tooltip/>
                        <Bar name={"0+ jours de délai"} dataKey={'value[0]'} stackId="a" fill={chartBarColors[0]} />
                        <Bar name={"7+ jours de délai"} dataKey={'value[1]'} stackId="a" fill={chartBarColors[1]} />
                        <Bar name={"10+ jours de délai"} dataKey={'value[2]'} stackId="a" fill={chartBarColors[2]} />
                        <Bar name={"14+ jours de délai"} dataKey={'value[3]'} stackId="a" fill={chartBarColors[3]} />
                        <Bar name={"21+ jours de délai"} dataKey={'value[4]'} stackId="a" fill={chartBarColors[4]} />
                        <Bar name={"28+ jours de délai"} dataKey={'value[5]'} stackId="a" fill={chartBarColors[5]} />
                        <Bar name={"60+ jours de délai"} dataKey={'value[6]'} stackId="a" fill={chartBarColors[6]} />
                    </BarChart>
                </ResponsiveContainer>
            )}
            <br/>
            <TableTemplate
                theadData={header}
                tbodyData={data}
                isLoading={isLoading}
            />
        </div>
    );
}

export default StatistiqueDSNMois;
