import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
// import './Eleveurs.css';
import { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { NavLink } from 'react-router-dom';
import {axiosCore} from "../../utils/http-core-service";

const Eleveurs = () => {
    const header = [
        {
            "key": "id",
            "label": "ID"
        },
        {
            "key": "nom",
            "label": "Nom"
        },
        {
            "key": "prenom",
            "label": "Prénom"
        },
        {
            "key": "mail",
            "label": "Mail"
        },
        {
            "key": "telephone",
            "label": "Téléphone"
        },
        {
            "key": "codePostal",
            "label": "Département"
        },
        {
            "key": "ville",
            "label": "Ville"
        },
        {
            "key": "races",
            "label": "Race(s) élevée(s)",
            "maxWidth": "0px",
            "render": (value) => {
                if (value.length === 0) {
                    return '-';
                }
                value = value.map(ra => ra.intituleRa).join(', ');
                return value;
            }
        },
        {
            "key": "saillies",
            "label": "Date dernière portée",
            "render": (value) => {
                if (value.length === 0) {
                    return '-';
                }
                return value.reduce((max, current) => max.dateNaissance > current.dateNaissance ? max : current).dateNaissance;
            }
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div>
                    <NavLink className='btn btn-outline-primary rounded-5' to={`/eleveurs/${row.id}`}>
                        Voir la fiche
                    </NavLink>
                    <button type="button" className="btn btn-outline-primary rounded-5 ms-2" onClick={() => {
                        window.open(`/eleveurs/${row.id}`, '_blank');
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill='black'><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                    </button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isClickedDisabled, setIsClickedDisabled] = useState(true);
    const [multiSelections, setMultiSelections] = useState([]);
    const [races, setRaces] = useState([]);

    const toggleClickedCheckbox = () => {
        setIsClickedDisabled(prevState => !prevState);
    };

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/eleveur/list`);
            } else {
                response = await axiosCore.get(`/api/eleveur/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'clicked' && search[key] === false) { // If the key is 'clicked' and the value is false
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'raceEleveeEleveur' && search[key].length > 0) { // If the key is 'raceEleveeEleveur' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getRaces = async () => {
        try {
            const response = await axiosCore.get(`/api/race/list`);
            setRaces(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
        getRaces();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Eleveur</h5>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-3">
                    <label htmlFor="idEleveur" className="form-label">ID</label>
                    <input type="text" className="form-control" id="idEleveur"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="nomEleveur" className="form-label">Nom</label>
                    <input type="text" className="form-control" id="nomEleveur" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="prenomEleveur" className="form-label">Prénom</label>
                    <input type="text" className="form-control" id="prenomEleveur"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="mailEleveur" className="form-label">Mail</label>
                    <input type="text" className="form-control" id="mailEleveur"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="departementEleveur" className="form-label">Département</label>
                    <input type="text" className="form-control" id="departementEleveur"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="villeEleveur" className="form-label">Ville</label>
                    <input type="text" className="form-control" id="villeEleveur" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="telEleveur" className="form-label">Téléphone</label>
                    <input type="text" className="form-control" id="telEleveur" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="raceEleveeEleveur" className="form-label">Race(s) élevée(s)</label>
                    <Typeahead
                        id="basic-typeahead-multiple"
                        labelKey="raceEleveeEleveur"
                        multiple
                        options={races.map((value) => value.intituleRa)}
                        onChange={setMultiSelections}
                        selected={multiSelections}
                    />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton' onClick={() => {
                        getData();
                        document.getElementById('idEleveur').value = '';
                        document.getElementById('nomEleveur').value = '';
                        document.getElementById('prenomEleveur').value = '';
                        document.getElementById('mailEleveur').value = '';
                        document.getElementById('departementEleveur').value = '';
                        document.getElementById('villeEleveur').value = '';
                        setMultiSelections([]); // Reset the "race" selection
                        document.getElementById('telEleveur').value = '';
                        setIsClickedDisabled(true);
                        document.getElementById('resetButton').classList.add('d-none');
                    }}>Réinitialiser</button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'idEleveur': document.getElementById('idEleveur').value,
                            'nomEleveur': document.getElementById('nomEleveur').value.replace(/\s/g, '').replace(/-/g, '').toLowerCase(),
                            'prenomEleveur': document.getElementById('prenomEleveur').value.replace(/\s/g, '').replace(/-/g, '').toLowerCase(),
                            'mailEleveur': document.getElementById('mailEleveur').value.replace(/\s/g, ''),
                            'departementEleveur': document.getElementById('departementEleveur').value.replace(/\s/g, ''),
                            'villeEleveur': document.getElementById('villeEleveur').value.replace(/\s/g, '').replace(/-/g, '').toLowerCase(),
                            'raceEleveeEleveur': races.filter((value) => multiSelections.includes(value.intituleRa)).map((value) => value.id), // Get the race ID from the race name
                            'telEleveur': document.getElementById('telEleveur').value.replace(/\s/g, ''),
                            ...(isClickedDisabled ? {} : { 'clicked': document.getElementById('clicked').checked })
                        };
                        console.log(search);
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher</button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des éleveurs</h4>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'éleveurs trouvés' : 'éleveur trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default Eleveurs;
