import * as BreederService from '../../services/breeder.service';
import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Form, FormGroup } from "react-bootstrap";

const SearchEleveur = ({ onSubmitHandler, formLabel, defaultValue = null }) => {
    const [owners, setOwners] = useState([]);
    const setSelectionOnChange = (options, callback) => {
        if (options instanceof Array && 0 < options.length) {
            callback(options[0]);
        } else {
            callback(null);
        }
    };
    const [isOwnersSearchLoading, setIsOwnersSearchLoading] = useState(false);
    const defaultFilterBy = () => true;
    const [owner, setOwner] = useState(null);
    const [validated, validate] = useState(false);
    const handlers = {
        search: (query, type) => {
            switch (type.toLowerCase()) {
                case 'owner':
                case 'owners':
                    setIsOwnersSearchLoading(true);
                    BreederService.queryByName(query)
                        .then(setOwners)
                        .finally(() => setIsOwnersSearchLoading(false));
                    break;
            }
        }
    };
    const renderDisplaySelection = (id, content) => {
        return (<div className="row">
            <span className="col-2 text-end fw-bold">({id})</span>
            <span className="col-10">&nbsp;{content}</span>
        </div>);
    };

    useEffect(() => {
        if (defaultValue && !owner) {
            setOwner(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (owner) {
            onSubmitHandler(owner);
        }
    }, [owner]);

    return (
        <FormGroup className="mb-1">
            <Form.Label>{formLabel}</Form.Label>
            <AsyncTypeahead id="create-or-update-form-fathers-search" name="IdEl" emptyLabel="Aucun résultat."
                placeholder="Rechercher par nom ou prénom" searchText="..." options={owners}
                onChange={(opts) => setSelectionOnChange(opts, setOwner)}
                labelKey="designation" isLoading={isOwnersSearchLoading} filterBy={defaultFilterBy}
                onSearch={(q) => handlers.search(q, 'owners')} required={false}
                renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], opt['designation'])}
                inputProps={{ required: true, className: "searchEleveurInput" }} defaultSelected={defaultValue ? [defaultValue] : []}
            />
        </FormGroup>
    );
};

export default SearchEleveur;