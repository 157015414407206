import * as CatService from '../../services/cat.service';
import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Form, FormGroup } from "react-bootstrap";

const SearchCat = ({ onSubmitHandler, formLabel, name = false, id = false, numOrigin = false, numIdentification = false, sex = 'all' }) => {
    const [cats, setCats] = useState([]);
    const [resetInputs, setResetInputs] = useState(false);
    const setSelectionOnChange = (options, callback) => {
        if (options instanceof Array && 0 < options.length) {
            callback(options[0]);
        } else {
            callback(null);
        }
    };
    const [isCatsSearchLoading, setIsCatsSearchLoading] = useState(false);
    const defaultFilterBy = () => true;
    const [cat, setCat] = useState(null);
    const [validated, validate] = useState(false);
    const handlers = {
        searchByName: (query, type) => {
            switch (type.toLowerCase()) {
                case 'cat':
                case 'cats':
                    setIsCatsSearchLoading(true);
                    CatService.queryBy('name', query, sex)
                        .then(setCats)
                        .finally(() => setIsCatsSearchLoading(false));
                    break;
            }
        },
        searchById: (query, type) => {
            switch (type.toLowerCase()) {
                case 'cat':
                case 'cats':
                    setIsCatsSearchLoading(true);
                    CatService.queryBy('id', query, sex)
                        .then((data) => {
                            setCats(data);
                        })
                        .finally(() => setIsCatsSearchLoading(false));
                    break;
            }
        },
        searchByNumOrigin: (query, type) => {
            switch (type.toLowerCase()) {
                case 'cat':
                case 'cats':
                    setIsCatsSearchLoading(true);
                    CatService.queryBy('numOrigin', query, sex)
                        .then(setCats)
                        .finally(() => setIsCatsSearchLoading(false));
                    break;
            }
        },
        searchByNumIdentification: (query, type) => {
            switch (type.toLowerCase()) {
                case 'cat':
                case 'cats':
                    setIsCatsSearchLoading(true);
                    CatService.queryBy('numIdentification', query, sex)
                        .then(setCats)
                        .finally(() => setIsCatsSearchLoading(false));
                    break;
            }
        },
    };
    const renderDisplaySelection = (id, content) => {
        return (<div className="row">
            <span className="col-3 text-end fw-bold">({id})</span>
            <span className="col-9">{content}</span>
        </div>);
    };

    useEffect(() => {
        if (cat) {
            onSubmitHandler(cat);
        }
    }, [cat]);

    useEffect(() => {
        if (resetInputs) {
            setCat(null);
            setResetInputs(false);
        }
    }, [resetInputs]);

    return (
        <FormGroup className="mb-1 d-flex flex-column gap-2">
            <Form.Label>{formLabel}&nbsp;{isCatsSearchLoading && <span className="spinner-border spinner-border-sm"></span>}</Form.Label>
            {name &&
                <AsyncTypeahead id="create-or-update-form-fathers-search" name="IdCh" emptyLabel="Aucun résultat."
                    placeholder="Rechercher par nom ou prénom" searchText="..." options={cats}
                    onChange={(opts) => setSelectionOnChange(opts, setCat)}
                    labelKey="fullName" filterBy={defaultFilterBy}
                    onSearch={(q) => handlers.searchByName(q, 'cats')} required={false}
                    renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], opt['fullName'])}
                    inputProps={{ required: true, className: "searchCatNameInput" }} selected={cat ? [cat] : []}
                />
            }
            {id &&
                <AsyncTypeahead id="create-or-update-form-fathers-search" name="IdCh" emptyLabel="Aucun résultat."
                    placeholder="Rechercher par ID" searchText="..." options={cats}
                    onChange={(opts) => setSelectionOnChange(opts, setCat)}
                    labelKey="id" filterBy={defaultFilterBy}
                    onSearch={(q) => handlers.searchById(q, 'cats')} required={false}
                    renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], opt['fullName'])}
                    inputProps={{ required: true, className: "searchCatIdInput" }} selected={cat ? [cat] : []}
                />
            }
            {numOrigin &&
                <AsyncTypeahead id="create-or-update-form-fathers-search" name="IdCh" emptyLabel="Aucun résultat."
                    placeholder="Rechercher par numéro d'origine" searchText="..." options={cats}
                    onChange={(opts) => setSelectionOnChange(opts, setCat)}
                    labelKey="numOrigin" filterBy={defaultFilterBy}
                    onSearch={(q) => handlers.searchByNumOrigin(q, 'cats')} required={false}
                    renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], opt['numOrigin'])}
                    inputProps={{ required: true, className: "searchCatNumOriginInput" }} selected={cat ? [cat] : []}
                />
            }
            {numIdentification &&
                <AsyncTypeahead id="create-or-update-form-fathers-search" name="IdCh" emptyLabel="Aucun résultat."
                    placeholder="Rechercher par numéro d'identification" searchText="..." options={cats}
                    onChange={(opts) => setSelectionOnChange(opts, setCat)}
                    labelKey="numTatouage" filterBy={defaultFilterBy}
                    onSearch={(q) => handlers.searchByNumIdentification(q, 'cats')} required={false}
                    renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], opt['numTatouage'])}
                    inputProps={{ required: true, className: "searchCatNumIdentificationInput" }} selected={cat ? [cat] : []}
                />
            }
            {cat && <button type="button" className="btn btn-danger btn-sm" onClick={() => setResetInputs(true)}>Réinitialiser</button>}
        </FormGroup>
    );
};

export default SearchCat;