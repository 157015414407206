import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';
import { axiosCore } from "../../../utils/http-core-service";
import Delete from '../../../components/Delete/Delete';
import OpenLink from '../../../components/OpenLink/OpenLink';
import { getFullAffixe } from '../../../utils/utils';

const FicheAffixe = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [affixe, setAffixe] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosCore.get(`/api/affixe/${id}`);

                setAffixe(response.data);
                console.log("Affixe: ", response.data);
                setIsLoading(false);
            } catch (error) {
                console.log("Error fetching data: ", error);
                setIsLoading(false);
                setError(error);
            }
        }
        fetchData();
    }, [id]);
    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && affixe &&
                <div>
                    <div className='fil-arenae d-flex align-items-center mb-3 justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <h5 className='mb-0'>
                                <NavLink className='link' to={`/affixes`}>Affixe</NavLink>
                            </h5>
                            <div className='mx-2'> &gt; </div>
                            <h5 className='mb-0'>Fiche Affixe</h5>
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                            <Delete
                                id={id}
                                deleteRoute={`/api/affixe/delete/${id}`}
                                redirectTo='/affixes'
                                context={'l\'affixe'}
                                content={`${affixe.nomAffixe} numéro ${affixe.numAffixe}`}
                            />
                            <NavLink className='btn btn-outline-primary' to={`/affixes/${id}/edit`}>Modifier</NavLink>
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <div id='topBox' className="whiteBox">
                            <div className="d-flex flex-column gap-2 align-items-start w-100">
                                <h5><b>Nom d'Affixe / N° d'Affixe: </b> {getFullAffixe(affixe)} / {affixe.numAffixe}</h5>
                                <h6 className='mb-1'>
                                    <b>Création en date du : </b> {affixe.createDate} | {affixe.createBy.firstname} {affixe.createBy.lastname} ({affixe.createBy.initials})
                                </h6>
                                <h6 className='mb-0'>
                                    <b>Modifié en date du : </b> {affixe.updateDate} | {affixe.updateBy.firstname} {affixe.updateBy.lastname} ({affixe.updateBy.initials})
                                </h6>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Position de l'Affixe :	</b></h6>
                                                    <div className='col-8'>{affixe.positionAffixe === 'prefix' ? 'Préfixe' : affixe.positionAffixe === 'suffix' ? 'Suffixe' : affixe.positionAffixe}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Type de l'Affixe :	</b></h6>
                                                    <div className='col-8'>{affixe.type}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Eleveur de l'Affixe :	</b></h6>
                                                    <div className='col-8'>
                                                        <OpenLink
                                                            url={`/eleveurs/${affixe.eleveur.id}`}
                                                            content={`${affixe.eleveur.civilite} ${affixe.eleveur.nom} ${affixe.eleveur.prenom}`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Club de l'Affixe :	</b></h6>
                                                    <div className='col-8'>
                                                        <OpenLink
                                                            url={`/expositions/clubs/${affixe.club.id}`}
                                                            content={`${affixe.club.intituleCl} (${affixe.club.acronyme})`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Date d'envoi :	</b></h6>
                                                    <div className='col-8'>{affixe.dateEnvoiAffixe ? affixe.dateEnvoiAffixe : <i style={{ color: 'grey' }}>Non renseigné</i>}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Accord Agria :	</b></h6>
                                                    <div className='col-8'>{affixe.agria ? 'Oui' : 'Non'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Commentaires d'Affixe : </b></h6>
                                                    <div className='col-8'>{affixe.commentairesAf ? affixe.commentairesAf : <i style={{ color: 'grey' }}>Non renseigné</i>}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger l'éleveur ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                </div>
            }
        </div>
    );
}

export default FicheAffixe;
