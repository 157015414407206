import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
import './RenderDemandes.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { axiosCore } from "../../utils/http-core-service";
import OpenLink from "../../components/OpenLink/OpenLink";
import RenderEleveur from '../../components/RenderEleveur/RenderEleveur.js';
import RenderDemandesInformation from '../../components/Demandes/RenderDemandesInformation/RenderDemandesInformation.js';
import RenderLoofUser from '../../components/Demandes/RenderLoofUser/RenderLoofUser';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FormGroup, Form } from 'react-bootstrap';
import SearchEleveur from '../../components/SearchEleveur/SearchEleveur.js';
import SearchUser from '../../components/SearchUser/SearchUser.js';

const RenderDemandes = ({ params = null }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [loofStatus, setLoofStatus] = useState(params?.paramLoofStatus !== null ? params.paramLoofStatus : 'new');
    const [type, setType] = useState(params?.paramType !== null ? params.paramType : 'all');
    const [demande, setDemande] = useState('');
    const [dateDebut, setDateDebut] = useState(params?.paramDateDebut !== undefined ? params.paramDateDebut : new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0]);
    const [dateFin, setDateFin] = useState(params?.paramDateFin !== undefined ? params.paramDateFin : new Date().toISOString().split('T')[0]);
    const [eleveur, setEleveur] = useState(params?.paramEleveur !== null ? params.paramEleveur : '');
    const [prisEnComptePar, setPrisEnComptePar] = useState('');
    const [transactionNumber, setTransactionNumber] = useState('');
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');

    const header = [
        {
            "key": "id",
            "label": "Numéro",
            "orderFunction": () => {
                setOrderBy("id");
                setOrder(!order || order === 'asc' ? 'desc' : 'asc');
            },
            "orderBy": orderBy,
            "order": order
        },
        {
            "key": "createdAt",
            "label": "Date",
            "orderFunction": () => {
                setOrderBy("createdAt");
                setOrder(!order || order === 'asc' ? 'desc' : 'asc');
            },
            "orderBy": orderBy,
            "order": order
        },
        {
            "key": "loofUserId",
            "label": "Eleveur",
            "render": (value, row, index) => {
                return <RenderEleveur value={value} />;
            },
        },
        {
            "key": "type",
            "label": "Type",
            "render": (value, row, index) => {
                return renderType(value)
            },
            "orderFunction": () => {
                setOrderBy("type");
                setOrder(!order || order === 'asc' ? 'desc' : 'asc');
            },
            "orderBy": orderBy,
            "order": order
        },
        {
            "key": "information",
            "label": "Information",
            "render": (value, row, index) => {
                return <RenderDemandesInformation value={value} data={data.find(item => item.id === row.id)} />;
            }
        },
        {
            "key": "loofStatus",
            "label": "Statut",
            "render": (value, row, index) => {
                return renderStatut(value)
            },
            "orderFunction": () => {
                setOrderBy("loofStatus");
                setOrder(!order || order === 'asc' ? 'desc' : 'asc');
            },
            "orderBy": orderBy,
            "order": order
        },
        {
            "key": "loofOperatorUserId",
            "label": "Affectée à",
            "render": (value, row, index) => {
                return <RenderLoofUser value={value} />;
            }
        }
        // {
        //     "key": "btn",
        //     "label": "Action",
        //     "render": (value, row, index) => {
        //         // const url = '/demandes/' + row.id;
        //         const url = '/demandes/' + row.id;
        //         return <div>
        //             <Link className="btn btn-outline-primary rounded-5" to={url}>Voir la fiche</Link>
        //             <Link className="btn btn-outline-primary rounded-5 ms-2" to={url} target="_blank">
        //                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill='black'><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
        //             </Link>
        //         </div>
        //     }
        // }
    ]

    const listLoofStatus = [
        {
            'value': 'all',
            'label': 'Toute'
        },
        {
            'value': 'new',
            'label': 'Nouvelle, non affectée'
        },
        {
            'value': 'nonClosed',
            'label': 'Non cloturée'
        },
        {
            'value': 'Transmise',
            'label': 'Statut : Nouvelle'
        },
        {
            'value': 'Prise en compte',
            'label': 'Statut : Prise en compte'
        },
        {
            'value': 'En cours de traitement',
            'label': 'Statut : En cours de traitement'
        },
        {
            'value': 'En attente de réponse',
            'label': 'Statut : En attente de réponse'
        },
        {
            'value': 'Validée',
            'label': 'Statut : Validée'
        },
        {
            'value': 'Refusée',
            'label': 'Statut : Refusée'
        },
    ]

    const listType = [
        {
            'value': 'all',
            'label': 'Par defaut'
        },
        {
            'value': 'cat',
            'label': 'Enregistrement d\'un chat à mon nom'
        },
        {
            'value': 'dsn',
            'label': 'DSN'
        },
        {
            'value': 'pedigree',
            'label': 'Pedigree'
        },
        {
            'value': 'register',
            'label': 'Création de compte'
        },
        {
            'value': 'affix',
            'label': 'Transformation d\'affixe'
        },
        {
            'value': 'favorite_cat',
            'label': 'Mettre/Enlever un reproducteur en favori'
        },
        {
            'value': 'update_profile',
            'label': 'Mise à jour éleveur'
        },
        {
            'value': 'create_user',
            'label': 'Création de compte MyLOOF'
        },
        {
            'value': 'salience_visibility',
            'label': 'Changer la visibilité d\'une portée'
        },
    ]

    const renderStatut = (value) => {
        if (!value) {
            return '-'
        }
        let statut = ''
        switch (value) {
            case 'Transmise':
                statut = 'Nouvelle'
                break;
            case 'Prise en compte':
                statut = 'Prise en compte'
                break;
            case 'En cours de traitement':
                statut = 'En cours de traitement'
                break;
            case 'En attente de réponse':
                statut = 'En attente de réponse'
                break;
            case 'Validée':
                statut = 'Validée'
                break;
            case 'Refusée':
                statut = 'Refusée'
                break;
            case 'all':
                statut = 'Toute'
                break;
            default:
                statut = value
                break;
        }

        return statut
    }

    const renderType = (value) => {
        let type = ''
        switch (value) {
            case 'cat':
                type = 'Enregistrement d\'un chat à mon nom'
                break;
            case 'dsn':
                type = 'DSN'
                break;
            case 'pedigree':
                type = 'Pédigrée'
                break;
            case 'register':
                type = 'Création de compte'
                break;
            case 'affix':
                type = 'Transformation d\'affixe'
                break;
            case 'favorite_cat':
                type = 'Mettre/Enlever un reproducteur en favori'
                break;
            case 'update_profile':
                type = 'Mise à jour éleveur'
                break;
            case 'create_user':
                type = 'Création de compte MyLOOF'
                break;
            case 'salience_visibility':
                type = 'Changer la visibilité d\'une portée'
                break;

            default:
                type = value
                break;
        }

        return type
    }

    const getDemandes = async (search = null) => {
        setIsLoading(true)
        try {
            let response = await axiosCore.get(`/api/demandes/list`, {
                params: search === null ? {} : search,
            });
            const datas = response.data.items.map((item) => {
                const createDate = new Date(item.createdAt);
                const updateDate = new Date(item.updatedAt);
                item = {
                    ...item,
                    createdAt: createDate.toLocaleDateString('fr-FR') + " " + createDate.toLocaleTimeString('fr-FR'),
                    updatedAt: updateDate.toLocaleDateString('fr-FR') + " " + updateDate.toLocaleDateString('fr-FR'),
                }
                return item;
            })
            setData(datas);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.totalItem / limit));
            setTotalItems(response.data.totalItem);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearch = () => {
        getDemandes({ loofStatus, type, demande, dateDebut, dateFin, currentPage, eleveur, prisEnComptePar, transactionNumber, orderBy, order });
        setCurrentPage(1);
    }

    const reinitSearch = () => {
        window.location.reload();
    }

    useEffect(() => {
        getDemandes({ loofStatus, type, demande, dateDebut, dateFin, currentPage, eleveur, prisEnComptePar, transactionNumber, orderBy, order });
    }, [currentPage]);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getDemandes({ loofStatus, type, demande, dateDebut, dateFin, currentPage, eleveur, prisEnComptePar, transactionNumber, orderBy, order });
    }, [orderBy, order]);

    return (
        <div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-3">
                    <p>Type de la demande</p>
                    <select className='form-select' name='loofStatus' onChange={(e) => setType(e.target.value)}
                        value={type}>
                        {listType.map((item) => (
                            <option value={item.value} key={item.value + `-type`}>{item.label}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <p>Statut</p>
                    <select className='form-select' name='loofStatus' onChange={(e) => setLoofStatus(e.target.value)}
                        value={loofStatus}>
                        {listLoofStatus.map((item) => (
                            <option value={item.value} key={item.value}>{item.label}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <p>Date</p>
                    <div className="row">
                        <div className="col-6"><input type="date" id="dateDebut" name="dateDebut"
                            className='form-control' value={dateDebut}
                            onChange={(e) => setDateDebut(e.target.value)} /></div>
                        <div className="col-6"><input type="date" id="dateFin" name="dateFin"
                            className='form-control' value={dateFin}
                            onChange={(e) => setDateFin(e.target.value)} /></div>
                    </div>
                </div>
                <div className="col-md-3">
                    <p>N° Demande : </p>
                    <input type="text" id="demande" name="demande" className='form-control' value={demande}
                        onChange={(e) => setDemande(e.target.value)} inputMode="numeric" onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                        }} />
                </div>
                <div className="col-md-3">
                    <p>ID Eleveur : </p>
                    <input type="text" id="eleveurId" name="eleveurId" className='form-control' value={eleveur}
                        onChange={(e) => setEleveur(e.target.value)} inputMode="numeric" />
                </div>
                <div className="col-md-3" id="searchEleveur">
                    <SearchEleveur
                        onSubmitHandler={(data) => {
                            setEleveur(data.id);
                        }}
                        formLabel="Eleveur :"
                    />
                </div>
                <div className="col-md-3">
                    <p>N° de transaction : </p>
                    <input type="text" id="transactionNumber" name="transactionNumber" className='form-control' value={transactionNumber}
                        onChange={(e) => setTransactionNumber(e.target.value)} inputMode="numeric" onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                        }} />
                </div>
                <div className="col-md-3">
                    <SearchUser
                        onSubmitHandler={(data) => {
                            setPrisEnComptePar(data.uid);
                        }}
                        formLabel="Pris en compte par :"
                    />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton'
                        onClick={() => {
                            reinitSearch();
                            document.getElementById('resetButton').classList.add('d-none');
                        }}>Réinitialiser
                    </button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        handleSearch();
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher
                    </button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des demandes</h4>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{
                                width: "30px",
                                height: "30px",
                                textAlign: "center",
                                backgroundColor: "rgba(217, 217, 217, 0.50)",
                                border: "0",
                                borderRadius: ".25rem"
                            }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{totalItems.toLocaleString()} {totalItems > 1 ? 'demandes trouvées' : 'demande trouvée'}</p>
                    </div>
                </div>
                <div className="p-2" style={{ width: "100%" }}>
                    <TableTemplate
                        theadData={header}
                        tbodyData={data}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default RenderDemandes;
