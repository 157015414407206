import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
// import './Pays.css';
import { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { deleteData } from '../../components/DataManagement/Delete.js';
import Insert from '../../components/DataManagement/Insert.js';
import Update from '../../components/DataManagement/Update.js';
import {axiosCore} from "../../utils/http-core-service";

const Pays = () => {
    const header = [
        {
            "key": "intitulePays",
            "label": "Pays",
        },
        {
            "key": "codePays",
            "label": "Code",
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    <button type="button" className="btn btn-outline-primary rounded-5" onClick={() => {
                        openModifyPopup(data[index].idPays, row.intitulePays, row.codePays);
                    }}>Modifier</button>
                    <button type="button" className="btn btn-outline-danger rounded-5" onClick={() => {
                        deleteData('api/pays/delete', data[index].idPays, row.intitulePays, { getData });
                    }}>Supprimer</button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilters, setActiveFilters] = useState({});
    const [intitulePays, setIntitulePays] = useState([]);
    const [intitulePaysSelection, setIntitulePaysSelection] = useState([]);
    const [codePays, setCodePays] = useState([]);
    const [codePaysSelection, setCodePaysSelection] = useState([]);
    const [isInsertPopupOpen, setInsertPopupOpen] = useState(false);
    const [isModifyPopupOpen, setModifyPopupOpen] = useState(false);
    const [modifyPaysDatas, setModifyPaysDatas] = useState({});

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/pays/list`);
            } else {
                response = await axiosCore.get(`/api/pays/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'intitulePays' && search[key].length > 0) { // If the key is 'intitulePays' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'codePays' && search[key].length > 0) { // If the key is 'codePays' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    const openInsertPopup = () => {
        setInsertPopupOpen(true);
    };

    const closeInsertPopup = () => {
        setInsertPopupOpen(false);
        getData();
    };

    const openModifyPopup = (idPays, intitulePays, codePays) => {
        setModifyPaysDatas({ idPays, intitulePays, codePays });
        setModifyPopupOpen(true);
    };

    const closeModifyPopup = () => {
        setModifyPopupOpen(false);
        getData();
    };

    const getAll = async (type) => {
        try {
            const response = await axiosCore.get(`/api/pays/get/${type}`);
            if (type === "intitulePays") {
                setIntitulePays(response.data);
            } else if (type === "codePays") {
                setCodePays(response.data);
            }
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
        getAll("intitulePays");
        getAll("codePays");
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Pays</h5>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-6">
                    <label htmlFor="intitulePays" className="form-label">Pays</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="intitulePays"
                        placeholder={'Rechercher un pays'}
                        options={intitulePays.map((value) => value.intitulePays)}
                        onChange={setIntitulePaysSelection}
                        selected={intitulePaysSelection}
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="codePays" className="form-label">Code pays</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="codePays"
                        placeholder={'Rechercher un code pays'}
                        options={codePays.map((value) => value.codePays)}
                        onChange={setCodePaysSelection}
                        selected={codePaysSelection}
                    />
                </div>
                <div className="col-md-6">
                    {Object.keys(activeFilters).length > 0 && (
                        <small className="text-muted">
                            <b>Filtre actif :&nbsp;</b>
                            {Object.entries(activeFilters).map(([key, value], index) => (
                                <span key={index}>
                                    {key === 'intitulePays' && value.length > 0 && `Pays : ${intitulePays.filter((value1) => value.includes(value1.intitulePays)).map((value1) => value1.intitulePays).join(', ')}`}
                                    {key === 'codePays' && value.length > 0 && `Code pays : ${codePays.filter((value1) => value.includes(value1.codePays)).map((value1) => value1.codePays).join(', ')}`}
                                    {value && index < Object.keys(activeFilters).length - 1 && ',  '}
                                </span>
                            ))}
                        </small>
                    )}
                    {Object.keys(activeFilters).length === 0 && (
                        <small className="text-muted"><b>Filtre actif :</b> Aucun</small>
                    )}
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton' onClick={() => {
                        getData();
                        setActiveFilters({});
                        setIntitulePaysSelection([]); // Reset the intitulePays selection
                        setCodePaysSelection([]); // Reset the codePays selection
                        document.getElementById('resetButton').classList.add('d-none');
                    }}>Réinitialiser</button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'intitulePays': intitulePaysSelection, // Filter the intitulePays by the selected intitulePays
                            'codePays': codePaysSelection, // Filter the codePays by the selected codePays
                        };
                        console.log(search);
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher</button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des pays</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary" onClick={openInsertPopup}>Insérer</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'pays trouvés' : 'pay trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
                {isInsertPopupOpen && (
                    <Insert
                        onClose={closeInsertPopup}
                        title="Insérer un pays"
                        insertDatas={[
                            {"key": "intitulePays", "label": "Pays", "required": true},
                            {"key": "codePays", "label": "Code", "required": true}
                        ]}
                        insertRoute={`api/pays/insert`}
                    />
                )}
                {isModifyPopupOpen && (
                    <Update
                        onClose={closeModifyPopup}
                        title={"Modifier le pays " + modifyPaysDatas.intitulePays}
                        modifyDatas={[
                            {"key": "intitulePays", "label": "Pays", "value": modifyPaysDatas.intitulePays, "required": true},
                            {"key": "codePays", "label": "Code", "value": modifyPaysDatas.codePays, "required": true}
                        ]}
                        modifyRoute={`api/pays/update/${modifyPaysDatas.idPays}`}
                    />
                )}
            </div>
        </div>
    );
}

export default Pays;
