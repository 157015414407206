// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats {
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  padding: 10px;
  display: block;
}
.stats .stat-title {
  margin-bottom: 20px;
}
.stats .stat-filter {
  margin-bottom: 10px;
}
.stats .stat-filter .form-control {
  max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Statistiques/statistiques.scss"],"names":[],"mappings":"AAAA;EACI,+CAAA;EACA,gBAAA;EACA,aAAA;EACA,cAAA;AACJ;AAAI;EACI,mBAAA;AAER;AAAI;EACI,mBAAA;AAER;AAAQ;EACI,gBAAA;AAEZ","sourcesContent":[".stats {\n    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);\n    margin-top: 30px;\n    padding: 10px;\n    display: block;\n    .stat-title {\n        margin-bottom: 20px;\n    }\n    .stat-filter {\n        margin-bottom: 10px;\n\n        .form-control {\n            max-width: 200px;\n        }\n    }\n}\n\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
