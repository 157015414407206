import React from 'react';
import IsLoading from '../IsLoading/IsLoading';
import DOMPurify from 'dompurify';

const TableTemplate = ({ theadData, tbodyData, isLoading = false, limit = null }) => {
    let displayData = tbodyData;

    if (limit !== null) {
        displayData = tbodyData.slice(0, limit);
    }

    return (
        <table className="table table-striped table-hover table-bordered" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <thead>
                <tr>
                    {theadData.map((column, index) => (
                        <th key={index}
                            style={{
                                backgroundColor: "rgb(71, 101, 118)",
                                color: "white",
                                display: column.column_hidden ? 'none' : 'table-cell',
                                cursor: column.orderFunction ? 'pointer' : 'default',
                                textDecoration: column.orderFunction ? 'underline' : 'none'
                            }}
                            onClick={column.orderFunction ? () => column.orderFunction(column.order) : null}
                        >
                            {column.label} {column.orderFunction && (column.key === column.orderBy ? (column.order === 'asc' ? <span>&#9650;</span> : <span>&#9660;</span>) : null)}
                        </th>
                    ))}
            </tr>
            </thead>
            <tbody>
                {isLoading && <tr>
                    <td colSpan={theadData.length}>
                        <IsLoading />
                    </td>
                </tr>}
                {!isLoading && displayData.map((rowData, rowIndex) => (
                    <tr key={rowIndex}>
                        {theadData.map((column, cellIndex) => (
                            <td key={cellIndex} style={{ display: column.column_hidden ? 'none' : 'table-cell', maxWidth: column.maxWidth, whiteSpace: "pre-line" }}>
                                {column.render
                                    ? column.hasInnerHTML
                                        ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(column.render(rowData[column.key], rowData, rowIndex)) }} />
                                        : column.render(rowData[column.key], rowData, rowIndex)
                                    : column.hasInnerHTML
                                        ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('')}}/>
                                            : rowData[column.key] !== undefined && rowData[column.key] !== null
                                                ? (column.key === 'AnneeCot' || column.key === 'Millesime' || column.key === 'Annee')
                                        ? rowData[column.key]
                                                    : rowData[column.key] : '-'
                                }
                            </td>
                        ))}
                    </tr>
                ))}
                {(!isLoading && displayData.length === 0) &&
                    <tr>
                        <td colSpan={theadData.length}>Pas de donnée à afficher</td>
                    </tr>
                }
            </tbody>
        </table>
    );
};

export default TableTemplate;
