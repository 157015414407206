import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';

const Header = ({ pedigreeInfos }) => {
  const titres = (!isEmpty(pedigreeInfos) && pedigreeInfos.titres) || '';
  const titreDist = (!isEmpty(pedigreeInfos) && pedigreeInfos.titreDist) || '';
  const nom = (!isEmpty(pedigreeInfos) && pedigreeInfos.nomChat) || '';
  const qualification = (!isEmpty(pedigreeInfos) && pedigreeInfos.qualification) || '';

  const getTitreWithDist = (titre, federation) => {
    const distsForThisFed = titreDist.find((dist) => dist.federation === federation);
    const dists = distsForThisFed ? distsForThisFed.titres.map((dist) => dist.titreFull).join(', ') : '';

    const titreFull = titre?.titres?.[0]?.titreFull || '';

    if (federation === 'Autre') {
      if (titreFull && dists) return `${titreFull} - ${dists}`;
      if (titreFull) return `${titreFull}`;
      if (dists) return `${dists}`;
      return '';
    }

    if (titreFull && dists) return `${titreFull} - ${dists} (${federation})`;
    if (titreFull) return `${titreFull} (${federation})`;
    if (dists) return `${dists} (${federation})`;
    return '';
  };

  const getTitres = (titres) => {
    if (!titres || titres.length === 0) return '';

    const sortedTitres = titres.sort((a, b) => {
      if (a.federation === 'LOOF') return -1; // "LOOF" comes first
      if (b.federation === 'LOOF') return 1; // "LOOF" comes first
      return 0;
    });

    const allTitres = sortedTitres
      .map((titre) => getTitreWithDist(titre, titre.federation))
      .filter((tmp) => tmp !== '')
      .join(' / ');

    return allTitres;
  };

  return (
    <View style={styles.container}>
      <View style={styles.detailColumn}>
        <Text style={styles.titre}>
          {getTitres(titres)}
        </Text>
        <Text style={styles.name}>{nom}</Text>
        <Text style={styles.qualification}>{qualification}</Text>
      </View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
  },
  detailColumn: {
    width: '100%',
  },
  name: {
    fontSize: 15,
    fontFamily: 'Lato Bold',
    textTransform: 'uppercase',
  },
  titre: {
    fontSize: 10,
    justifySelf: 'flex-end',
    marginBottom: 5,
  },
  qualification: {
    fontFamily: 'Lato Bold',
    color: 'red',
    fontSize: 11,
    textTransform: 'uppercase',
    alignSelf: 'flex-end',
    marginBottom: 5,
  },
});
