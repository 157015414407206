import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';

const Watermark = () => {

    return (
        <View style={styles.container}>
            <View style={styles.watermark}>
                <Text>SPECIMEN</Text>
            </View>
        </View>
    );
};

export default Watermark;

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    watermark: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.07,
        fontSize: 150,
        color: 'black',
        fontWeight: 'bold',
        transform: 'rotate(-25deg)',
    },
});
