import React from 'react';
import TableTemplate from '../../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../../components/Pagination/Pagination.js';
// import './ExpoClubs.css';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FormCheck } from 'react-bootstrap';
import {axiosCore} from "../../../utils/http-core-service";

const ExpoClubs = () => {
    const header = [
        {
            "key": "idClub",
            "label": "ID"
        },
        {
            "key": "acroClub",
            "label": "Acronyme"
        },
        {
            "key": "nomClub",
            "label": "Nom du club"
        },
        {
            "key": "paysClub",
            "label": "Pays"
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div>
                    <NavLink className='btn btn-outline-primary rounded-5' to={`/expositions/clubs/${row.idClub}`}>
                        Voir la fiche
                    </NavLink>
                    <button type="button" className="btn btn-outline-primary rounded-5 ms-2" onClick={() => {
                        window.open(`/expositions/clubs/${row.idClub}`, '_blank');
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill='black'><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                    </button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilters, setActiveFilters] = useState({});
    const [pays, setPays] = useState([]);
    const [paysSelection, setPaysSelection] = useState([]);

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/club/list`);
            } else {
                response = await axiosCore.get(`/api/club/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getPays = async () => {
        try {
            const response = await axiosCore.get(`/api/pays/list`);
            setPays(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
        getPays();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Clubs</h5>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-1">
                    <label htmlFor="idClub" className="form-label">ID Club</label>
                    <input type="text" className="form-control" id="idClub" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="acroClub" className="form-label">Acronyme</label>
                    <input type="text" className="form-control" id="acroClub" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="nomClub" className="form-label">Nom</label>
                    <input type="text" className="form-control" id="nomClub" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="paysClub" className="form-label">Pays</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="paysClub"
                        placeholder={'Rechercher un pays'}
                        options={pays.map((value) => value.intitulePays)}
                        onChange={setPaysSelection}
                        selected={paysSelection}
                    />
                </div>
                <div className="col-md-2">
                    <label htmlFor="excludLoof" className="form-label">Exclure les clubs adhérents LOOF</label>
                    <FormCheck
                        type="checkbox"
                        id="excludLoof"
                        defaultChecked={true}
                    />
                </div>
                <div className="col-md-6">
                    {Object.keys(activeFilters).length > 0 && (
                        <small className="text-muted">
                            <b>Filtre actif :&nbsp;</b>
                            {Object.entries(activeFilters).map(([key, value], index) => (
                                <span key={index}>
                                    {key === 'idClub' && value && `ID : ${value}`}
                                    {key === 'acroClub' && value && `Acronyme : ${value}`}
                                    {key === 'nomClub' && value && `Nom : ${value}`}
                                    {key === 'paysClub' && value && `Pays : ${pays.filter((pays) => pays.idPays === value)[0]?.intitulePays}`}
                                    {key === 'excludLoof' && value && 'Clubs adhérents LOOF exclus'}
                                    {value && index < Object.keys(activeFilters).length - 1 && ',  '}
                                </span>
                            ))}
                        </small>
                    )}
                    {Object.keys(activeFilters).length === 0 && (
                        <small className="text-muted"><b>Filtre actif :</b> Aucun</small>
                    )}
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton' onClick={() => {
                        getData();
                        setActiveFilters({});
                        document.getElementById('idClub').value = '';
                        document.getElementById('acroClub').value = '';
                        document.getElementById('nomClub').value = '';
                        setPaysSelection([]);
                        document.getElementById('excludLoof').checked = true;
                        document.getElementById('resetButton').classList.add('d-none');
                    }}>Réinitialiser</button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'idClub': document.getElementById('idClub').value.replace(/\s/g, ''), // Retire les espaces de la recherche
                            'acroClub': document.getElementById('acroClub').value.replace(/\s/g, ''),
                            'nomClub': document.getElementById('nomClub').value.trim(),
                            'paysClub': pays.filter((value) => value.intitulePays === paysSelection[0])[0]?.idPays,
                            'excludLoof': document.getElementById('excludLoof').checked ? true : false,
                        };
                        console.log(search);
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher</button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des clubs</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary">Insérer</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'clubs trouvés' : 'club trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default ExpoClubs;
