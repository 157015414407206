import React from 'react';
import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Signature from './assets/Signature_Bourreau.jpg';
import { isEmpty } from 'lodash';

const Footer = ({ pedigreeInfos }) => {
  const dateEnregistrementChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.dateSaisieChat) || '';
  const dateEdition = new Date().toLocaleDateString('fr-FR');

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.bold}>Livre Officiel des Origines Félines - <Link style={styles.link}>www.loof.asso.fr</Link></Text>
      </View>
      <View style={styles.section}>
        <Image src={Signature} style={{ width: 100, height: 50 }} />
        <Text style={{ ...styles.bold, marginBottom: 4 }}>La Présidente du LOOF, par délégation</Text>
        <Text style={styles.dates}>Date d'enregistrement : {dateEnregistrementChat}</Text>
        <Text style={styles.dates}>Date d'édition : {dateEdition}</Text>
      </View>
    </View>
  );
};

export default Footer;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 9,
    alignItems: 'flex-end'
  },
  section: {
    alignItems: 'center',
  },
  bold: {
    fontFamily: 'Lato Bold',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
  dates: {
    fontSize: 8,
  }
});
