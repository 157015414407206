import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { axiosCore } from '../../utils/http-core-service';

const Delete = ({ id, deleteRoute, redirectTo, context, content }) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = async () => {
        try {
            await axiosCore.delete(deleteRoute);
            window.location.href = redirectTo;
        } catch (error) {
            setError(error);
            console.log("Error deleting data: ", error);
        }
    }

    return (
        <>
            <Button variant="outline-danger" onClick={handleShow}>
                Supprimer
            </Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Supprimer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Êtes-vous sûr de vouloir supprimer {context} {content} ?</Modal.Body>
                {
                    error &&
                    <Modal.Body>
                        <div className="alert alert-danger" role="alert">
                            {error.message}
                            <br />
                            {error.response.data.error}
                        </div>
                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Delete;
