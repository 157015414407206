import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';

const PorteeCatteryBreeder = ({ breederInfos, porteeInfos, catInfos, pedColor }) => {
  const portee = (!isEmpty(porteeInfos) && porteeInfos) || '';
  const affixe = (!isEmpty(breederInfos) && breederInfos.affixe) || '';
  const breeder = (!isEmpty(breederInfos) &&
    [
      breederInfos.prenomEl + ' ' + breederInfos.nomEl,
      ((breederInfos.codePostalEl && breederInfos.villeEl) && `${breederInfos.codePostalEl} ${breederInfos.villeEl}`) || null,
    ]) || '';
  const owner = (!isEmpty(breederInfos) &&
    [
      ((breederInfos.prenomOwner && breederInfos.nomOwner) && `${breederInfos.prenomOwner} ${breederInfos.nomOwner}`) || null,
      ((breederInfos.codePostalOwner && breederInfos.villeOwner) && `${breederInfos.codePostalOwner} ${breederInfos.villeOwner}`) || null,
    ]) || '';
  const mentions = (!isEmpty(catInfos) && catInfos.mentionPedigree) || '';
  const commentairePedigree = (!isEmpty(catInfos) && catInfos.commentairePedigree) || '';
  const CeDocumentNaPasValeurDePedigree = 'Ce document n\'a pas valeur de pedigree.';

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      border: 1,
      borderColor: pedColor,
      justifyContent: 'space-between',
      padding: 5,
      fontSize: 8,
    },
    section: {
      marginBottom: 3,
    },
    sectionDetails: {
      marginBottom: 3,
    },
    title: {
      fontFamily: 'Lato Bold',
      color: pedColor,
    },
    value: {
      flexDirection: 'column',
    },
    affixe: {
      textTransform: 'uppercase',
    },
    mention: {
      fontFamily: 'Lato Bold',
    },
  });

  useEffect(() => {
  }, [portee]);

  return (
    <View style={styles.container}>
      {mentions !== '' || commentairePedigree !== '' ?
          (<View style={styles.section}>
          <Text style={styles.title}>Mention(s) :</Text>
          {mentions !== '' ? (
            <View style={styles.value}>
              {mentions.includes(CeDocumentNaPasValeurDePedigree) ? (
                <>
                  <Text style={styles.mention}>{mentions.replace(CeDocumentNaPasValeurDePedigree, '')}</Text>
                  <Text style={{ ...styles.mention, color: 'red' }}>{CeDocumentNaPasValeurDePedigree}</Text>
                </>
              ) : (
                <Text style={styles.mention}>{mentions}</Text>
              )}
              {commentairePedigree && commentairePedigree.includes(CeDocumentNaPasValeurDePedigree) ? (
                <>
                  <Text style={styles.mention}>{commentairePedigree.replace(CeDocumentNaPasValeurDePedigree, '')}</Text>
                  <Text style={{ ...styles.mention, color: 'red' }}>{CeDocumentNaPasValeurDePedigree}</Text>
                </>
              ) : (
                <Text style={styles.mention}>{commentairePedigree}</Text>
              )}
            </View>
          ) : commentairePedigree !== '' ? (
            <View style={styles.value}>
              {commentairePedigree.includes(CeDocumentNaPasValeurDePedigree) ? (
                <>
                  <Text style={styles.mention}>{commentairePedigree.replace(CeDocumentNaPasValeurDePedigree, '')}</Text>
                  <Text style={{ ...styles.mention, color: 'red' }}>{CeDocumentNaPasValeurDePedigree}</Text>
                </>
              ) : (
                <Text style={styles.mention}>{commentairePedigree}</Text>
              )}
            </View>
          ) : null}
        </View>
      ) : ''}
      {portee && (
          <View style={styles.sectionDetails}>
            <Text style={styles.title}>Détails de la portée / Litter :</Text>
            <View style={styles.value}>
              {portee.map((chaton, index) => (
                  <Text key={index}>{chaton.nb} {chaton.sexeChat} {chaton.codeEMS}</Text>
              ))}
            </View>
          </View>
      )}
      <View style={styles.section}>
        <View style={styles.section}>
          <Text style={styles.title}>Chatterie / Cattery :</Text>
          <View style={styles.value}>
            <Text style={styles.affixe}>{affixe}</Text>
          </View>
        </View>
        <Text style={styles.title}>Eleveur / Breeder :</Text>
        <View style={styles.value}>
          {breeder && breeder.map((info, index) => (
              <Text key={index}>{info}</Text>
          ))}
        </View>
      </View>
      {owner[0] ? (
        <View style={styles.section}>
          <Text style={styles.title}>Propriétaire / Owner :</Text>
          <View style={styles.value}>
            {owner.map((info, index) => (
              <Text key={index}>{info}</Text>
            ))}
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default PorteeCatteryBreeder;
