import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
// import './LG.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useState, useEffect } from 'react';
import {axiosCore} from "../../utils/http-core-service";

const LG = () => {
    const header = [
        {
            "key": "sigleLG",
            "label": "Sigle"
        },
        {
            "key": "paysLG",
            "label": "Pays"
        },
        {
            "key": "federationLG",
            "label": "Fédération"
        },
        {
            "key": "nomLG",
            "label": "Nom"
        },
        {
            "key": "contactLG",
            "label": "Contact"
        },
        {
            "key": "commentaireLG",
            "label": "Commentaire",
            "hasInnerHTML": true
        },
        {
            "key": "exemplePedLG",
            "label": "Exemple de pédigrée"
        },
        {
            "key": "precisionsLG",
            "label": "Précisions"
        },
        {
            "key": "certificatLG",
            "label": "Certificat"
        },
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilters, setActiveFilters] = useState({});
    const [sigleSelection, setSigleSelection] = useState([]);
    const [sigles, setSigles] = useState([]);
    const [federationSelection, setFederationSelection] = useState([]);
    const [federations, setFederations] = useState([]);
    const [paysSelection, setPaysSelection] = useState([]);
    const [pays, setPays] = useState([]);

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/LG/list`);
            } else {
                response = await axiosCore.get(`/api/LG/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'siglesLG' && search[key].length > 0) { // If the key is 'siglesLG' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'federationsLG' && search[key].length > 0) { // If the key is 'federationsLG' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'paysLG' && search[key].length > 0) { // If the key is 'paysLG' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAll = async (type) => {
        try {
            const response = await axiosCore.get(`/api/LG/get/${type}`);
            if (type === "sigles") {
                setSigles(response.data);
            } else if (type === "federations") {
                setFederations(response.data);
            } else if (type === "pays") {
                setPays(response.data);
            }
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
        getAll("sigles");
        getAll("federations");
        getAll("pays");
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <h2>Livres généalogiques</h2>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-2">
                    <label htmlFor="sigleLG" className="form-label">Sigle</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="sigleLG"
                        placeholder={'Rechercher un sigle'}
                        options={sigles.map((value) => value.sigleLG)}
                        onChange={setSigleSelection}
                        selected={sigleSelection}
                    />
                </div>
                <div className="col-md-2">
                    <label htmlFor="federationLG" className="form-label">Fédération</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="federationLG"
                        placeholder={'Rechercher une fédération'}
                        options={federations.map((value) => value.federationLG)}
                        onChange={setFederationSelection}
                        selected={federationSelection}
                    />
                </div>
                <div className="col-md-2">
                    <label htmlFor="paysLG" className="form-label">Pays</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="paysLG"
                        placeholder={'Rechercher un pays'}
                        options={pays.map((value) => value.paysLG)}
                        onChange={setPaysSelection}
                        selected={paysSelection}
                    />
                </div>
                <div className="col-md-3">
                    <label htmlFor="nomLG" className="form-label">Nom</label>
                    <input type="text" className="form-control" id="nomLG" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="mailLG" className="form-label">Mail</label>
                    <input type="text" className="form-control" id="mailLG" />
                </div>
                <div className="col-md-6">
                    {Object.keys(activeFilters).length > 0 && (
                        <small className="text-muted">
                            <b>Filtre actif :&nbsp;</b>
                            {Object.entries(activeFilters).map(([key, value], index) => (
                                <span key={index}>
                                    {key === 'sigleLG' && value.length > 0 && `Sigle : ${sigles.filter((value1) => value.includes(value1.sigleLG)).map((value1) => value1.sigleLG).join(', ')}`}
                                    {key === 'paysLG' && value.length > 0 && `Pays : ${pays.filter((value1) => value.includes(value1.paysLG)).map((value1) => value1.paysLG).join(', ')}`}
                                    {key === 'federationLG' && value.length > 0 && `Fédération : ${federations.filter((value1) => value.includes(value1.federationLG)).map((value1) => value1.federationLG).join(', ')}`}
                                    {key === 'nomLG' && value && `Nom : ${value}`}
                                    {key === 'mailLG' && value && `Mail : ${value}`}
                                    {value && index < Object.keys(activeFilters).length - 1 && ',  '}
                                </span>
                            ))}
                        </small>
                    )}
                    {Object.keys(activeFilters).length === 0 && (
                        <small className="text-muted"><b>Filtre actif :</b> Aucun</small>
                    )}
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton' onClick={() => {
                        getData();
                        setActiveFilters({});
                        setSigleSelection([]); // Reset the sigle selection
                        setPaysSelection([]); // Reset the pays selection
                        setFederationSelection([]); // Reset the federation selection
                        document.getElementById('nomLG').value = '';
                        document.getElementById('mailLG').value = '';
                        document.getElementById('resetButton').classList.add('d-none');
                    }}>Réinitialiser</button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'sigleLG': sigles.filter((value) => sigleSelection.includes(value.sigleLG)).map((value) => value.sigleLG), // Filter the sigles by the selected sigles
                            'paysLG': pays.filter((value) => paysSelection.includes(value.paysLG)).map((value) => value.paysLG), // Filter the pays by the selected pays
                            'federationLG': federations.filter((value) => federationSelection.includes(value.federationLG)).map((value) => value.federationLG), // Filter the federations by the selected federations
                            'nomLG': document.getElementById('nomLG').value.trim(),
                            'mailLG': document.getElementById('mailLG').value.trim(),
                        };
                        console.log(search);
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher</button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des livres généalogiques</h4>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'LG trouvés' : 'LG trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default LG;
