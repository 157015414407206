import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
import { useState, useEffect } from 'react';
import Insert from '../../components/DataManagement/Insert.js';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import OpenLink from '../../components/OpenLink/OpenLink.js';

const RoyalCanin = () => {
    const header = [
        {
            "key": "id",
            "label": "ID",
        },
        {
            "key": "status",
            "label": "Status",
        },
        {
            "key": "type",
            "label": "Type",
        },
        {
            "key": "price",
            "label": "Prix",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    {row.price} €
                </div>
            }
        },
        {
            "key": "quantity",
            "label": "Quantité",
        },
        {
            "key": "productData",
            "label": "Nom du produit",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    {row.productData.name}
                </div>
            }
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    <NavLink className='btn btn-outline-primary rounded-5' to={`/royal-canin/detail/${row.id}`}>
                        Voir la fiche
                    </NavLink>
                    <OpenLink url={"/royal-canin/detail/" + row.id} size="md" />
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [search, setSearch] = useState([]);

    const getAll = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            let url = `${process.env.REACT_APP_ROYAL_CANIN_API_URL}/purchases/export/json`;

            if (search && search.id) {
                url = `${process.env.REACT_APP_ROYAL_CANIN_API_URL}/purchases/${search.id}`;
            } else if (search && search.dateFrom && search.dateTo) {
                url += `?dateFrom=${search.dateFrom}&dateTo=${search.dateTo}`;
            } else {
                url += `?dateFrom=2020-01-01&dateTo=${new Date().toISOString().split('T')[0]}`;
            }
            response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_ROYAL_CANIN_API_TOKEN}`
                }
            });
            console.log('url', url);
            if (search && search.id) {
                setData((response.data ? [response.data] : []));
                setTotalPages(Math.ceil(response.data.length / limit));
            } else {
                setData(response.data.result);
                setTotalPages(Math.ceil(response.data.result.length / limit));
            }
            setIsLoading(false);
            setCurrentPage(1);
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 404) {
                setData([]);
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        getAll();
    }, []);

    useEffect(() => {
        console.log('data', data);
    }, [data]);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    const handleSearch = (event) => {
        event.preventDefault();
        getAll(search)
    }

    const reinitSearch = () => {
        setSearch([]);
        document.getElementById('search').value = '';
        document.getElementById('dateFrom').value = '2020-01-01';
        document.getElementById('dateFrom').disabled = false;
        document.getElementById('dateTo').value = new Date().toISOString().split('T')[0];
        document.getElementById('dateTo').disabled = false;
        getAll()
    }

    const exportToCsv = async (search, filename) => {
        let url = `${process.env.REACT_APP_ROYAL_CANIN_API_URL}/purchases/export/csv`;

        if (search && search.dateFrom && search.dateTo) {
            url += `?dateFrom=${search.dateFrom}&dateTo=${search.dateTo}`;
        } else {
            url += `?dateFrom=2020-01-01&dateTo=${new Date().toISOString().split('T')[0]}`;
        }

        console.log('url', url);

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_ROYAL_CANIN_API_TOKEN}`
                },
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'text/csv' });
            saveAs(blob, filename);
        } catch (error) {
            console.error('Error exporting CSV:', error);
        }
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Royal Canin</h5>
            </div>
            <div className="searchBox whiteBox request">
                <form onSubmit={handleSearch}>
                    <div className="row">
                        <div className="col-3">
                            <p>ID de l'achat</p>
                            <input type="text" id="search" name="search" className='form-control' onChange={e => {
                                if (e.target.value) {
                                    document.getElementById('dateFrom').disabled = true;
                                    document.getElementById('dateTo').disabled = true;
                                }
                                else {
                                    document.getElementById('dateFrom').disabled = false;
                                    document.getElementById('dateTo').disabled = false;
                                }
                            }} />
                        </div>
                        <div className="col-3">
                            <p>Date de début</p>
                            <input type="date" id="dateFrom" name="dateFrom" className='form-control' defaultValue="2020-01-01" max={new Date().toISOString().split('T')[0]} />
                        </div>
                        <div className="col-3">
                            <p>Date de fin</p>
                            <input type="date" id="dateTo" name="dateTo" className='form-control' defaultValue={new Date().toISOString().split('T')[0]} max={new Date().toISOString().split('T')[0]} />
                        </div>
                    </div>
                    <div className="d-flex mt-4 justify-content-end">
                        <button className='btn btn-primary mx-3' type='submit'
                            onClick={() => {
                                setSearch({
                                    id: document.getElementById('search').value ? document.getElementById('search').value : '',
                                    dateFrom: document.getElementById('dateFrom').value ? document.getElementById('dateFrom').value : '',
                                    dateTo: document.getElementById('dateTo').value ? document.getElementById('dateTo').value : ''
                                });
                            }}
                        >Rechercher</button>
                        <button className='btn btn-danger' type='button' onClick={reinitSearch}>Voir tout</button>
                    </div>
                </form>
            </div>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des achats via Royal Canin</h4>
                    </div>
                    <div className="ms-auto">
                        <button className='btn btn-outline-primary' onClick={() => {
                            exportToCsv(search, `export-royal-canin-${search.dateFrom ? search.dateFrom : '2020-01-01'}-${search.dateTo ? search.dateTo : new Date().toISOString().split('T')[0]}.csv`);
                        }}>Exporter en CSV du {search.dateFrom ? search.dateFrom.split('-').reverse().join('/') : '01/01/2020'} au {search.dateTo ? search.dateTo.split('-').reverse().join('/') : new Date().toISOString().split('T')[0].split('-').reverse().join('/')}</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'achats trouvés' : 'achat trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{ width: "100%" }}>
                    <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default RoyalCanin;
