import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
// import './Yeux.css';
import { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { deleteData } from '../../components/DataManagement/Delete.js';
import Insert from '../../components/DataManagement/Insert.js';
import Update from '../../components/DataManagement/Update.js';
import {axiosCore} from "../../utils/http-core-service";

const Yeux = () => {
    const header = [
        {
            "key": "intituleYeux",
            "label": "Yeux",
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    <button type="button" className="btn btn-outline-primary rounded-5" onClick={() => {
                        openModifyPopup(data[index].idYeux, row.intituleYeux);
                    }}>Modifier</button>
                    <button type="button" className="btn btn-outline-danger rounded-5" onClick={() => {
                        deleteData('api/yeux/delete', data[index].idYeux, row.intituleYeux, { getData });
                    }}>Supprimer</button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilters, setActiveFilters] = useState({});
    const [intituleYeux, setIntituleYeux] = useState([]);
    const [intituleYeuxSelection, setIntituleYeuxSelection] = useState([]);
    const [isInsertPopupOpen, setInsertPopupOpen] = useState(false);
    const [isModifyPopupOpen, setModifyPopupOpen] = useState(false);
    const [modifyYeuxDatas, setModifyYeuxDatas] = useState({});

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/yeux/list`);
            } else {
                response = await axiosCore.get(`/api/yeux/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'intituleYeux' && search[key].length > 0) { // If the key is 'intituleYeux' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    const openInsertPopup = () => {
        setInsertPopupOpen(true);
    };

    const closeInsertPopup = () => {
        setInsertPopupOpen(false);
        getData();
    };

    const openModifyPopup = (idYeux, intituleYeux) => {
        setModifyYeuxDatas({ idYeux, intituleYeux });
        setModifyPopupOpen(true);
    };

    const closeModifyPopup = () => {
        setModifyPopupOpen(false);
        getData();
    };

    const getAll = async (type) => {
        try {
            const response = await axiosCore.get(`/api/yeux/get/${type}`);
            if (type === "intituleYeux") {
                setIntituleYeux(response.data);
            }
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
        getAll("intituleYeux");
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Yeux</h5>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-3">
                    <label htmlFor="intituleYeux" className="form-label">Yeux</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="intituleYeux"
                        placeholder={'Rechercher des yeux'}
                        options={intituleYeux.map((value) => value.intituleYeux)}
                        onChange={setIntituleYeuxSelection}
                        selected={intituleYeuxSelection}
                    />
                </div>
                <div className="col-md-9"></div>
                <div className="col-md-6">
                    {Object.keys(activeFilters).length > 0 && (
                        <small className="text-muted">
                            <b>Filtre actif :&nbsp;</b>
                            {Object.entries(activeFilters).map(([key, value], index) => (
                                <span key={index}>
                                    {key === 'intituleYeux' && value.length > 0 && `Yeux : ${intituleYeux.filter((value1) => value.includes(value1.intituleYeux)).map((value1) => value1.intituleYeux).join(', ')}`}
                                    {value && index < Object.keys(activeFilters).length - 1 && ',  '}
                                </span>
                            ))}
                        </small>
                    )}
                    {Object.keys(activeFilters).length === 0 && (
                        <small className="text-muted"><b>Filtre actif :</b> Aucun</small>
                    )}
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton' onClick={() => {
                        getData();
                        setActiveFilters({});
                        setIntituleYeuxSelection([]); // Reset the intituleYeux selection
                        document.getElementById('resetButton').classList.add('d-none');
                    }}>Réinitialiser</button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'intituleYeux': intituleYeuxSelection, // Filter the intituleYeux by the selected intituleYeux
                        };
                        console.log(search);
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher</button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des yeux</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary" onClick={openInsertPopup}>Insérer</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'yeux trouvés' : 'oeil trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                    <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
                {isInsertPopupOpen && (
                    <Insert
                        onClose={closeInsertPopup}
                        title="Insérer un oeil"
                        insertDatas={[
                            {"key": "intituleYeux", "label": "Yeux", "required": true},
                        ]}
                        insertRoute={`api/yeux/insert`}
                    />
                )}
                {isModifyPopupOpen && (
                    <Update
                        onClose={closeModifyPopup}
                        title={"Modifier l'oeil " + modifyYeuxDatas.intituleYeux}
                        modifyDatas={[
                            {"key": "intituleYeux", "label": "Yeux", "value": modifyYeuxDatas.intituleYeux, "required": true},
                        ]}
                        modifyRoute={`api/yeux/update/${modifyYeuxDatas.idYeux}`}
                    />
                )}
            </div>
        </div>
    );
}

export default Yeux;
