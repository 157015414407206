import React, { useEffect } from 'react';
import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Signature from './assets/Signature_Bourreau.jpg';
import { isEmpty } from 'lodash';
import QRCode from 'qrcode';

const Footer = ({ pedigreeInfos }) => {
  const dateEnregistrementChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.dateSaisieChat) || '';
  const dateEdition = new Date().toLocaleDateString('fr-FR');
  const [QRCodeString, setQRCodeString] = React.useState('');

  useEffect(() => {
    QRCode.toDataURL('https://www.loof.asso.fr')
      .then(dataUrl => {
        setQRCodeString(dataUrl);
      }
    );
  }, []);

  return (
      <View style={styles.container}>
        <View style={styles.section}>
          <Image src={QRCodeString} style={{ width: 50, height: 50 }} />
        </View>
        <View style={styles.section}>
          <Image src={Signature} style={{ width: 100, height: 50 }} />
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ ...styles.bold, marginBottom: 4 }}>La Présidente du LOOF, par délégation</Text>
            <Text style={styles.dates}>Date d'enregistrement : {dateEnregistrementChat}</Text>
            <Text style={styles.dates}>Date d'édition : {dateEdition}</Text>
          </View>
        </View>
      </View>
  );
};

export default Footer;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    gap: 20,
    fontSize: 9,
    alignItems: 'flex-end'
  },
  section: {
    alignItems: 'start',
  },
  bold: {
    fontFamily: 'Lato Bold',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
  dates: {
    fontSize: 8,
  },
});
