import React, { useEffect, useState } from 'react';
import { axiosCore } from '../../utils/http-core-service';
import SearchEleveur from '../SearchEleveur/SearchEleveur';
import SearchClub from '../SearchClub/SearchClub';
import * as AffixeService from '../../services/affixe.service';
import SearchLabo from '../SearchLabo/SearchLabo';
import SearchRace from '../SearchRace/SearchRace';

// Ce composant permet d'insérer ou de modifier n'importe quel type de données.
// Il est destiné à être appelé depuis les pages /*element*/new et /*element*/:id/update
// Il prend en paramètre :
//      - type : le type de données à insérer ou modifier (affixe, agria, club, ...)
//          - doit correspondre au nom de la variable associée, par exemple pour insérer ou modifier un affixe, il faut que la variable soit nommée "affixe"
//          - est utilisé pour appeler la fonction correspondante dans le backend (par exemple, pour insérer un affixe, il appelle la fonction /api/{$type}/:id)
//      - action : insert ou update
//      - insertRoute : la route pour insérer les données
//      - updateRoute : la route pour modifier les données

const InsertOrUpdate = ({ type, action, insertRoute = null, updateRoute = null }) => {
    const affixe = async (updateData = null) => {
        return [
            {
                "key": "numAffixe",
                "label": "Numéro d'affixe",
                "type": "text",
                "required": true,
                "disabled": false,
                "default": insertRoute ? await AffixeService.getNextNumAffixe() : updateData.numAffixe ? updateData.numAffixe : '',
            },
            {
                "key": "article",
                "label": "Article de l'affixe",
                "type": "select",
                "options": [
                    { "value": "NEANT", "label": "NEANT" },
                    { "value": "AU", "label": "AU" },
                    { "value": "AUX", "label": "AUX" },
                    { "value": "DU", "label": "DU" },
                    { "value": "DE", "label": "DE" },
                    { "value": "DES", "label": "DES" },
                    { "value": "DE LA", "label": "DE LA" },
                    { "value": "D\'", "label": "D\'" },
                    { "value": "DE L\'", "label": "DE L\'" },
                    { "value": "LES", "label": "LES" },
                    { "value": "LA", "label": "LA" },
                    { "value": "OF", "label": "OF" },
                    { "value": "FROM", "label": "FROM" },
                    { "value": "OF THE", "label": "OF THE" },
                    { "value": "SOUS", "label": "SOUS" },
                    { "value": "ON", "label": "ON" },
                    { "value": "LE", "label": "LE" },
                    { "value": "SUR", "label": "SUR" },
                    { "value": "OR A", "label": "OR A" },
                    { "value": "DELL", "label": "DELL" },
                    { "value": "THE", "label": "THE" },
                    { "value": "DE MA", "label": "DE MA" },
                ],
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.article) ? updateData.article : 'NEANT',
            },
            {
                "key": "nomAffixe",
                "label": "Nom de l'affixe",
                "type": "text",
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.nomAffixe) ? updateData.nomAffixe : '',
            },
            {
                "key": "dateEnvoi",
                "label": "Date d'envoi",
                "type": "date",
                "required": false,
                "disabled": false,
                "default": (updateData && updateData.dateEnvoiAffixe) ? new Date(updateData.dateEnvoiAffixe.split('/').reverse().join('-')).toISOString().split('T')[0] : '',
            },
            {
                "key": "positionAffixe", // n'est pas un champ dans la base, mais un un paramètre modifiant le nomAffixe, voir le backend
                "label": "Position de l'affixe",
                "type": "radio",
                "options": [
                    { "value": "prefix", "label": "Préfixe" },
                    { "value": "suffix", "label": "Suffixe" },
                ],
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.positionAffixe) ? updateData.positionAffixe : '',
            },
            {
                "key": "type",
                "label": "Type de l'affixe",
                "type": "radio",
                "options": [
                    { "value": "2", "label": "Occasionnel" },
                    { "value": "1", "label": "Définitif" },
                ],
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.type) ? updateData.type === "Occasionnel" ? "2" : "1" : '',
            },
            {
                "key": "agria",
                "label": "Accord Agria",
                "type": "radio",
                "options": [
                    { "value": true, "label": "Oui" },
                    { "value": false, "label": "Non" },
                ],
                "required": true,
                "disabled": (updateData && updateData.agria) ? true : false,
                "default": (updateData && updateData.agria) ? updateData.agria : null,
            },
            {}, // empty object to add a line break
            {
                "key": "eleveur",
                "label": "Eleveur",
                "type": "render",
                "render": (value, row, index) => {
                    return <SearchEleveur
                        onSubmitHandler={(value) => {
                            setForm((prevForm) => ({ ...prevForm, eleveur: value }));
                        }}
                        defaultValue={(updateData && updateData.eleveur) ? { id: updateData.eleveur.id, designation: `${updateData.eleveur.civilite} ${updateData.eleveur.prenom} ${updateData.eleveur.nom}` } : null}
                    />
                },
                "required": true,
                "disabled": false,
            },
            {
                "key": "club",
                "label": "Club",
                "type": "render",
                "render": (value, row, index) => {
                    return <SearchClub
                        onSubmitHandler={(value) => {
                            setForm((prevForm) => ({ ...prevForm, club: value }));
                        }}
                        defaultValue={(updateData && updateData.club) ? updateData.club : null}
                    />
                },
                "required": true,
                "disabled": false,
            },
            {}, // empty object to add a line break
            {}, // empty object to add a line break
            {
                "key": "commentairesAf",
                "label": "Commentaire",
                "type": "textarea",
                "required": false,
                "disabled": false,
                "default": (updateData && updateData.commentairesAf) ? updateData.commentairesAf : '',
            },
        ];
    };
    const maladie = async (updateData = null) => {
        return [
            {
                "key": "nom",
                "label": "Nom",
                "type": "text",
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.nom) ? updateData.nom : '',
            },
            {
                "key": "nomEn",
                "label": "Nom Anglais",
                "type": "text",
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.nomEn) ? updateData.nomEn : '',
            },
            {
                "key": "sigle",
                "label": "Sigle de la maladie",
                "type": "text",
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.sigle) ? updateData.sigle : '',
            },
            {
                "key": "modeTrans",
                "label": "Mode de transmission",
                "type": "text",
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.modeTrans) ? updateData.modeTrans : '',
            },
            {
                "key": "races",
                "label": "Race(s) concernée(s)",
                "type": "render",
                "render": (value, row, index) => {
                    return <SearchRace
                        onSubmitHandler={(value) => {
                            setForm((prevForm) => ({ ...prevForm, races: value }));
                        }}
                        defaultValue={(updateData && updateData.races) ? updateData.races : null}
                        multipleSelection={true}
                        required={true}
                    />
                },
                "required": true,
                "disabled": false,
            },
            {
                "key": "sigleGene",
                "label": "Sigle gène",
                "type": "text",
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.sigleGene) ? updateData.sigleGene : '',
            },
            {
                "key": "sigleMutation",
                "label": "Sigle de la mutation",
                "type": "text",
                "required": true,
                "disabled": false,
                "default": (updateData && updateData.sigleMutation) ? updateData.sigleMutation : '',
            },
            {
                "key": "labo",
                "label": "Laboratoire(s)",
                "type": "render",
                "render": (value, row, index) => {
                    return <SearchLabo
                        onSubmitHandler={(value) => {
                            setForm((prevForm) => ({ ...prevForm, labo: value }));
                        }}
                        defaultValue={(updateData && updateData.labo) ? updateData.labo : null}
                        multipleSelection={true}
                        required={true}
                    />
                },
                "required": true,
                "disabled": false,
            },
        ];
    };

    const [data, setData] = useState(null);
    const [form, setForm] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log('form :', form);
    }, [form]);

    const handleInputChange = (key, value) => {
        setForm((prevForm) => ({ ...prevForm, [key]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        console.log('submit :', form);
        setIsLoading(true);
        try {
            let $response;
            if (action === 'insert') {
                $response = await axiosCore.post(insertRoute, form);
            } else if (action === 'update') {
                let $id = window.location.pathname.split('/').reverse()[1]; // reverse the url and get the second element (ex : /affixes/1/edit => 1)
                $response = await axiosCore.put(updateRoute + $id, form);
            }
            setIsLoading(false);
            $response.data.successRoute && window.location.replace($response.data.successRoute);
        } catch (error) {
            setIsLoading(false);
            setError(error);
            console.log(error);
        }
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                if (action === 'insert') {
                    setData(await eval(type)());
                } else if (action === 'update') {
                    let $id = window.location.pathname.split('/').reverse()[1]; // reverse the url and get the second element (ex : /affixes/1/edit => 1)
                    let $response = await axiosCore.get(`/api/${type}/${$id}`);
                    console.log($response.data);
                    setData(await eval(type)(await $response.data));
                }
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };
        fetch();
    }, [type]);

    useEffect(() => {
        for (const key in data) {
            if (data[key].default) {
                handleInputChange(data[key].key, data[key].default);
            }
        }
    }, [data]);

    useEffect(() => {
        try {
            if (!type || !action || (!insertRoute && action === 'insert') || (!updateRoute && action === 'update')) {
                throw new Error('Missing required props');
            }
        } catch (error) {
            setError(error);
            console.log(error);
        }
    }, [type, action, insertRoute, updateRoute]);

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>{action === 'insert' ? 'Insérer' : 'Modifier'} un {type}</h5>
            </div>
            <p>Les champs marqués d'un <span className="text-danger">*</span> sont obligatoires.</p>
            <form className="row g-3 whiteBox searchBox" onSubmit={handleSubmit}>
                {data && data.map((item, index) => (
                    <div className="col-md-3" key={index}>
                        <label htmlFor={item.key} className="form-label">
                            {item.label} {item.required && <span className="text-danger">*</span>}
                        </label>
                        {item.type === 'select' &&
                            <select
                                className="form-select"
                                id={item.key}
                                onChange={(e) => handleInputChange(item.key, e.target.value)}
                                required={item.required}
                                disabled={item.disabled}
                                defaultValue={item.default ? item.default : ''}
                            >
                                {item.options.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        }
                        {item.type === 'text' &&
                            <input
                                type="text"
                                className="form-control"
                                id={item.key}
                                onChange={(e) => handleInputChange(item.key, e.target.value)}
                                required={item.required}
                                disabled={item.disabled}
                                defaultValue={item.default ? item.default : ''}
                            />
                        }
                        {item.type === 'checkbox' &&
                            item.options.map((option, index) => (
                                <div className="form-check" key={index}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={option.value}
                                        id={item.key + index}
                                        onChange={(e) => handleInputChange(item.key, e.target.checked)}
                                        required={item.required}
                                        disabled={item.disabled}
                                        defaultChecked={item.default ? item.default : false}
                                    />
                                    <label className="form-check-label" htmlFor={item.key + index}>
                                        {option.label}
                                    </label>
                                </div>
                            ))
                        }
                        {item.type === 'radio' &&
                            item.options.map((option, index) => (
                                <div className="form-check" key={index}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={item.key}
                                        id={item.key + index}
                                        value={option.value}
                                        onChange={(e) => handleInputChange(item.key, e.target.value)}
                                        required={item.required}
                                        disabled={item.disabled}
                                        defaultChecked={item.default === option.value ? true : false}
                                    />
                                    <label className="form-check-label" htmlFor={item.key + index}>
                                        {option.label}
                                    </label>
                                </div>
                            ))
                        }
                        {item.type === 'textarea' &&
                            <textarea
                                className="form-control"
                                id={item.key}
                                rows="3"
                                onChange={(e) => handleInputChange(item.key, e.target.value)}
                                required={item.required}
                                disabled={item.disabled}
                                defaultValue={item.default ? item.default : ''}
                            ></textarea>
                        }
                        {item.type === 'render' &&
                            item.render(item.value, item, index)
                        }
                        {item.type === 'date' &&
                            <input
                                type="date"
                                className="form-control"
                                id={item.key}
                                onChange={(e) => handleInputChange(item.key, e.target.value)}
                                required={item.required}
                                disabled={item.disabled}
                                defaultValue={item.default ? item.default : ''}
                            />
                        }
                    </div>
                ))}
                <div className="col-12 d-flex justify-content-space-between">
                    <button type="button" className="btn btn-danger" onClick={() => window.history.back()}>Annuler</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="submit" className="btn btn-primary">Valider</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {isLoading && <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>}
                </div>
            </form>
            {error && <div className="alert alert-danger" role="alert">
                {error.message}<br />{error.response.data.error}
            </div>}
        </div>
    );
};

export default InsertOrUpdate;