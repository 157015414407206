import { NavLink, useParams } from 'react-router-dom';
import RenderDemandes from '../../../../components/RenderDemandes/RenderDemandes';


const FicheEleveurDemandes = () => {
    const { id } = useParams();

    return (
        <div className="Content">
            <div>
                <div className='fil-arenae d-flex align-items-center mb-3 justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <h5 className='mb-0'>
                            <NavLink className='link' to={`/eleveurs`}>Eleveur</NavLink>
                        </h5>
                        <div className='mx-2'> &gt; </div>
                        <h5 className='mb-0'>
                            <NavLink className='link' to={`/eleveurs/${id}`}>Fiche Eleveur</NavLink>
                        </h5>
                        <div className='mx-2'> &gt; </div>
                        <h5 className='mb-0'>Demandes</h5>
                    </div>
                    <div className='d-flex align-items-center'>
                        <NavLink className='btn btn-primary' to={`/eleveurs/${id}/demandes`}>Demandes</NavLink>
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <RenderDemandes
                        params={
                            {
                                paramEleveur: id,
                                paramLoofStatus: 'nonClosed'
                            }
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default FicheEleveurDemandes;