import * as ClubService from '../../services/club.service';
import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Form, FormGroup } from "react-bootstrap";

const SearchClub = ({ onSubmitHandler, formLabel, defaultValue = null }) => {
    const [clubs, setClubs] = useState([]);
    const setSelectionOnChange = (options, callback) => {
        if (options instanceof Array && 0 < options.length) {
            callback(options[0]);
        } else {
            callback(null);
        }
    };
    const [isClubsSearchLoading, setIsClubsSearchLoading] = useState(false);
    const defaultFilterBy = () => true;
    const [club, setClub] = useState(null);
    const [validated, validate] = useState(false);
    const handlers = {
        search: (query, type) => {
            switch (type.toLowerCase()) {
                case 'club':
                case 'clubs':
                    setIsClubsSearchLoading(true);
                    ClubService.queryByName(query)
                        .then(setClubs)
                        .finally(() => setIsClubsSearchLoading(false));
                    break;
            }
        }
    };
    const renderDisplaySelection = (id, content) => {
        return (<div className="row">
            <span className="col-2 text-end fw-bold">({id})</span>
            <span className="col-10">&nbsp;{content}</span>
        </div>);
    };

    useEffect(() => {
        if (defaultValue && !club) {
            setClub(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (club) {
            onSubmitHandler(club);
        }
    }, [club]);

    return (
        <FormGroup className="mb-1">
            <Form.Label>{formLabel}</Form.Label>
            <AsyncTypeahead id="create-or-update-form-clubs-search" name="IdCl" emptyLabel="Aucun résultat."
                placeholder="Rechercher le club" searchText="..." options={clubs}
                onChange={(opts) => setSelectionOnChange(opts, setClub)}
                labelKey="acronyme" isLoading={isClubsSearchLoading} filterBy={defaultFilterBy}
                onSearch={(q) => handlers.search(q, 'clubs')} required={false}
                renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], `${opt['acronyme']} - ${opt['intituleCl']}`)}
                inputProps={{ required: true, className: "SearchClubInput" }} defaultSelected={defaultValue ? [defaultValue] : []}
            />
        </FormGroup>
    );
};

export default SearchClub;