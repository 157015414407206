import * as RaceService from '../../services/race.service';
import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Form, FormGroup } from "react-bootstrap";

const SearchRace = ({ onSubmitHandler, formLabel, defaultValue = null, multipleSelection = false, required = false }) => {
    const [races, setRaces] = useState([]);
    const [isRacesSearchLoading, setIsRacesSearchLoading] = useState(false);
    const [race, setRace] = useState(multipleSelection ? (defaultValue || []) : defaultValue || null);
    const [validated, validate] = useState(false);

    const setSelectionOnChange = (options, callback) => {
        if (options instanceof Array) {
            if (multipleSelection) {
                callback(options);
            } else {
                callback(options.length > 0 ? options[0] : null);
            }
        } else {
            callback(null);
        }
    };

    const defaultFilterBy = () => true;

    const handlers = {
        search: (query, type) => {
            switch (type.toLowerCase()) {
                case 'race':
                case 'races':
                    setIsRacesSearchLoading(true);
                    RaceService.queryByName(query)
                        .then(setRaces)
                        .finally(() => setIsRacesSearchLoading(false));
                    break;
            }
        }
    };

    const renderDisplaySelection = (id, content) => {
        return (
            <div className="row">
                <span className="col-2 text-end fw-bold">({id})</span>
                <span className="col-10">&nbsp;{content}</span>
            </div>
        );
    };

    useEffect(() => {
        if (defaultValue && !race) {
            setRace(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (race) {
            onSubmitHandler(race);
        }
    }, [race]);

    return (
        <FormGroup className="mb-1">
            <Form.Label>{formLabel}</Form.Label>
            <AsyncTypeahead id="create-or-update-form-races-search" name="IdRa" emptyLabel="Aucun résultat."
                placeholder="Rechercher par intitulé" searchText="..." options={races}
                onChange={(opts) => setSelectionOnChange(opts, setRace)}
                labelKey="intituleRa" isLoading={isRacesSearchLoading} filterBy={defaultFilterBy}
                onSearch={(q) => handlers.search(q, 'races')} required={required}
                renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], opt['intituleRa'])}
                inputProps={{
                    className: "searchRaceInput",
                    required: required ? (multipleSelection ? (race.length === 0) : (race === null)) : false // Required if no selection (because the insert or update form can't handle object with null value)
                }}
                defaultSelected={defaultValue ? (multipleSelection ? defaultValue : [defaultValue]) : []}
                multiple={multipleSelection} />
        </FormGroup>
    );
};

export default SearchRace;
