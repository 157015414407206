import React, { useState, useEffect } from 'react';
import { axiosCore } from '../../../utils/http-core-service';

const getUser = async (value) => {
    try {
        let response = await axiosCore.get(`/api/user/${value}`);
        return `${response.data.firstname} ${response.data.lastname}`;
    } catch (error) {
        console.error(error);
        return '-';
    }
};

const RenderLoofUser = ({ value }) => {
    const [userName, setUserName] = useState('-');

    useEffect(() => {
        let isMounted = true;
        if (value) {
            getUser(value).then(name => {
                if (isMounted) {
                    setUserName(name);
                    return userName;
                }
            });
        }
        return () => {
            isMounted = false;
        };
    }, [value]);

    if (!value) {
        return '-';
    }

    return userName;
};

export default RenderLoofUser;