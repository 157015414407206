// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roundButton {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  width: 100px;
  height: 100px;
}

h3 {
  text-align: center;
}

.roundButton:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
}

.roundButton a {
  color: inherit;
  text-decoration: inherit;
}

.roundButton p {
  font-weight: bold;
}

.home-tables {
  width: 100%;
}
.home-tables .home-table-child {
  flex: 1 1;
  margin-right: 50px;
}
.home-tables .home-table-child:last-child {
  margin-right: 0;
}

.chart-accueil {
  padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Accueil/Accueil.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,wCAAA;EACA,oBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,wCAAA;EACA,sBAAA;AACJ;;AAEA;EACI,cAAA;EACA,wBAAA;AACJ;;AACA;EAEI,iBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;AACI;EACI,SAAA;EACA,kBAAA;AACR;AACQ;EACI,eAAA;AACZ;;AAGA;EACI,oBAAA;AAAJ","sourcesContent":[".roundButton {\n    padding: 20px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);\n    transition: all 0.3s;\n    user-select: none;\n    cursor: pointer;\n    width: 100px;\n    height: 100px;\n}\n\nh3 {\n    text-align: center;\n}\n\n.roundButton:hover {\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);\n    transform: scale(1.05);\n}\n\n.roundButton a {\n    color: inherit;\n    text-decoration: inherit;\n}\n.roundButton p {\n\n    font-weight: bold;\n}\n\n.home-tables {\n    width: 100%;\n    \n    .home-table-child {\n        flex: 1;\n        margin-right: 50px;\n\n        &:last-child {\n            margin-right: 0;\n        }\n    }\n}\n.chart-accueil {\n    padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
