import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';
import './Fiche.scss';
import CatInfo from '../../../components/Request/CatInfo';
import DsnInfo from '../../../components/Request/DsnInfo';
import PedigreeInfo from '../../../components/Request/PedigreeInfo';
import {axiosCore} from "../../../utils/http-core-service";

const FicheDemande = () => {
    const { id } = useParams();
    const [demande, setDemande] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosCore.get(`/api/demandes/one/${id}`);
                setDemande(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log("Error fetching data: ", error);
                setIsLoading(false);
                setError(error);
            }
        }

        fetchData();
    }, [id]);

    const getDemandeTitle = (type) => {
        switch (type) {
            case 'cat':
                return 'Enregistrement d\'un chat à mon nom';
            case 'dsn':
                return 'DSN';
            case 'pedigree':
                return 'Demande de pedigree';
            default:
                return 'Demande';
        }
    }

    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && demande &&
                <div>
                    <h3 className='mb-3'>{getDemandeTitle(demande.type).toUpperCase()}</h3>
                    <div className="d-flex flex-column gap-2">
                        <div id='topBox' className="whiteBox d-flex justify-content-between"> {/* Bloc haut */}
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h6><b>Nom :</b> {demande.firstname} {demande.lastname}</h6>
                                <h6><b>Adresse :</b> {demande.numPortee}</h6>
                                <h6><b>Portable :</b> {demande.numPortee}</h6>
                                <h6><b>Email :</b> {demande.email}</h6>
                            </div>
                        </div>
                        {demande.type === 'cat'  &&
                            <CatInfo demande={demande}/>
                        }
                        {demande.type === 'dsn'  &&
                            <DsnInfo demande={demande}/>
                        }
                        {demande.type === 'pedigree'  &&
                            <PedigreeInfo demande={demande}/>
                        }
                    </div>
                </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger le demande ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                    <p><b>Message de l'API:</b> {error.response.data.error}</p>
                </div>
            }
        </div>
    )
}

export default FicheDemande;
