import React, {useState} from 'react';
import {Modal, Button, Form, Alert} from 'react-bootstrap';
import {axiosCore} from "../../utils/http-core-service";
import {Typeahead} from "react-bootstrap-typeahead";

const Insert = ({onClose, title, insertDatas, insertRoute, checkRoute, checkField}) => {
    const [formData, setFormData] = useState({});
    const [serverError, setServerError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        setValidated(form.checkValidity());
        if (form.checkValidity()) {
            try {
                let reponse = await axiosCore.post(`/${insertRoute}`, formData);
                if (reponse.data.error) {
                    setServerError(reponse.data.error);
                    return;
                }
                onClose();
            } catch (error) {
                if (error.response && error.response.data) {
                    setServerError(error.response.data.message || 'Une erreur est survenue.');
                } else {
                    setServerError('Une erreur est survenue.');
                }
            }
        }
    };

    function getField(data, options = null) {
        if (data.dropdown) {
            return (
                <Form.Select
                    name={data.key}
                    value={formData[data.key] || ''}
                    onChange={handleInputChange}
                    required={data.required}
                >
                    <option value="" disabled>Choisir</option>
                    {data.dropdown.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.label}
                        </option>
                    ))}
                </Form.Select>
            )
        } else {
            switch (data.type) {
                case 'date':
                    let max = options && options.max ? options.max : null;
                    return (
                        <Form.Control
                            name={data.key}
                            type="datetime-local"
                            max={max}
                        />
                    )
                case 'checkbox':
                    return (
                        <Form.Check
                            name={data.key}
                            onChange={handleInputChange}
                            value="true"
                        />
                    )
                case 'search':
                    return (
                        <Typeahead
                            id="basic-typeahead-single"
                            labelKey={data.key}
                            options={data.options}
                            emptyLabel={"Aucun résultat"}
                        />
                    )
                default:
                    return (
                        <Form.Control
                            type="text"
                            name={data.key}
                            value={formData[data.key] || ''}
                            onChange={handleInputChange}
                            required={data.required}
                        />
                    )
            }
        }
    }

    const rand = Math.floor(Math.random() * 99999);

    return (<Modal show={true} onHide={onClose} centered scrollable={true}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {serverError && <Alert variant="danger">{serverError}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form id={`insert-cat-form-${rand}`} onSubmit={handleSubmit} validated={validated}>
                {insertDatas.map((data) => (
                    <Form.Group key={data.key} className='mb-3'>
                        <Form.Label>{data.label} {data.required && <span className="text-danger">*</span>}</Form.Label>
                        {getField(data, data.options)}
                    </Form.Group>
                ))}
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="submit" className="mt-3" form={`insert-cat-form-${rand}`}>
                Insérer
            </Button>
        </Modal.Footer>
    </Modal>);
};

export default Insert;
