import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../components/IsLoading/IsLoading';
import Update from '../../components/DataManagement/Update';
import axios from 'axios';

const FicheRoyalCanin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [RC, setRC] = useState(null);
    const [error, setError] = useState(null);
    const [isModifyPopupOpen, setModifyPopupOpen] = useState(false);

    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROYAL_CANIN_API_URL}/purchases/${id}`, {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_ROYAL_CANIN_API_TOKEN}`
                }
            });
            setRC(response.data);
            console.log(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log("Error fetching data: ", error);
            if (error.response && error.response.status === 404) {
                setRC(null);
                setIsLoading(false);
            }
            setIsLoading(false);
            setError(error);
        }
    }

    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && RC &&
                <div>
                    <div className='fil-arenae d-flex align-items-center mb-4'>
                        <h5 className='mb-0'>
                            <NavLink className='link' to={`/royal-canin`}>Royal Canin</NavLink>
                        </h5>
                        <div className='mx-2'> &gt; </div>
                        <h5 className='mb-0'>Fiche Royal Canin</h5>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <div id='topBox' className="whiteBox d-flex flex-row justify-content-between align-items-start">
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h6 className='mb-1'><b>ID : </b> {RC.id ?? '-'}</h6>
                                <h6 className='mb-1'><b>Status : </b> {RC.status ?? '-'}</h6>
                                <h6 className='mb-1'><b>Type : </b> {RC.type ?? '-'}</h6>
                                <h6 className='mb-1'><b>Date : </b> {RC.date ? new Date(RC.date).toLocaleString('fr-FR') : '-'}</h6>
                            </div>
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h6 className='mb-1'><b>Validé le : </b> {RC.validatedAt ? new Date(RC.validatedAt).toLocaleString('fr-FR') : '-'}</h6>
                                <h6 className='mb-1'><b>Validé par : </b> {RC.validatedBy ?? '-'}</h6>
                                <h6 className='mb-1'><b>Annulé le : </b> {RC.canceledAt ? new Date(RC.canceledAt).toLocaleString('fr-FR') : '-'}</h6>
                                <h6 className='mb-1'><b>Annulé par : </b> {RC.canceledBy ?? '-'}</h6>
                            </div>
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h6 className='mb-1'><b>Email envoyé à l'utilisateur le : </b> {RC.emailUserSentAt ? new Date(RC.emailUserSentAt).toLocaleString('fr-FR') : '-'}</h6>
                                <h6 className='mb-1'><b>Email envoyé au vendeur le : </b> {RC.emailWholesalerSentAt ? new Date(RC.emailWholesalerSentAt).toLocaleString('fr-FR') : '-'}</h6>
                            </div>
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h6 className='mb-1'><b>Quantité : </b> {RC.quantity ?? '-'}</h6>
                                <h6 className='mb-1'><b>Prix initial : </b> {`${RC.initialPrice} €` ?? '-'}</h6>
                                <h6 className='mb-1'><b>Prix : </b> {`${RC.price} €` ?? '-'}</h6>
                                <h6 className='mb-1'><b>Prix total : </b> {`${RC.totalPrice} €` ?? '-'}</h6>
                            </div>
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h6 className='mb-1'><b>Vendeur : </b> {RC.wholesalerName ?? '-'}</h6>
                                <h6 className='mb-1'><b>Email du vendeur : </b> {RC.wholesalerEmail ?? '-'}</h6>
                                <h6 className='mb-1'><b>Kit portée : </b> {RC.litterKit ?? '-'}</h6>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-2'>
                                <div className="card mb-4">
                                    <h5 className="card-header text-center text-white">Produit</h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                {/* ---------- Produit ---------- */}

                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Nom :</b></h6>
                                                    <div className='col-7'>{RC.productData.name ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Etiquette du produit :</b></h6>
                                                    <div className='col-7'>{(RC.productData.subLabel > 0 ? `${RC.productData.subLabel}` : '-')}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Référence :</b></h6>
                                                    <div className='col-7'>{RC.productData.reference ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Image :</b></h6>
                                                    <div className='col-7'>
                                                        <img src={RC.productData.image} alt="product" style={{ width: '100px' }} />
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Poids :</b></h6>
                                                    <div className='col-7'>{RC.productData.weight ?? '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-2'>
                                <div className="card mb-4">
                                    <h5 className="card-header text-center text-white">Partenaire</h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                {/* ---------- Partenaire ---------- */}

                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>ID extérieur :</b></h6>
                                                    <div className='col-7'>{RC.partner.extId ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Nom :</b></h6>
                                                    <div className='col-7'>{RC.partner.name ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Téléphone :</b></h6>
                                                    <div className='col-7'>{RC.partner.phone.length > 0 ? RC.partner.phone : '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Référence client vendeur :</b></h6>
                                                    <div className='col-7'>{RC.partner.wholesalerClientReference ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Contact principal :</b></h6>
                                                    <div className='col-7'>{RC.partner.primaryContact.mobilePhone ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>GUID :</b></h6>
                                                    <div className='col-7'>{RC.user.guid ?? '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-2'>
                                <div className="card mb-4">
                                    <h5 className="card-header text-center text-white">Utilisateur</h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                {/* ---------- Utilisateur ---------- */}

                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Email :</b></h6>
                                                    <div className='col-7'>{RC.user.email ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Titre :</b></h6>
                                                    <div className='col-7'>{RC.user.title ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Prénom :</b></h6>
                                                    <div className='col-7'>{RC.user.firstname ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Nom :</b></h6>
                                                    <div className='col-7'>{RC.user.lastname ?? '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card mb-4">
                                    <h5 className="card-header text-center text-white">Adresse de livraison</h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                {/* ---------- Adresse de livraison ---------- */}

                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Nom :</b></h6>
                                                    <div className='col-7'>{RC.shippingAddress.name ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Livraison immédiate :</b></h6>
                                                    <div className='col-7'>{(RC.shippingAddress.isImmediateDelivery === true ? 'Oui' : 'Non') ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Nom vendeur :</b></h6>
                                                    <div className='col-7'>{RC.shippingAddress.wholesalerName ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Rue :</b></h6>
                                                    <div className='col-7'>{RC.shippingAddress.street ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Rue (secondaire) :</b></h6>
                                                    <div className='col-7'>{RC.shippingAddress.street2 ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>numéro de rue :</b></h6>
                                                    <div className='col-7'>{RC.shippingAddress.number ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Code postal :</b></h6>
                                                    <div className='col-7'>{RC.shippingAddress.zip ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Ville :</b></h6>
                                                    <div className='col-7'>{RC.shippingAddress.city ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Pays :</b></h6>
                                                    <div className='col-7'>{RC.shippingAddress.country ?? '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card mb-4">
                                    <h5 className="card-header text-center text-white">Vendeur</h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                {/* ---------- Vendeur ---------- */}

                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>ID :</b></h6>
                                                    <div className='col-7'>{RC.wholesaler.id ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Référence :</b></h6>
                                                    <div className='col-7'>{RC.wholesaler.reference ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Livraison immédiate :</b></h6>
                                                    <div className='col-7'>{(RC.wholesaler.isImmediateDelivery === true ? 'Oui' : 'Non') ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Référence client requise :</b></h6>
                                                    <div className='col-7'>{(RC.wholesaler.isClientReferenceRequired === true ? 'Oui' : 'Non') ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Description référence client :</b></h6>
                                                    <div className='col-7'>{RC.wholesaler.clientReferenceDescription ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Supprimé le :</b></h6>
                                                    <div className='col-7'>{RC.wholesaler.deletedAt ? new Date(RC.wholesaler.deletedAt).toLocaleString('fr-FR') : '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Email :</b></h6>
                                                    <div className='col-7'>{RC.wholesaler.email ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Copie email :</b></h6>
                                                    <div className='col-7'>{RC.wholesaler.emailsCopy ?? '-'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Label :</b></h6>
                                                    <div className='col-7'>{RC.wholesaler.label.fr ?? '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger l'éleveur ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                </div>
            }
        </div>
    );
}

export default FicheRoyalCanin;
