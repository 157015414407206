// src/components/Login.js

import React, { useState } from 'react';
import { HttpStatusCode } from 'axios';
import './Login.css';
import {ReactComponent as Logo} from '../../assets/images/logoLOOF.svg';
import {axiosCore} from "../../utils/http-core-service";

const Login = () => {
    const [user, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosCore.post(`${process.env.REACT_APP_API_ROUTE_LOGIN}`, {
                user: user,
                password: password,
            });
            localStorage.setItem('token', response.data.token);
            window.location.reload();
        } catch (error) {
            const response = error.response;
            if (!response) {
                setError("Une erreur est survenue, le serveur ne répond pas.");
                return;
            }
            if (response.status === HttpStatusCode.NotFound) {
                setError("Login ou mot de passe incorrect.");
                return;
            } else if (response.status === HttpStatusCode.Forbidden) {
                setError("Votre compte a été désactivé. Contactez un administrateur pour plus d'informations.");
                return;
            }
            setError("Une erreur inconnue est survenue.");
            console.log(error);
        }
    };

    return (
        <div id="body">
            <div className="white-box">
                <Logo className="logo" />
                <form onSubmit={handleLogin} className="login-form">
                    <p id='page_de_connexion' style={{marginBottom: error ? '0' : '20px'}}>Page de connexion</p>
                    <div className="error-box" style={{display: error ? 'flex' : 'none'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                        <p>{error}</p>
                    </div>
                    <div className="login-form-input-box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                        <input
                            type="user"
                            placeholder="Nom d'utilisateur"
                            value={user}
                            onChange={(e) => setUsername(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className="login-form-input-box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
                        <input
                            type="password"
                            placeholder="Mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required={true}
                        />
                    </div>
                    <button type='submit'>Se connecter</button>
                    <a href="/reset-password"><p>Mot de passe oublié ?</p></a>
                </form>
            </div>
        </div>
    );
};

export default Login;
