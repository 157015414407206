import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';

import './Fiche.css';
import { axiosCore } from "../../../utils/http-core-service";
import { getFullAffixe } from '../../../utils/utils';
import OpenLink from '../../../components/OpenLink/OpenLink';

import { FaExclamation } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { FaPlane } from "react-icons/fa";
import Patte from '../../../assets/images/Patte';

const FicheEleveur = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [eleveur, setEleveur] = useState(null);
    const [error, setError] = useState(null);
    const [displayedDSN, setDisplayedDSN] = useState([]);
    const [yearOptions, setYearOptions] = useState([])

    const displayInformation = (data, fallbackMessage = "Aucune information") => {
        return data ? <span>{data}</span> : <span className="text-muted fst-italic">{fallbackMessage}</span>;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const eleveurResponse = await axiosCore.get(`/api/eleveur/${id}`);
                const paysResponse = await axiosCore.get(`/api/pays/${eleveurResponse.data.idPays}`);
                const dsnResponse = await axiosCore.get(`/api/saillie/user/${id}`)
                const createByResponse = await axiosCore.get(`/api/user/${eleveurResponse.data.createBy}`)
                let updateByResponse;
                if (eleveurResponse.updateDate !== null) {
                    updateByResponse = await axiosCore.get(`/api/user/${eleveurResponse.data.updateBy}`)
                }

                console.log("Eleveur: ", dsnResponse.data);
                setEleveur({ ...eleveurResponse.data, pays: paysResponse.data, dsn: dsnResponse.data, createBy: createByResponse.data, updateBy: updateByResponse ? updateByResponse.data : null });
                setIsLoading(false);
            } catch (error) {
                console.log("Error fetching data: ", error);
                setIsLoading(false);
                setError(error);
            }
        }
        fetchData();
    }, [id]);

    // au changement des données de l'éleveur,
    // récupère toutes les années durant lesquelles l'utilisateur a enregistré un DSN
    useEffect(() => {
        const yearArray = [];
        eleveur?.dsn.map((dsn) => {
            const annee = new Date(dsn.dateNaissance).getFullYear();
            if (!yearArray.includes(annee)) {
                yearArray.push(annee);
            }
        })
        yearArray.sort();
        setYearOptions(yearArray);
        if (yearArray.length > 0) {
            setDisplayedDSN(eleveur.dsn.filter((dsn) => new Date(dsn.dateNaissance).getFullYear() === yearArray[yearArray.length - 1]));
        }
    }, [eleveur]);

    // When user select a new value for DSN, sets correct DSN
    const handleYearSelectChange = (e) => {
        const selectedDate = e.target.value;
        setDisplayedDSN(eleveur.dsn.filter((dsn) => new Date(dsn.dateNaissance).getFullYear() === parseInt(selectedDate)));
    }

    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && eleveur &&
                <div>
                    <div className='fil-arenae d-flex align-items-center mb-3 justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <h5 className='mb-0'>
                                <NavLink className='link' to={`/eleveurs`}>Eleveur</NavLink>
                            </h5>
                            <div className='mx-2'> &gt; </div>
                            <h5 className='mb-0'>Fiche Eleveur</h5>
                        </div>
                        <div className='d-flex align-items-center'>
                            <NavLink className='btn btn-outline-primary' to={`/eleveurs/${id}/demandes`}>Demandes</NavLink>
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <div id='topBox' className="whiteBox">
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h5><b>Nom, Prénom de l'éleveur
                                    : </b>{displayInformation(eleveur.civilite, '')} {displayInformation(eleveur.nom, '')} {displayInformation(eleveur.prenom, '')}
                                </h5>
                                <h6 className='mb-1'><b>Créé le : </b> {displayInformation(eleveur.createDate)} (par : {displayInformation(`${eleveur.createBy.firstname} ${eleveur.createBy.lastname}`, 'Inconnu')})</h6>
                                {eleveur.updateDate &&
                                    (<h6 className='mb-0'><b>Modifié le : </b> {eleveur.updateDate} (par : {displayInformation(`${eleveur.updateBy?.firstname} ${eleveur.updateBy?.lastname}`, 'Inconnu')})</h6>)
                                }
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox'> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Identifiant :</b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.id)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Identifiant éleveur externe :</b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.idElExt)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Adresse : </b></h6>
                                                    <div className='col-8'>{displayInformation(eleveur.adresse)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Code Postal / Ville / Pays : </b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.codePostal)} {displayInformation(eleveur.ville)} {displayInformation(eleveur.pays.intitulePa)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Téléphone :</b></h6>
                                                    <div className='col-8'>
                                                        <div className='vstack'>
                                                            <span>{displayInformation(eleveur.telephone, '')}</span>
                                                            <span data-toggle="tooltip" data-placement="top"
                                                                title="Mobile">{displayInformation(eleveur.mobile, '')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Mail : </b></h6>
                                                    <div className='col-8'>{displayInformation(eleveur.mail)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Site web : </b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.siteWeb, 'Aucun site web')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Société : </b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.societe, 'Pas de société')}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>SIRET : </b></h6>
                                                    <div className='col-8'>{displayInformation(eleveur.siret)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Certif. capa : </b></h6>
                                                    <div className='col-8'>{displayInformation(eleveur.dsv)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Uniquement propriétaire : </b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.proprio ? 'Oui' : 'Non')}</div>
                                                </div>                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Publication autorisée : </b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.publi ? 'Oui' : 'Non')}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Compte Site : </b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.cptSite ? 'Oui' : 'Non')}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Juge : </b></h6>
                                                    <div
                                                        className='col-8'>{displayInformation(eleveur.juge ? 'Oui' : 'Non')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line d-flex justify-content-between'>
                                                <div className='form-line-item d-flex align-items-start'>
                                                    <h6 className='mb-0'><b>Affixe :</b></h6>
                                                    <div className='mx-1'>
                                                        {eleveur.affixes.length > 0 ? eleveur.affixes.map((affixe, index) => (
                                                            <div className='d-flex align-items-center' key={index}>
                                                                <OpenLink
                                                                    content={displayInformation(getFullAffixe(affixe))}
                                                                    url={'/affixes/' + affixe.id}
                                                                    key={index}
                                                                />
                                                                <span className="text-muted fst-italic">&nbsp;({affixe.type})</span>
                                                            </div>
                                                        )) : <span className="text-muted fst-italic">Aucun affixe</span>}
                                                    </div>
                                                </div>
                                                <div className='form-line-item d-flex align-items-start'>
                                                    <h6 className='mb-0'><b>Race :</b></h6>
                                                    <div
                                                        className='mx-1'>{displayInformation(eleveur.races.map(r => r.intituleRa).join(' - '))}</div>
                                                </div>
                                                <div className='form-line-item d-flex align-items-start'>
                                                    <h6 className='mb-0'><b>Club :</b></h6>
                                                    <div
                                                        className='mx-1'>{displayInformation(eleveur.clubs.map(r => r.intituleCl).join(' - '), 'Aucun club')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line d-flex justify-content-start'>
                                                <div className='d-flex align-items-start'>
                                                    <h6 className='mb-0'><b>Liste des DSN :</b></h6>
                                                </div>
                                                <div className='d-flex align-items-start'>
                                                    <div className='mx-4'>
                                                        {yearOptions.length > 0 ? (
                                                            <select defaultValue={yearOptions[yearOptions.length - 1]} className='mx-1 form-select' onChange={(e) => {
                                                                handleYearSelectChange(e)
                                                            }}>
                                                                {yearOptions.map((year) => (
                                                                    <option key={year} value={year}>{year}</option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <span className='text-muted fst-italic'>Aucune DSN enregistré</span>
                                                        )}
                                                    </div>
                                                    <div className='vstack'>
                                                        {displayedDSN.map((dsn) => (
                                                            <div key={dsn.id} className='d-flex align-items-center'>
                                                                <OpenLink
                                                                    content={`${dsn.numDossier}`}
                                                                    url={'/dossiers/' + dsn.id}
                                                                />
                                                                <span className="text-muted fst-italic">
                                                                    &nbsp;|&nbsp;
                                                                    {dsn.numPortee}
                                                                    &nbsp;|&nbsp;
                                                                    ({new Date(dsn.dateNaissance).toLocaleDateString()})
                                                                    {dsn.commentairesNa !== "" && <>&nbsp;&nbsp;<FaExclamation height='1em' width='1em' /></>}
                                                                    {(new Date(dsn.dateDemandePedig).getFullYear() > 1970) && <>&nbsp;&nbsp;<FaEnvelope height='1em' width='1em' /></>}
                                                                    {dsn.nbPedigrees > 0 && <>&nbsp;&nbsp;<Patte height='1em' width='1em' /></>}
                                                                    {(new Date(dsn.dateEnvoiPed).getFullYear() > 1970) && <>&nbsp;&nbsp;<FaPlane height='1em' width='1em' /></>}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='row'>
                                                <h6 className='col-3'><b>Commentaire éleveur : </b></h6>
                                                <div className='col-9'>
                                                    <div className='commentBoxFiche' style={{ height: 'auto' }}>
                                                        {displayInformation(eleveur.commentaires, 'Aucun commentaire')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger l'éleveur ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                </div>
            }
        </div>
    );
}

export default FicheEleveur;