import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
import { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { deleteData } from '../../components/DataManagement/Delete.js';
import Insert from '../../components/DataManagement/Insert.js';
import {axiosCore} from "../../utils/http-core-service";

const Titre = () => {
    const header = [
        {
            "key": "intituleTi",
            "label": "INTITULÉ",
        },
        {
            "key": "abrev",
            "label": "ABRÉVIATION",
        },
        {
            "key": "nbAffectations",
            "label": "Affectations",
        },
        {
            "key": "acro",
            "label": "Acronyme",
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    <button type="button" className="btn btn-outline-primary rounded-5" onClick={() => {
                        moveTitre('api/titres/move', 'up', row.id, { getAll });
                    }}>&nbsp;↑&nbsp;</button>
                    <button type="button" className="btn btn-outline-primary rounded-5" onClick={() => {
                        moveTitre('api/titres/move', 'down', row.id, { getAll });
                    }}>&nbsp;↓&nbsp;</button>
                    <button type="button" className="btn btn-outline-danger rounded-5" onClick={() => {
                        deleteData('api/titres/delete', row.id, row.intituleTi, { getAll });
                    }}>Supprimer</button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [federations, setFederations] = useState([]);
    const [federationSelection, setFederationSelection] = useState(['LOOF']);
    const [isInsertPopupOpen, setInsertPopupOpen] = useState(false);

    const getAll = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            let url = `/api/titres/list`;
            if (search) {
                url += `?federation=${search}`
            }

            response = await axiosCore.get(url);
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
        } catch (error) {
            console.log(error);
        }
    };

    const openInsertPopup = () => {
        setInsertPopupOpen(true);
    };

    const closeInsertPopup = () => {
        setInsertPopupOpen(false);
        searchTitres(federationSelection);
    };

    const searchTitres = async (value) => {
        let federation = '';
        setFederationSelection(value)

        if (value.length) {
            federation = value[0];
        }

        getAll(federation)
    }
    const getAllFederation = async () => {
        try {
            const response = await axiosCore.get(`/api/titres/get/federations`);
            setFederations(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const moveTitre = async (route, action, id, callback) => {
        try {
            const response = await axiosCore.post(`/${route}/${id}`, {action});
            callback.getAll(federationSelection);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAll('LOOF');
        getAllFederation();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Titre</h5>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-4">
                    <label htmlFor="federation" className="form-label">Fédération</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="federation"
                        options={federations.map((value) => value.federation)}
                        onChange={searchTitres}
                        selected={federationSelection}
                    />
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des titres</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary" onClick={openInsertPopup}>Insérer</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'titres trouvés' : 'titre trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
                {isInsertPopupOpen && (
                    <Insert
                        onClose={closeInsertPopup}
                        title="Insérer un titre"
                        insertDatas={[
                            {"key": "intitule", "label": "Titre", "required": true},
                            {"key": "abrev", "label": "Avréviation", "required": true},
                            {"key": "acro", "label": "Acronyme", "required": true},
                            {"key": "federation", "label": "Fédération", "dropdown": federations.map((item) => { return {id: item.federation, label: item.federation} }), "required": true}
                        ]}
                        insertRoute={`api/titres/insert`}
                    />
                )}
            </div>
        </div>
    );
}

export default Titre;
