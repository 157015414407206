import React from 'react';
import TableTemplate from '../../../components/TableTemplate/TableTemplate.js';
import './Utilisateurs.css';
import { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { deleteData } from '../../../components/DataManagement/Delete.js';
import Insert from '../../../components/DataManagement/Insert.js';
import Update from '../../../components/DataManagement/Update.js';
import {axiosCore} from "../../../utils/http-core-service";

const Utilisateurs = () => {
    const [rolesDropdown, setRolesDropdown] = useState([]);
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await axiosCore.get(`/api/roles/list`);

                const roles = response.data.map((role) => ({
                    key: role.id,
                    label: role.label,
                }));

                setRolesDropdown(roles);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        fetchRoles();
    }, []);

    console.log(  rolesDropdown)
    useEffect(() => {
        console.log(rolesDropdown);
    }, [rolesDropdown]);


    const header = [
        {
            "key": "uid",
            "label": "Uid",
        },
        {
            "key": "lastname",
            "label": "Nom",
        },
        {
            "key": "firstname",
            "label": "Prénom",
        },
        {
            "key": "initials",
            "label": "Initiale",
        },
        {
            "key": "username",
            "label": "Identifiant",
        },
        {
            "key": "password",
            "label": "Mot de passe",
        },
        {
            "key": "email",
            "label": "Email",
        },
        {
            "key": "roles",
            "label": "Rôle",
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    <button type="button" className="btn btn-outline-primary rounded-5" onClick={() => {
                        openModifyPopup(row.uid, row.lastname, row.firstname, row.initials, row.username, row.email, rolesDropdown.find((role) => role.label === row.roles).key);
                    }}>Modifier</button>
                    <button type="button" className="btn btn-outline-danger rounded-5" onClick={() => {
                        deleteData('api/user/delete', row.uid, row.username, { getData });
                    }}>Supprimer</button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isInsertPopupOpen, setInsertPopupOpen] = useState(false);
    const [isModifyPopupOpen, setModifyPopupOpen] = useState(false);
    const [modifyUserDatas, setModifyUserDatas] = useState({});
    const [rolesDropdownSelection, setRolesDropdownSelection] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});

    const getData = async (search = null) => {
        setIsLoading(true)
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/user/list`);
            } else {
                response = await axiosCore.get(`/api/user/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'roles' && search[key].length > 0) { // If the key is 'roles' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    const openInsertPopup = () => {
        setInsertPopupOpen(true);
    };

    const closeInsertPopup = () => {
        setInsertPopupOpen(false);
        getData();
    };


    const openModifyPopup = (uid, lastname, firstname, initials, username, email, roles) => {
        setModifyUserDatas({ uid, lastname, firstname, initials, username, email, roles });
        console.log(modifyUserDatas.roles)
        setModifyPopupOpen(true);
    };

    const closeModifyPopup = () => {
        setModifyPopupOpen(false);
        getData();
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="Content">
            <h2>Administration : Utilisateurs</h2>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-1">
                    <label htmlFor="uid" className="form-label">Entrer un UID</label>
                    <input type="text" className="form-control" id="uid" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="lastname" className="form-label">Entrer un nom</label>
                    <input type="text" className="form-control" id="lastname" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="firstname" className="form-label">Entrer un prénom</label>
                    <input type="text" className="form-control" id="firstname" />
                </div>
                <div className="col-md-2">
                    <label htmlFor="initials" className="form-label">Entrer des initiales</label>
                    <input type="text" className="form-control" id="initials" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="roles" className="form-label">Sélectionner un rôle</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="roles"
                        placeholder={'Rechercher un rôle'}
                        options={rolesDropdown.map((value) => value.label)}
                        onChange={setRolesDropdownSelection}
                        selected={rolesDropdownSelection}
                    />
                </div>
                <div className="col-md-3">
                    <label htmlFor="username" className="form-label">Entrer un identifiant</label>
                    <input type="text" className="form-control" id="username" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="email" className="form-label">Entrer un mail</label>
                    <input type="text" className="form-control" id="email" />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                    {Object.keys(activeFilters).length > 0 && (
                        <small className="text-muted">
                            <b>Filtre actif :&nbsp;</b>
                            {Object.entries(activeFilters).map(([key, value], index) => (
                                <span key={index}>
                                    {key === 'uid' && value && `UID : ${value}`}
                                    {key === 'lastname' && value && `Nom : ${value}`}
                                    {key === 'firstname' && value && `Prénom : ${value}`}
                                    {key === 'initials' && value && `Initiales : ${value}`}
                                    {key === 'username' && value && `Identifiant : ${value}`}
                                    {key === 'email' && value && `Email : ${value}`}
                                    {key === 'roles' && value.length > 0 && `Rôle : ${rolesDropdown.filter((value1) => value.includes(value1.label)).map((value1) => value1.label).join(', ')}`}
                                    {value && index < Object.keys(activeFilters).length - 1 && ',  '}
                                </span>
                            ))}
                        </small>
                    )}
                    {Object.keys(activeFilters).length === 0 && (
                        <small className="text-muted"><b>Filtre actif :</b> Aucun</small>
                    )}
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton' onClick={() => {
                        getData();
                        setActiveFilters({});
                        setRolesDropdownSelection([]); // Reset the roles selection
                        document.getElementById('uid').value = '';
                        document.getElementById('lastname').value = '';
                        document.getElementById('firstname').value = '';
                        document.getElementById('initials').value = '';
                        document.getElementById('username').value = '';
                        document.getElementById('email').value = '';
                        document.getElementById('resetButton').classList.add('d-none');
                    }}>Réinitialiser</button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'roles': rolesDropdownSelection.map((value) => rolesDropdown.find((value1) => value1.label === value).key), // Get the key of the selected roles
                            'uid': document.getElementById('uid').value.trim(),
                            'lastname': document.getElementById('lastname').value.trim(),
                            'firstname': document.getElementById('firstname').value.trim(),
                            'initials': document.getElementById('initials').value.trim(),
                            'username': document.getElementById('username').value.trim(),
                            'email': document.getElementById('email').value.trim(),
                        };
                        console.log(search);
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher</button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des utilisateurs</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary" onClick={() => {
                            openInsertPopup();
                        }}>Insérer</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'utilisateurs trouvés' : 'utilisateur trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                <TableTemplate
                        theadData={header}
                        tbodyData={data}
                        isLoading={isLoading}
                    />
                </div>
                {isInsertPopupOpen && (
                    <Insert
                        onClose={closeInsertPopup}
                        title="Insérer un utilisateur"
                        insertDatas={[
                            { key: 'lastname', label: 'Nom', required: true },
                            { key: 'firstname', label: 'Prénom', required: true },
                            { key: 'initials', label: 'Initiales', required: true },
                            { key: 'username', label: 'Identifiant', required: true },
                            { key: 'password', label: 'Mot de passe', required: true },
                            { key: 'email', label: 'Email', required: false },
                            { key: 'roles', label: 'Rôle', required: true, dropdown: rolesDropdown },
                        ]}
                        insertRoute={`api/user/insert`}
                    />
                )}
                {isModifyPopupOpen && (
                    <Update
                        onClose={closeModifyPopup}
                        title={"Modifier l'utilisateur " + modifyUserDatas.lastname + " " + modifyUserDatas.firstname}
                        modifyDatas={[
                            { key: 'lastname', label: 'Nom', value: modifyUserDatas.lastname, required: true },
                            { key: 'firstname', label: 'Prénom', value: modifyUserDatas.firstname, required: true },
                            { key: 'initials', label: 'Initiales', value: modifyUserDatas.initials, required: true },
                            { key: 'username', label: 'Identifiant', value: modifyUserDatas.username, required: true },
                            { key: 'password', label: 'Mot de passe', value: '', required: false },
                            { key: 'email', label: 'Email', value: modifyUserDatas.email, required: false },
                            { key: 'roles', label: 'Rôle', value: modifyUserDatas.roles, required: true, dropdown: rolesDropdown },
                        ]}
                        modifyRoute={`api/user/update/${modifyUserDatas.uid}`}
                    />
                )}
            </div>
        </div>
    );
}

export default Utilisateurs;
