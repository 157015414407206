import React, { useState, useEffect } from 'react';
import { axiosCore } from '../../utils/http-core-service';
import OpenLink from '../OpenLink/OpenLink';

const getEleveur = async (value) => {
    try {
        let response = await axiosCore.get(`/api/eleveur/${value}`);
        return `${response.data.nom} ${response.data.prenom}`;
    } catch (error) {
        console.error(error);
        return '-';
    }
};

const RenderEleveur = ({ value }) => {
    const [eleveurName, setEleveurName] = useState('-');

    useEffect(() => {
        let isMounted = true;
        if (value) {
            getEleveur(value).then(name => {
                if (isMounted) {
                    setEleveurName(name);
                }
            });
        }
        return () => {
            isMounted = false;
        };
    }, [value]);

    if (!value) {
        return '-';
    }

    return <OpenLink url={`/eleveurs/${value}`} content={eleveurName} divClassName='d-flex align-items-center justify-content-center' />;
};

export default RenderEleveur;