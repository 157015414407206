import React from 'react';
import TableTemplate from "../TableTemplate/TableTemplate";
import {Modal} from "react-bootstrap";

const Popup = ({ name, theadData, tbodyData, isLoading = false, limit = null, show, onClose }) => {
    const css = ".modal { --bs-modal-width: fit-content; }";

    return (
        <>
            <style>{css}</style>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Historique des {name}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto',
                    width: 'fit-content',
                    marginRight:'auto',
                    marginLeft:'auto'
                }} >
                    <TableTemplate
                        theadData={theadData}
                        tbodyData={tbodyData}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Popup;
