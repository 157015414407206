import React from 'react';
import { isEmpty } from 'lodash';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import DNACertificate from './assets/dna.jpg';

const IdGenAndTest = ({ pedigreeInfos }) => {
    const genetiqueChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.genetiqueChaton) || '';
    const testGenetique = (!isEmpty(pedigreeInfos) && pedigreeInfos.tests) || '';

    return (
        <View style={styles.container}>
            {genetiqueChat && genetiqueChat === 'DNA comp.' ? (
                <Image src={DNACertificate} style={styles.image} />
            ) : null}
            {genetiqueChat ? (
                <View style={styles.section}>
                    <View style={styles.row}>
                        <View style={{ ...styles.cell, ...styles.title }}>
                            <Text>ID. Gen</Text>
                        </View>
                        <View style={styles.cell}>
                            <Text>{genetiqueChat}</Text>
                        </View>
                    </View>
                </View>
            ) : null}
            {testGenetique.length > 0 ? (
                <View style={styles.section}>
                    <View>
                        <View style={styles.tableHeader}>
                            <Text>Tests</Text>
                        </View>
                        {testGenetique.map((test, index) => (
                            <View style={styles.row} key={index}>
                                <View style={{ ...styles.cell, ...styles.title }}>
                                    <Text>{test.sigle}</Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text>{test.resultat}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
            ) : null}
        </View>
    );
};

export default IdGenAndTest;

const styles = StyleSheet.create({
    container: {
    },
    section: {
        flexDirection: 'row',
        borderTop: 1,
    },
    row: {
        borderLeft: 1,
        flexDirection: 'row',
    },
    tableHeader: {
        fontSize: 10,
        borderRight: 1,
        borderLeft: 1,
        borderBottom: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Lato Bold',
    },
    cell: {
        borderRight: 1,
        borderBottom: 1,
        fontSize: 10,
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: 'Lato Bold',
    },
    image: {
        position: 'absolute',
        width: 40,
        height: 40,
        top: 0,
        left: -35,
    }
});