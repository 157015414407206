// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-dialog {
  max-width: 80vw !important;
}

.rbt-token {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  color: #007bff;
  background-color: #e7f4ff;
}

.rbt-close-content{
  display: none;
}

.rbt-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; gap: 5px;
}

.rbt-token-label {
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,uBAAkB;EAAlB,kBAAkB;EAClB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB,EAAE,QAAQ;AAC/B;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.modal-dialog {\n  max-width: 80vw !important;\n}\n\n.rbt-token {\n  display: flex;\n  width: fit-content;\n  color: #007bff;\n  background-color: #e7f4ff;\n}\n\n.rbt-close-content{\n  display: none;\n}\n\n.rbt-input-wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row; gap: 5px;\n}\n\n.rbt-token-label {\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
