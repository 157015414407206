import {axiosCore as http} from "../utils/http-core-service";

const queryByName = async (query) => {
    const params = new FormData();
    params.set('query', query);
    return http.get(`/api/user/query/get?${new URLSearchParams(params).toString()}`)
        .then(response => response.data)
        .catch(console.error);
}

export {queryByName}
