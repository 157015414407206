import React, {useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, InputGroup } from 'react-bootstrap';
import {axiosCore} from "../../utils/http-core-service";
import {AsyncTypeahead, Typeahead} from "react-bootstrap-typeahead";

const Update = ({ onClose, title, modifyDatas, modifyRoute }) => {
    const [formData, setFormData] = useState({});
    const [serverError, setServerError] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFormData(Object.fromEntries(
            modifyDatas.map(({ key, value }) => [key, value])
        ));
    }, [modifyDatas]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response = await axiosCore.put(`/${modifyRoute}`, formData);
            if (response.data.error) {
                setServerError(response.data.error);
                return;
            }
            onClose();
        } catch (error) {
            if (error.response && error.response.data) {
                setServerError(error.response.data.message || 'Une erreur est survenue.');
            } else {
                setServerError('Une erreur est survenue.');
            }
        }
    };

    const handleSearch = async (query, table) => {
        setIsLoading(true);
        try {

            const search = {
                'idEleveur': "",
                'nomEleveur': query.toUpperCase(),
                'prenomEleveur': "",
                'mailEleveur': "",
                'departementEleveur': "",
                'villeEleveur': "",
                'raceEleveeEleveur': [],
                'telEleveur': "",
            };



            const response = await axiosCore.get(`/api/${table}/search/${JSON.stringify(search)}`);
            const eleveurs = response.data.map((eleveur) => ({
                key: eleveur.idEleveur,
                label: `${eleveur.nomEleveur} ${eleveur.prenomEleveur}`,
            }));
            setOptions(eleveurs);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterBy = () => true;

    return (
        <Modal show={true} onHide={onClose} centered style={{width:'100vw'}}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {serverError && <Alert variant="danger">{serverError}</Alert>}
                <Form onSubmit={handleSubmit} style={{display:'flex', flexDirection:'row',flexWrap:'wrap', justifyContent:'space-between'}}>
                    {modifyDatas.map((data) => (
                        <Form.Group key={data.key} className="mb-3" style={{display:"flex", flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', width:'48%'}}>
                            <Form.Label>{data.label} {data.required && <span className="text-danger">*</span>}</Form.Label>
                            {data.dropdown && !data.multiple && data.table &&
                                <AsyncTypeahead
                                    style={{width:'100%'}}
                                    filterBy={filterBy}
                                    id="async-example"
                                    labelKey="label"
                                    minLength={3}
                                    disabled={data.disabled || false}

                                    isLoading={isLoading}
                                    onSearch={(query) => handleSearch(query, data.table)}
                                    options={options}
                                    placeholder="Rechercher un éleveur"
                                    onChange={(selected) => {
                                        handleChange({
                                            target: {
                                                name: data.key,
                                                value: selected,
                                            },
                                        });
                                    }}
                                />
                            }
                            {data.dropdown && !data.table && !data.multiple &&
                                <Form.Select
                                    name={data.key}
                                    value={formData[data.key] || ''}
                                    onChange={handleChange}
                                    disabled={data.disabled || false}
                                    required={data.required}
                                >
                                    <option value="" disabled>Choisir</option>
                                    {data.dropdown.map((item) => (
                                        <option value={item.key}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Form.Select>}
                            {data.multiple && (
                                <Typeahead
                                    id="public-methods-example"
                                    disabled={data.disabled || false}
                                    style={{width:'100%'}}
                                    options={data.dropdown} // Assurez-vous que data.dropdown est un tableau d'objets
                                    // defaultSelected={[formData[data.key]] || []} // Valeur par défaut comme tableau pour mode multiple+
                                    onChange={(selected) => {
                                        handleChange({
                                            target: {
                                                name: data.key,
                                                value: selected, // Met à jour avec les options sélectionnées
                                            },
                                        });
                                    }}
                                />
                            )}
                            {data.checked &&
                                <Form.Check
                                    name={data.key}
                                    type="switch"
                                    disabled={data.disabled || false}
                                    value={formData[data.key] || ''}
                                    onChange={handleChange}
                                    checked={data.value}
                                    required={data.required}
                                />
                            }
                            {data.textarea &&
                                <Form.Control
                                    style={{height:'100px'}}
                                    as="textarea"
                                    name={data.key}
                                    disabled={data.disabled || false}
                                    value={formData[data.key] || ''}
                                    onChange={handleChange}
                                    required={data.required}
                                />
                            }
                            {!data.dropdown && !data.checked && !data.textarea && !data.devise && !data.devise && !data.multiple && !data.date &&
                                <Form.Control
                                    type={data.type || 'text'}
                                    name={data.key}
                                    disabled={data.disabled || false}
                                    value={formData[data.key] || ''}
                                    onChange={handleChange}
                                    required={data.required}
                                />
                            }
                            {data.devise &&
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type={data.type || 'text'}
                                        name={data.key}
                                        disabled={data.disabled || false}
                                        value={formData[data.key] || ''}
                                        onChange={handleChange}
                                        required={data.required}
                                    />
                                    <InputGroup.Text>{data.devise}</InputGroup.Text>
                                </InputGroup>
                            }
                            {data.date &&
                                <Form.Control
                                    type="date"
                                    name={data.key}
                                    disabled={data.disabled || false}
                                    value={formData[data.key] || ''}
                                    onChange={handleChange}
                                    required={data.required}
                                />
                            }

                        </Form.Group>
                    ))}
                    <Button variant="primary" type="submit" className="mt-3" centered style={{marginLeft:'auto', marginRight:'auto'}}>
                        Enregistrer
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Update;
