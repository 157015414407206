// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
    --bs-breadcrumb-divider: '>';
}

.breadcrumb .breadcrumb-item a {
    color: rgb(241, 60, 82);
    text-decoration: none;
}

.toast {
    position: fixed;
    z-index: 1090;
    right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Cat/CreateOrUpdateCatView.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,WAAW;AACf","sourcesContent":[".breadcrumb {\n    --bs-breadcrumb-divider: '>';\n}\n\n.breadcrumb .breadcrumb-item a {\n    color: rgb(241, 60, 82);\n    text-decoration: none;\n}\n\n.toast {\n    position: fixed;\n    z-index: 1090;\n    right: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
