import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import IdGenandTest from './assets/IdGenAndTest.png';

const IdGenAndTest = ({ pedigreeInfos, pedColor }) => {
    const genetiqueChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.genetiqueChaton) || '';
    const testGenetique = (!isEmpty(pedigreeInfos) && pedigreeInfos.tests) || '';

    const styles = StyleSheet.create({
        container: {
            height: '100%',
            width: '100%',
            border: 1,
            borderLeft: 0,
            borderColor: pedColor,
            fontSize: 8,
            padding: 5,
            paddingLeft: 20,
            paddingBottom: 0,
        },
        section: {
            flexDirection: 'row',
        },
        row: {
            borderLeft: 1,
            flexDirection: 'row',
        },
        tableHeader: {
            fontSize: 10,
            borderRight: 1,
            borderLeft: 1,
            borderBottom: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Lato Bold',
        },
        cell: {
            borderRight: 1,
            borderBottom: 1,
            fontSize: 10,
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontFamily: 'Lato Bold',
            color: pedColor,
            marginBottom: 5,
        },
        titleList: {
            fontFamily: 'Lato Bold',
            color: pedColor,
        },
        value: {
            flexDirection: 'column',
        },
        imageSection: {
            position: 'absolute',
            left: -4,
            top: 0,
            height: '100%',
        },
        image: {
            height: '100%',
            width: 'auto',
        },
    });

    return (
        <View style={styles.container}>
            <View style={styles.imageSection}>
                <Image src={IdGenandTest} style={styles.image} />
            </View>
            {genetiqueChat && (
            <View style={styles.section}>
                <Text style={styles.title}>Id. Gen : </Text>
                <View style={styles.value}>
                        <Text>{genetiqueChat}</Text>
                </View>
            </View>
            )}
            { testGenetique.length > 0 && (
                <View style={styles.section}>
                    <Text style={styles.titleList}>Génétique : </Text>
                    <View style={styles.value}>
                        {testGenetique.length > 0 && (
                            testGenetique.map((test, index) => (
                                <Text key={index}>{test.sigle} : {test.resultat}</Text>
                            ))
                        )}
                    </View>
                </View>
            )}
        </View>
    );
};

export default IdGenAndTest;
