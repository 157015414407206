// src/App.js

import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import Accueil from './pages/Accueil/Accueil';
import Dossiers from './pages/Dossiers/Dossiers';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { isExpired, decodeToken } from "react-jwt";
import Statistiques from './pages/Statistiques/Statistiques';
import Utilisateurs from './pages/Administration/Utilisateurs/Utilisateurs';
import Fiche from './pages/Dossiers/Fiche/Fiche';
import Demandes from './pages/Demandes/Demandes';
import FicheDemande from './pages/Demandes/Fiche/FicheDemande';
import Eleveurs from './pages/Eleveurs/Eleveurs';
import FicheEleveur from './pages/Eleveurs/Fiche/FicheEleveur';
import Affixes from './pages/Affixes/Affixes';
import FicheAffixe from './pages/Affixes/Fiche/FicheAffixe';
import Certificats from './pages/Certificats/Certificats';
import Chats from './pages/Chats/Chats';
import LG from './pages/Référentiels/LG';
import Pays from './pages/Référentiels/Pays';
import Yeux from './pages/Référentiels/Yeux';
import Titre from './pages/Référentiels/Titre';
import AgriaTrans from './pages/Agria/AgriaTrans';
import Agria from './pages/Agria/Agria';
import FicheAgria from './pages/Agria/Fiche/FicheAgria';
import Maladie from './pages/Référentiels/Maladie/Maladie';
import FicheMaladie from './pages/Référentiels/Maladie/FicheMaladie';
import Race from './pages/Race/Race';
import Robe from './pages/Robe/Robe';
import Labo from './pages/Labo/Labo';
import FicheLabo from './pages/Labo/FicheLabo';
import Expo from './pages/Expositions/Expositions/Expo';
import FicheExpo from './pages/Expositions/Expositions/FicheExpo';
import ExpoClubs from './pages/Expositions/Clubs/Clubs';
import FicheExpoClubs from './pages/Expositions/Clubs/FicheExpoClubs';
import FicheClubs from './pages/Adherents/Clubs/FicheClubs';
import Clubs from './pages/Adherents/Clubs/Clubs';
import CreateOrUpdateCatView from "./pages/Cat/CreateOrUpdateCatView";
import FicheChat from "./pages/Chats/Fiche/FicheChat";
import RoyalCanin from './pages/RoyalCanin/RoyalCanin';
import FicheRoyalCanin from './pages/RoyalCanin/FicheRoyalCanin';
import FicheEleveurDemandes from './pages/Eleveurs/Fiche/Demandes/FicheEleveurDemandes';
import InsertOrUpdate from './components/InsertOrUpdate/InsertOrUpdate';
import CouleursChatons from './components/CouleursChatons/CouleursChatons';
import Portees from './pages/Chats/Fiche/Sub/Portees';

const App = () => {
    // si il y a un token dans le localStorage, on considère que l'utilisateur est connecté
    const token = localStorage.getItem('token');
    let isLoggedIn = false;
    if (token) {
        const myDecodedToken = decodeToken(token);
        const isMyTokenExpired = isExpired(token);
        isLoggedIn = myDecodedToken && !isMyTokenExpired;
    }

    return (
        <div className="App">
            {isLoggedIn && <Header />}
            <Routes>
                {!isLoggedIn && <Route path="*" element={<Login />} />}
                {isLoggedIn && <Route path="/login" element={<Navigate to="/accueil" />} />}
                {isLoggedIn && <Route path="/accueil" element={<Accueil />} />}
                {isLoggedIn && <Route path="/dossiers" element={<Dossiers />} />}
                {isLoggedIn && <Route path="/dossiers/:id" element={<Fiche />} />}
                {isLoggedIn && <Route path="/dossiers/services/couleurs-chatons" element={<CouleursChatons />} />}
                {isLoggedIn && <Route path="/demandes" element={<Demandes />} />}
                {isLoggedIn && <Route path="/demandes/dsn" element={<Demandes paramType={'dsn'}/>} />}
                {isLoggedIn && <Route path="/demandes/pedigree" element={<Demandes paramType={'pedigree'}/>} />}
                {isLoggedIn && <Route path="/demandes/:id" element={<FicheDemande />} />}
                {isLoggedIn && <Route path="/eleveurs" element={<Eleveurs />} />}
                {isLoggedIn && <Route path="/eleveurs/:id" element={<FicheEleveur />} />}
                {isLoggedIn && <Route path="/eleveurs/:id/demandes" element={<FicheEleveurDemandes />} />}
                {isLoggedIn && <Route path="/affixes" element={<Affixes />} />}
                {isLoggedIn && <Route path="/affixes/new" element={<InsertOrUpdate type={'affixe'} action={'insert'} insertRoute={'/api/affixe/insert/'} />} />}
                {isLoggedIn && <Route path="/affixes/:id/edit" element={<InsertOrUpdate type={'affixe'} action={'update'} updateRoute={'/api/affixe/update/'} />} />}
                {isLoggedIn && <Route path="/affixes/:id" element={<FicheAffixe />} />}
                {isLoggedIn && <Route path="/certificats" element={<Certificats />} />}
                {isLoggedIn && <Route path="/certificats" element={<Certificats />} />}
                {isLoggedIn && <Route path="/chats" element={<Chats />} />}
                {isLoggedIn && <Route path="/chats/new" element={<CreateOrUpdateCatView />} />}
                {isLoggedIn && <Route path="/chats/:id" element={<FicheChat />} />}
                {isLoggedIn && <Route path="/chats/:id/portees" element={<Portees />} />}
                {isLoggedIn && <Route path="/administration/utilisateurs" element={<Utilisateurs />} />}
                {isLoggedIn && <Route path="/referentiels/livres-genealogiques" element={<LG />} />}
                {isLoggedIn && <Route path="/referentiels/pays" element={<Pays />} />}
                {isLoggedIn && <Route path="/referentiels/yeux" element={<Yeux />} />}
                {isLoggedIn && <Route path="/referentiels/titres" element={<Titre />} />}
                {isLoggedIn && <Route path="/referentiels/maladies" element={<Maladie />} />}
                {isLoggedIn && <Route path="/referentiels/maladies/:id" element={<FicheMaladie />} />}
                {isLoggedIn && <Route path="/referentiels/maladies/:id/edit" element={<InsertOrUpdate type={'maladie'} action={'update'} updateRoute={'/api/maladie/update/'} />} />}
                {isLoggedIn && <Route path="expositions/expositions" element={<Expo />} />}
                {isLoggedIn && <Route path="expositions/expositions/:id" element={<FicheExpo />} />}
                {isLoggedIn && <Route path="expositions/clubs" element={<ExpoClubs />} />}
                {isLoggedIn && <Route path="expositions/clubs/:id" element={<FicheExpoClubs />} />}
                {isLoggedIn && <Route path="adherents/clubs/" element={<Clubs />} />}
                {isLoggedIn && <Route path="adherents/clubs/:id" element={<FicheClubs />} />}
                {isLoggedIn && <Route path="/races" element={<Race />} />}
                {isLoggedIn && <Route path="/robes" element={<Robe />} />}
                {isLoggedIn && <Route path="/laboratoires/list" element={<Labo />} />}
                {isLoggedIn && <Route path="/laboratoires/detail/:id" element={<FicheLabo />} />}
                {isLoggedIn && <Route path="/statistiques" element={<Statistiques />} />}
                {isLoggedIn && <Route path="/agria" element={<AgriaTrans />} />}
                {isLoggedIn && <Route path="/agria/:id" element={<Agria />} />}
                {isLoggedIn && <Route path="/agria/fiche/:id" element={<FicheAgria />} />}
                {isLoggedIn && <Route path="/royal-canin" element={<RoyalCanin />} />}
                {isLoggedIn && <Route path="/royal-canin/detail/:id" element={<FicheRoyalCanin />} />}
                {isLoggedIn && <Route path="/" element={<Accueil />} />}
                {isLoggedIn && <Route path="*" element={<NotFound />} />}

                <Route path="/login" element={<Login />} />
            </Routes>
        </div>
    );
}

export default App;
