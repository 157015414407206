import DOMPurify from 'dompurify';
import { axiosCore } from "../utils/http-core-service";
import { Modal, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import IsLoading from '../components/IsLoading/IsLoading';

export function mappingMonth(monthInt) {
    switch (monthInt) {
        case '01':
            return 'Janvier';
        case '02':
            return 'Février';
        case '03':
            return 'Mars';
        case '04':
            return 'Avril';
        case '05':
            return 'Mai';
        case '06':
            return 'Juin';
        case '07':
            return 'Juillet';
        case '08':
            return 'Août';
        case '09':
            return 'Septembre';
        case '10':
            return 'Octobre';
        case '11':
            return 'Novembre';
        case '12':
            return 'Décembre';
        default:
            return null;
    }
}

export function getFullAffixe(affixeObject) {
    let affixe = '';
    if (affixeObject.nomAffixe !== '***' && affixeObject.nomAffixe.slice(-1) === '*') {
        affixe = affixeObject.nomAffixe.slice(0, -2);
    } else {
        if (affixeObject.article !== 'NEANT') {
            affixe += ' ' + affixeObject.article;
        }
        if (affixeObject.nomAffixe !== 'NEANT') {
            if (affixe.slice(-1) !== "'") {
                affixe += ' ';
            }
            affixe += affixeObject.nomAffixe;
        }
    }
    return affixe;
}

export function putComment(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(((str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')).trim()) }} />
    )
}
export const showICAD = async ({ infos = null, numIdentification = null, setIcadResult = null }) => {
    const ModalWrapper = () => {
        const [showModal, setShowModal] = useState(true);
        const [modalData, setModalData] = useState(infos || null);
        const [isLoading, setIsLoading] = useState(!infos);
        const [error, setError] = useState(null);

        const fetchData = async (reset = false) => {
            if ((numIdentification && !infos) || reset) {
                try {
                    const realNumIdentification = numIdentification || infos.IDENTIFICATION; // in case of reset
                    const response = await axiosCore.get(`/api/icad/search/${realNumIdentification}`);

                    if (response.status !== 200) {
                        throw new Error(`Failed with status code ${response.status}`);
                    }

                    const data = response.data;
                    setModalData(data);
                    setIcadResult?.(data);
                } catch (err) {
                    setError([
                        {
                            key: 'Erreur',
                            value: `Une erreur est survenue lors de la recherche de l'identification ICAD`
                        },
                        {
                            key: 'Code erreur',
                            value: err.response.status || 'Code inconnu'
                        },
                        {
                            key: 'Message',
                            value: err.response.data.error || err.response.data.message || err.message || 'Erreur inconnue'
                        }
                    ]);
                    console.error('Erreur lors de la recherche de l\'identification ICAD : ', err);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        useEffect(() => {
            fetchData();
        }, [numIdentification, infos, setIcadResult]);

        const closeModal = () => {
            setShowModal(false);
            setTimeout(() => root.unmount(), 300);
        };

        const resetModal = () => {
            setIsLoading(true);
            setError(null);
            fetchData(true);
        }

        return (
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Informations ICAD</Modal.Title>
                    &emsp;
                    {((!isLoading && modalData) || error) && (
                        <Button variant="primary btn-sm" onClick={() => resetModal()}>Rafraîchir</Button>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {isLoading && <IsLoading />}
                    {!isLoading && modalData && (
                        <div>
                            {Object.entries(modalData).map(([key, value], index) => (
                                <p key={index}><strong>{key}:</strong> {value}</p>
                            ))}
                        </div>
                    )}
                    {!isLoading && error && (
                        <div>
                            {error.map((err, index) => (
                                <p key={index}><strong>{err.key}:</strong> {err.value}</p>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const container = document.createElement('div');
    document.body.appendChild(container);
    const root = createRoot(container);
    root.render(<ModalWrapper />);
};
