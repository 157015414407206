import React from 'react';
import './statistiques.scss';
import StatistiqueDSNMois from '../../components/Statistiques/StatistiqueDSNMois';
import StatistiquePedMois from '../../components/Statistiques/StatistiquePedMois';
import StatistiqueDSNPerUserPerMois from '../../components/Statistiques/StatistiqueDSNPerUserPerMois';

const Statistiques = () => {
    return (
        <div className="Content">
            <h2>Statistiques</h2>

            <div className='stats whiteBox'>
                <StatistiqueDSNMois />
            </div>

            <div className='stats whiteBox'>
                <StatistiqueDSNPerUserPerMois />
            </div>

            <div className='stats whiteBox'>
                <StatistiquePedMois />
            </div>
        </div>
    );
}

export default Statistiques;
