import axios from 'axios';
import {jwtDecode} from "jwt-decode";


//Création d'axios avec une url de base
export const axiosCore = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

//Intercepteur sur les requests.
//Il permet de rajouter le bearer quand il est présent dans le localStorage
axiosCore?.interceptors.request.use(config => {
    //Intercepteur sur les requests.
    //Il permet de rajouter le bearer quand il est présent dans le localStorage
    if (process.env.REACT_APP_API_ROUTE_LOGIN !== config.url) {
        const token = localStorage.getItem('token');
        if (!token || token && tokenIsExpired(token) ) {
            const controller = new AbortController();
            window.location.reload();
            controller.abort("Lors d'une connexion « en tant que » vous êtes en « lecture seule »");
            return {
                ...config,
                signal: controller.signal,
            };
        }
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
    }
    return config;
});

function tokenIsExpired(token) {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convertit le temps actuel en secondes
        return decodedToken.exp < currentTime;
    } catch (error) {
        return true;
    }
}