import React, {useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import IsLoading from "../../../components/IsLoading/IsLoading";
import Popup from "../../../components/popup/popup";
import {axiosCore} from "../../../utils/http-core-service";
import Update from "../../../components/DataManagement/Update";

const FicheClubs = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [club, setClub] = useState([]);
    const [infos, setInfos] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [dataHistorique, setDataHistorique] = useState([]);
    const [idAdh, setidAdh] = useState('');
    const [idAct, setidAct] = useState('');
    const [idNB, setIdNB] = useState('');
    const [statutId, setStatutId] = useState('');
    const [cptResId, setCptResId] = useState('');
    const [headerTable, setHeaderTable] = useState();
    const [popupName, setPopupName] = useState('')
    const [isModifyPopupOpen, setModifyPopupOpen] = useState(false);
    const [paysDropdown, setPaysDropdown] = useState([]);
    const [collegeDropdown, setCollegeDropdown] = useState([]);
    const [racesDropdown, setRacesDropdown] = useState([]);
    const [eleveursDropdown, setEleveursDropdown] = useState([]);
    const [modifyUserDatas, setModifyUserDatas] = useState({});
    const [pays, setPays] = useState('')
    const [college, setCollege] = useState('')
    const [races, setRaces] = useState([]);

    const [error, setError] = useState(null);

    useEffect(() => {
        getPays();
        getCollege();
        getRaces();
        getEleveurs();

    }, []);

    const getPays = async () => {
        try {
            const response = await axiosCore.get(`/api/pays/list`);
            const pays = response.data.map((pays) => ({
                key: pays.idPays,
                label: pays.intitulePays,
            }));
            setPaysDropdown(pays);
        } catch (error) {
            console.log(error);
        }
    };

    const getRaces = async () => {
        try {
            const response = await axiosCore.get(`/api/race/list`);
            const races = response.data.map((races) => ({
                key: races.id,
                label: races.intituleRa ,
            }));
            setRacesDropdown(races);
        } catch (error) {
            console.log(error);
        }
    };

    const getEleveurs = async () => {
        try {
            const response = await axiosCore.get(`/api/eleveur/list`);
            const eleveurs = response.data.map((eleveurs) => ({
                key: eleveurs.id,
                label: eleveurs.nom + ' ' + eleveurs.prenom,
            }));
            setEleveursDropdown(eleveurs);
        } catch (error) {
            console.log(error);
        }
    };

    const getCollege = async () => {
        try {
            const response = await axiosCore.get(`/api/college/list`);
            const college = response.data.map((college) => ({
                key: college.idCollege,
                label: college.libelle,
            }));
            setCollegeDropdown(college);
        } catch (error) {
            console.log(error);
        }
    };




    const Adh_Cotisation = [
        {
            "key": "AnneeCot",
            "label": "Année"
        },
        {
            "key": "RefPaiement",
            "label": "Référence paiement"
        },
        {
            "key": "RefPaiement",
            "label": "Référence paiement"
        },
        {
            "key": "Date_ReceptCot",
            "label": "Date de réception"
        },
        {
            "key": "Date_ValAdh",
            "label": "Mise en banque"
        },
        {
            "key": "Montant",
            "label": "Montant"
        },
        {
            "key": "Emetteur",
            "label": "Emetteur"
        },
        {
            "key": "NumRemise",
            "label": "Numéro de remise"
        },
        {
            "key": "NumRecu",
            "label": "Numéro de recu"
        },
    ]

    const Club_Dirigeant = [
        {
            "key": "eleveurs",
            "label": "Nom Prénom"
        },
        {
            "key": "DateNomination",
            "label": "Date de nomination"
        },
        {
            "key": "DateModif",
            "label": "Date de modification"
        },
    ]

    const Statut = [
        {
            "key": "Date_depot",
            "label": "Date de dépôt en préfecture"
        },
        {
            "key": "Recept_Loof",
            "label": "Date de réception des statuts"
        },
    ]

    const PVAG = [
        {
            "key": "Date_AG",
            "label": "Date de l'assemblée générale"
        },
        {
            "key": "Date_Recept_AG",
            "label": "Date de réception du PV"
        },
    ]

    const CptsRes = [
        {
            "key": "Date_FinEx",
            "label": "Date de fin d'exercice"
        },
        {
            "key": "Date_Recept_EX",
            "label": "Date de réception"
        },
    ]

    const Doc = [
        {
            "key": "nature",
            "label": "Nature du document"
        },
        {
            "key": "dateReceptDoc",
            "label": "Date de réception"
        },
    ]

    const Respresentant = [
        {
            "key": "Annee",
            "label": "Année"
        },
        {
            "key": "Nb_Adh",
            "label": "Nombre d'adhérents"
        },
        {
            "key": "Nb_Repr",
            "label": "Nombre de représentants"
        },
        {
            "key": "eleveurs",
            "label": "Représentants"
        },
    ]

    const Activite = [
        {
            "key": "Millesime",
            "label": "Millesime"
        },
        {
            "key": "Actualise",
            "label": "Site actualisé"
        },
        {
            "key": "Bulletin",
            "label": "Bulletin d'information"
        },
        {
            "key": "Bulletin",
            "label": "Gestion des problèmes de santé liés à la race"
        },
    ]

    const getClub = async () => {
        try {
            const response = await axiosCore.get(`${process.env.REACT_APP_BACKEND_URL}/api/adherents/club/${id}`);
            setClub(response.data.club);
            setidAdh(response.data.club.idAdh)
            setIdNB(response.data.club.nbAdhId)
            setidAct(response.data.club.activiteId)
            setStatutId(response.data.club.statutId)
            setCptResId(response.data.club.cptResId)
            setPays(response.data.infos.pays)
            setCollege(response.data.infos.college)
            setRaces(response.data.infos.race)

            setInfos(response.data.infos);
            setIsLoading(false);
        } catch (error) {
            if (!error.response) {
                console.log("Error fetching data: ", error.message);
                setIsLoading(false);
                setError(error.message);
                return;
            }
            console.log("Error fetching data: ", error.response.data.error);
            setIsLoading(false);
            setError(error.response.data.error);
        }
    }

    const openModifyPopup = (paysSelected, collegeSelected, raceSelected) => {
        setModifyUserDatas({ paysSelected, collegeSelected, raceSelected });
        setModifyPopupOpen(true);
    };

    const closeModifyPopup = () => {
        setModifyPopupOpen(false);
        getClub();
    };

    useEffect(() => {
        if (isNaN(id)) {
            console.log("id is null");
            setError("Ce club n'existe pas");
            setIsLoading(false);
            return;
        }
        getClub();
    }, []);

    const handlePopupOpen = () => {
        setShowPopup(true);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    const getHistorique = async (idChamp, name, idRequire, tHead) => {
        setHeaderTable(tHead)
        setPopupName(name)
        const request = { 'id': idRequire, 'name': name, 'idChamp' : idChamp };
        try {
            const response = await axiosCore.get(`/api/adherents/club/historique/${JSON.stringify(request)}`);
            setDataHistorique(response.data.reverse());
            handlePopupOpen();
        } catch (error) {
            console.error(`Erreur lors de la récupération de l'historique des ${name}:`, error);
        }
    };

    function formatDate(dateString) {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('fr-FR');
        }

    }

    return (
        <div className="Content" id="Top">
            {isLoading && <IsLoading />}
            {!isLoading && club && !error &&

                <div>
                    <div className='fil-arenae d-flex align-items-center mb-3'>
                        <h5 className='mb-0'>
                            <NavLink className='link' to={`/adherents/clubs`}>Club</NavLink>
                        </h5>
                        <div className='mx-2'> &gt; </div>
                        <h5 className='mb-0'>Fiche club</h5>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <div id='topBox' className="whiteBox" style={{position: "unset"}}>
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <h5><b>Nom du club : </b> {club.intituleCl} </h5>
                                <h6 className='mb-1'><b>ID : </b> {club.id}</h6>
                                <h6 className='mb-1'><b>Création en date du : </b> {club.dateCreation}</h6>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Libellé : </b></h6>
                                                    <div className='col-8'>{club.acronyme}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Date d'adhésion : </b></h6>
                                                    <div
                                                        className='col-8'>{formatDate(infos.adherent.dateAdhesion)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Actif : </b></h6>
                                                    <div className='col-8'>
                                                        {infos.adherent.actif === false ? 'non' : 'oui'}
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Commentaires : </b></h6>
                                                    <div className='col-8'>{infos.adherent.commentaireAdherent}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Numéro adhérent : </b></h6>
                                                    <div className='col-8'>{infos.adherent.numAdherent}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>College : </b></h6>
                                                    <div className='col-8'>{infos.college.collegeLibelle}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-4'><b>Cotisation : </b></h6>
                                                    <div className='col-8'>
                                                        <button type="button" style={{height: 'fit-content'}}
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                            getHistorique('IdAdh', 'Adh_Cotisation', idAdh, Adh_Cotisation)
                                                            setPopupName('cotisations');
                                                        }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Millésime : </b></h6>
                                                    <div className='col-8'>{infos.activites.actiMillesime}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Référence du paiement : </b></h6>
                                                    <div className='col-8'>{infos.cotisations.RefPaiement}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Date de réception de la cotisation : </b>
                                                    </h6>
                                                    <div
                                                        className='col-8'> {formatDate(infos.cotisations.DateReceptCot)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Émetteur du chèque : </b></h6>
                                                    <div className='col-8'>{infos.cotisations.Emetteur}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Reçus : </b></h6>
                                                    <div
                                                        className='col-8'>{formatDate(infos.cotisations.DateValAdh)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Montant : </b></h6>
                                                    <div className='col-8'> {infos.cotisations.Montant}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Numéro de reçu : </b></h6>
                                                    <div className='col-8'> {infos.cotisations.NumRecu}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Mise en banque : </b></h6>
                                                    <div
                                                        className='col-8'>{formatDate(infos.cotisations.DateValAdh)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Numéro de remise : </b></h6>
                                                    <div className='col-8'>{infos.cotisations.NumRemise.toLocaleString()}</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Adresse : </b></h6>
                                                    <div className='col-8'>{club.aoAdresse}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Code postal : </b></h6>
                                                    <div className='col-8'>{club.aoCp}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Pays : </b></h6>
                                                    <div className='col-8'>{infos.pays.intitulePa}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Téléphone : </b></h6>
                                                    <div className='col-8'>{club.aoTel}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Fax : </b></h6>
                                                    <div className='col-8'>{club.aoFax}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Ville : </b></h6>
                                                    <div className='col-8'>{club.aoVille}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Mobile : </b></h6>
                                                    <div className='col-8'>{club.aoMobile}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Email : </b></h6>
                                                    <div className='col-8'><a href={'mailto:' + club.aoEmail}>{club.aoEmail}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Acronyme du Club : </b></h6>
                                                    <div className='col-8'>{club.acronyme}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Intitulé du Club : </b></h6>
                                                    <div className='col-8'>{club.intituleCl}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Date de création : </b></h6>
                                                    <div className='col-8'>
                                                        {club.dateCreation}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Article : </b></h6>
                                                    <div className='col-8'>{club.article}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>URL du du site : </b></h6>
                                                    <div className='col-8'>{club.urlSite && (
                                                        <a href={club.urlSite} target={'_blank'}>{club.urlSite}</a>)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row mb-4'>
                            <div className='col-lg-4'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-6'><b>Président du club : </b></h6>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    getHistorique('IdCl', 'Club_President', id, Club_Dirigeant)
                                                                    setPopupName('présidents');
                                                                }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Nom et prénom : </b></h6>
                                                    <div
                                                        className='col-6'>{infos.president.nomPresident} {infos.president.prenomPresident}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de nomination : </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.president.dateNominationPresi)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de mise à jour: </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.president.dateModificationPresi)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-6'><b>Trésorier du club : </b></h6>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    getHistorique('IdCl', 'Club_Tresorier', id, Club_Dirigeant)
                                                                    setPopupName('trésoriers');
                                                                }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Nom et prénom : </b></h6>
                                                    <div
                                                        className='col-6'>{infos.tresorier.nomTresorier} {infos.tresorier.prenomTresorier}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de nomination : </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.tresorier.dateNominationTreso)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de mise à jour: </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.tresorier.dateModificationTreso)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-6'><b>Secrétaire du club : </b></h6>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    getHistorique('IdCl', 'Club_Secretaire', id, Club_Dirigeant)
                                                                    setPopupName('secrétaires');
                                                                }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Nom et prénom : </b></h6>
                                                    <div
                                                        className='col-6'>{infos.secretaire.nomSecretaire} {infos.secretaire.prenomSecretaire}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de nomination : </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.secretaire.dateNominationSecret)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de mise à jour: </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.secretaire.dateModificationSecret)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row mb-4'>
                            <div className='col-lg-4'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-6'><b>Statut : </b></h6>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    getHistorique('IdCl', 'Club_Statuts', id, Statut)
                                                                    setPopupName('statuts');
                                                                }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de dépôt en préfecture : </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.statut.dateDepotStatut)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de réception des statuts : </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.statut.dateReceptionStatut)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-6'><b>PV d'assemblée générale : </b></h6>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    getHistorique('IdCl', 'Club_PVAG', id, PVAG)
                                                                    setPopupName('assemblées générales');
                                                                }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date l'assemblée générale : </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.pvag.dateAssembleGenerale)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de réception du PV : </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.pvag.dateReceptionPV)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-6'><b>Compte de résultat : </b></h6>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    getHistorique('IdCl', 'Club_CptRes', id, CptsRes)
                                                                    setPopupName('comptes de résultats');
                                                                }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de fin d'exercice : </b></h6>
                                                    <div className='col-6'>{formatDate(infos.compteRes.dateFinEx)}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Date de réception : </b></h6>
                                                    <div
                                                        className='col-6'>{formatDate(infos.compteRes.dateReceptEx)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-4'><b>Autres documents envoyés : </b></h6>
                                                    <div className='col-8'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    getHistorique('IdDoc', 'Club_Docs', id, Doc)
                                                                    setPopupName('documents');
                                                                }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Nature du document : </b></h6>
                                                    <div className='col-8'>{infos.document.natureDoc}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Date de réception : </b></h6>
                                                    <div
                                                        className='col-8'>{formatDate(infos.document.dateRecepDoc)}</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Race(s) : </b></h6>
                                                    <div className='col-8'>{infos.race.intituleRace}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-4'><b>Adhérents / Représentants : </b></h6>
                                                    <div className='col-8'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    getHistorique('IdNB', 'Club_NbAdh', id, Respresentant)
                                                                    setPopupName('adhérents / représentants');
                                                                }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Année LOOF : </b></h6>
                                                    <div className='col-8'>{infos.nbAdherent.nbAdhAnnee}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Nombre d'adhérents  : </b></h6>
                                                    <div className='col-8'>{infos.nbAdherent.nbAdherent}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Nombre de représentants : </b></h6>
                                                    <div className='col-8'>{infos.nbAdherent.nbRepresentant}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Liste des représentants : </b></h6>
                                                    <div
                                                        className='col-8'> {infos.nbAdherent?.representants?.map(representant => (
                                                        <div key={representant.id}>
                                                            {representant.prenom} {representant.nom}
                                                        </div>
                                                    ))}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item' style={{paddingBottom: '5px'}}>
                                                    <h6 className='col-6'><b>Contrôle d'activité : </b></h6>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                            getHistorique('IdCl', 'Club_Activite', id, Activite)
                                                            setPopupName('contrôles d\'activité');
                                                        }}>
                                                            Voir l'historique
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Millesime : </b></h6>
                                                    <div className='col-6'>{infos.activites.actiMillesime}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Bulletin d'information : </b></h6>
                                                    <div
                                                        className='col-6'>{infos.activites.actiBulletin === false ? 'non' : 'oui'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Gestion des problèmes de santé lié à la
                                                        race : </b></h6>
                                                    <div
                                                        className='col-6'>{infos.activites.actiPbSante === false ? 'non' : 'oui'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Site actualisé :</b></h6>
                                                    <div
                                                        className='col-6'>{infos.activites.actiSiteActu === false ? 'non' : 'oui'}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-6'><b>Spéciales d'élevage :</b></h6>
                                                    <div
                                                        className='col-6'>{infos.activites.actiSpeElevage === false ? 'non' : 'oui'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className='d-flex gap-3 justify-content-center mb-3'>
                            <button className='btn btn-primary btn-sm' onClick={() => {
                                openModifyPopup(pays, college, races);
                            }}>Modifier
                            </button>
                        </div>
                    </div>

                </div>

            }
            {isModifyPopupOpen && (
                <Update
                    onClose={closeModifyPopup}
                    title={"Modifier le club " + club.acronyme}
                    modifyDatas={[
                        { key: "acronyme",  label: "Libellé :", required: true,  value: club.acronyme,},
                        { key: "dateAdhesion",  label: "Date d'adhésion :", required: true,  value: infos.adherent.dateAdhesion, disabled:true, date:true},
                        { key: "actif",  label: "Actif :", required: false,  value: infos.adherent.actif, checked:true, disabled:true},
                        { key: "commentaireAdherent",  label: "Commentaire :", required: false,  value: infos.adherent.commentaireAdherent, textarea: true, disabled:true},
                        { key: "numAdherent",  label: "Numéro adhérent :", required: true,  value: infos.adherent.numAdherent, disabled:true},
                        { key: 'idCollege', label: 'College :', value: modifyUserDatas.collegeSelected.idCollege, required: true, dropdown: collegeDropdown, disabled:true },
                        { key: "actiMillesime",  label: "Millésime :", required: true,  value: infos.activites.actiMillesime, disabled:true},
                        { key: "RefPaiement",  label: "Référence du paiement :", required: true,  value: infos.cotisations.RefPaiement, disabled:true},
                        { key: "DateReceptCot",  label: "Date de réception de la cotisation :", required: true,  value: infos.cotisations.DateReceptCot,date:true, disabled:true},
                        { key: "Emetteur",  label: "Émetteur du chèque :", required: true,  value: infos.cotisations.Emetteur, disabled:true},
                        { key: "Montant",  label: "Montant :", required: false,  value: infos.cotisations.Montant, devise:'€', disabled:true},
                        { key: "NumRecu",  label: "Numéro de reçu :", required: false,  value: infos.cotisations.NumRecu, disabled:true},
                        { key: "DateValAdh",  label: "Mise en banque :", required: true,  value: infos.cotisations.DateValAdh, date:true, disabled:true},
                        { key: "NumRemise",  label: "Numéro de remise :", required: false,  value: infos.cotisations.NumRemise, disabled:true},
                        { key: "aoAdresse",  label: "Adresse :", required: true,  value: club.aoAdresse,},
                        { key: "aoCp",  label: "Code postal :", required: true,  value: club.aoCp,},
                        { key: 'pays', label: 'Pays :', value: modifyUserDatas.paysSelected.idPa, required: true, dropdown: paysDropdown, disabled:true},
                        { key: "aoTel",  label: "Téléphone :", required: true,  value: club.aoTel,},
                        { key: "aoFax",  label: "Fax :", required: false,  value: club.aoFax,},
                        { key: "aoVille",  label: "Ville :", required: true,  value: club.aoVille,},
                        { key: "aoMobile",  label: "Mobile :", required: false,  value: club.aoMobile,},
                        { key: "aoEmail",  label: "Email :", required: false,  value: club.aoEmail,},
                        { key: "intituleCl",  label: "Intitulé du club :", required: true,  value: club.intituleCl,},
                        { key: "dateCreation",  label: "Date de creation :", required: false,  value: club.dateCreation, date:true, disabled: true},
                        { key: "article",  label: "Article :", required: false,  value: club.article,},
                        { key: "urlSite",  label: "URL du Site :", required: false,  value: club.urlSite,},
                        { key: 'Presi', label: 'Président :', value: infos.president.idElPre, required: true, dropdown: eleveursDropdown, table:'eleveur', disabled:true },
                        { key: "dateNominationPresi",  label: "Date de nomination du président :", required: false,  value: infos.president.dateNominationPresi, date:true, disabled:true},
                        { key: 'Treso', label: 'Trésorier :', value: infos.tresorier.idElTreso, required: true, dropdown: eleveursDropdown, table:'eleveur', disabled:true },
                        { key: "dateNominationTreso",  label: "Date de nomination du trésorier :", required: false,  value: infos.tresorier.dateNominationTreso, date:true, disabled:true},
                        { key: 'Sec', label: 'Secrétaire :', value: infos.secretaire.idElSec, required: true, dropdown: eleveursDropdown, table:'eleveur', disabled:true},
                        { key: "dateNominationSecret",  label: "Date de nomination de la  secrétaire :", required: false,  value: infos.secretaire.dateNominationSecret, date:true, disabled:true},
                        { key: "dateDepotStatut",  label: "Date de dépôt en préfecture : ", required: false,  value: infos.statut.dateDepotStatut, date:true, disabled:true},
                        { key: "dateReceptionStatut",  label: "Date de réception des statuts : ", required: false,  value: infos.statut.dateReceptionStatut, date:true, disabled:true},
                        { key: "dateAssembleGenerale",  label: "Date l'assemblée générale : ", required: false,  value: infos.pvag.dateAssembleGenerale, date:true, disabled:true},
                        { key: "dateReceptionPV",  label: "Date de réception du PV : ", required: false,  value: infos.pvag.dateReceptionPV, date:true, disabled:true},
                        { key: "dateFinEx",  label: "Date de fin d'exercice : ", required: false,  value: infos.compteRes.dateFinEx, date:true, disabled:true},
                        { key: "dateReceptEx",  label: "Date de réception : ", required: false,  value: infos.compteRes.dateReceptEx, date: true, disabled:true},
                        { key: "natureDoc",  label: "Nature du document :", required: false,  value: infos.document.natureDoc, disabled:true},
                        { key: "dateRecepDoc",  label: "Date de réception : ", required: false,  value: infos.document.dateRecepDoc, date : true, disabled:true},
                        { key: 'race', label: 'Race :', value: infos.race, required: true, dropdown: racesDropdown, multiple:true, disabled:true},
                        { key: "nbAdhAnnee",  label: "Année LOOF : ", required: false,  value: infos.nbAdherent.nbAdhAnnee, disabled:true},
                        { key: "nbAdherent",  label: "Nombre d'adhérent(s) : ", required: false,  value: infos.nbAdherent.nbAdherent, disabled:true},
                        { key: "nbRepresentant",  label: "Nombre de représentant(s) : ", required: false,  value: infos.nbAdherent.nbRepresentant, disabled:true},
                        { key: "actiBulletin",  label: "Bulletin d'information :", required: false,  value: infos.activites.actiBulletin, checked:true, disabled:true},
                        { key: "actiPbSante",  label: "Gestion des problèmes de santé lié à la race :", required: false,  value: infos.activites.actiPbSante, checked:true, disabled:true},
                        { key: "actiSiteActu",  label: "Site actualisé :", required: false,  value: infos.activites.actiSiteActu, checked:true, disabled:true},
                        { key: "actiSpeElevage",  label: "Spéciales d'élevage :", required: false,  value: infos.activites.actiSpeElevage, checked:true, disabled:true},
                    ]}
                    modifyRoute={`api/adherents/club/update/${club.id}`}
                />
            )}

            {dataHistorique &&
                <Popup id="popup" show={showPopup} theadData={headerTable} isLoading={isLoading} limit={100} name={popupName} tbodyData={dataHistorique} onClose={handlePopupClose}/>}

            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
}

export default FicheClubs;
