import React, { useEffect } from 'react';
import {
  Text,
  Font,
  Page,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer, Link,
} from '@react-pdf/renderer';

import Header from './Header';
import IdGenAndTest from './IdGenAndTest';
import Content from './Content';
import PorteeCatteryBreeder from './PorteeCatteryBreeder';
import Footer from './Footer';
import Watermark from '../Watermark';
import Diplomas from './Diplomas';

Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: 'Lato',
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

export const generatePedigree = ({ pedigreeInfos, pedColor, pedType }) => (
  <Document
    author="Livre Officiel des Origines Félines"
    title="Pedigree"
  >
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Header
            pedigreeInfos={pedigreeInfos.catInfos}
            pedColor={pedColor}
            pedType={pedType}
          />
        </View>

        <View style={styles.contentGeneral}>
          <View style={styles.contentLeft}>
            {pedigreeInfos.catInfos.genetiqueChaton !== ''
                || (pedigreeInfos.catInfos.tests !== undefined && pedigreeInfos.catInfos.tests.length >0)
                ? (<View style={styles.idGenAndTest}>
                <IdGenAndTest
                  pedigreeInfos={pedigreeInfos.catInfos}
                  pedColor={pedColor}
                />
              </View>
              ) : ''
            }
            <View style={styles.porteeCatteryBreeder}>
              <PorteeCatteryBreeder
                breederInfos={pedigreeInfos.breederInfos}
                porteeInfos={pedigreeInfos.porteeInfos}
                catInfos={pedigreeInfos.catInfos}
                pedColor={pedColor}
              />
            </View>
            <View style={styles.diplomas}>
              <Diplomas
                pedigreeInfos={pedigreeInfos.catInfos}
                pedColor={pedColor}
              />
            </View>
            <View style={styles.footer}>
              <Footer style={styles.footer}
                pedigreeInfos={pedigreeInfos.catInfos}
                pedColor={pedColor}
              />
            </View>
          </View>
          <View style={styles.contentRight}>
            <View style={styles.content}>
              <Content
                pedigreeInfos={pedigreeInfos.ancestors}
                pedColor={pedColor}
                pedType={pedType}
              />
            </View>
          </View>
        </View>
        {/*TODO voir pour mettre PED en dynamic*/}
        <View style={styles.footerLOOF}>
          <Text style={styles.LOOF}>Livre Officiel des Origines Félines - <Link style={styles.link}>https://www.loof.asso.fr</Link>
            {' '}-{' '}LOOF – SIRET : 312 646 854 00025 –
            {(pedType === "Pedigree") ? "PED / V10 06-2024" :
                (pedType === "Pedigree RIEX") ? "RIEX/V6 juin 2024"
                    : (pedType === "Pedigree RIA") ? "RIA/V6 juin 2024" :
                        (pedType === "Registre de filiation") ? "RF/V6 juin 2024" : ''
              }
            </Text>
        </View>
        <View style={styles.watermark}>
          <Watermark />
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    padding: 15,
    fontFamily: 'Lato',
  },
  container: {
    flex: 1,
  },
  header: {
    width: '100%',
    marginBottom: 10,
  },
  contentGeneral: {
    flexDirection: 'row',
    flex: 9,
  },
  contentLeft: {
    flex: 1,
  },
  idGenAndTest: {
    flex: 1,
  },
  porteeCatteryBreeder: {
    flex: 3,
  },
  diplomas: {
    flex: 2,
  },
  content: {
    flex: 1,
  },
  contentRight: {
    flex: 4,
    paddingLeft: 10,
  },
  footer: {
    marginTop: 20,
    width: '100%',
  },
  watermark: {
    position: 'absolute',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  footerLOOF: {
    width: '100%',
  },
  LOOF: {
    fontFamily: 'Lato Bold',
    fontSize: 7,
    textAlign: 'center',
  },
});
