import React from 'react';
import {
  Text,
  Font,
  Page,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';

import Header from './Header';
import IdGenAndTest from './IdGenAndTest';
import GeneralInfos from './GeneralInfos';
import Content from './Content';
import PorteeCatteryBreeder from './PorteeCatteryBreeder';
import Footer from './Footer';
import Watermark from '../Watermark';

Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: 'Lato',
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

export const generatePedigree = ({ pedigreeInfos }) => (
  <Document
    author="Livre Officiel des Origines Félines"
    title="Pedigree"
  >
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.container}>
        <View style={styles.headerContent}>
          <View style={styles.headerLeft}>
            <View style={styles.header}>
              <Header
                pedigreeInfos={pedigreeInfos.catInfos}
              />
            </View>
            <View style={styles.generalInfos}>
              <GeneralInfos
                pedigreeInfos={pedigreeInfos.catInfos}
              />
            </View>
          </View>
          <View style={styles.headerRight}>
            <IdGenAndTest
              pedigreeInfos={pedigreeInfos.catInfos}
            />
          </View>
        </View>
        <View style={styles.content}>
          <Content
            pedigreeInfos={pedigreeInfos.ancestors}
          />
        </View>
        <View style={styles.porteeCatteryBreeder}>
          <PorteeCatteryBreeder
            breederInfos={pedigreeInfos.breederInfos}
            porteeInfos={pedigreeInfos.porteeInfos}
            catInfos={pedigreeInfos.catInfos}
          />
        </View>
        <View style={styles.footer}>
          <Footer style={styles.footer}
            pedigreeInfos={pedigreeInfos.catInfos}
          />
        </View>
        <View style={styles.watermark}>
          <Watermark />
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    padding: 15,
    fontFamily: 'Lato',
  },
  container: {
    flex: 1,
  },
  headerContent: {
    flex: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerLeft: {
    width: '100%',
    marginRight: '5%',
  },
  headerRight: {
    width: '17%',
  },
  content: {
    flex: 14,
  },
  porteeCatteryBreeder: {
    flex: 4,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  watermark: {
    position: 'absolute',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});
