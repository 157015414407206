import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';
import FicheAdherent from '../../Adherents/Clubs/FicheClubs';
import {axiosCore} from "../../../utils/http-core-service";

const FicheExpoClubs = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [club, setClub] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosCore.get(`/api/club/${id}`);
                console.log("Data fetched: ", response.data);
                setClub(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log("Error fetching data: ", error);
                setIsLoading(false);
                setError(error);
            }
        }
        fetchData();
    }, [id]);
    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && club && club.idAdh === 0 &&
                <div>
                    <div className='fil-arenae d-flex align-items-center mb-3'>
                        <h5 className='mb-0'>
                            <NavLink className='link' to={`/expositions/clubs`}>Clubs</NavLink>
                        </h5>
                        <div className='mx-2'> &gt; </div>
                        <h5 className='mb-0'>Fiche Club</h5>
                    </div>
                    <div className="d-flex flex-column gap-2">

                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Acronyme du club : </b></h6>
                                                    <div className='col-7'>{club.acronyme}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Intitulé du club :</b></h6>
                                                    <div className='col-7'>{club.intituleCl}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Date de création :	</b></h6>
                                                    <div className='col-7'>{club.dateCreation}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Article : </b></h6>
                                                    <div className='col-7'>{club.article}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>URL du site : </b></h6>
                                                    <div className='col-7'><a href={club.urlSite} target='_blank' rel='noreferrer'>{club.urlSite}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header text-center text-white fs-6">Adresse officielle du club</h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Adresse : </b></h6>
                                                    <div className='col-7'>{club.aoAdresse}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Code postal :</b></h6>
                                                    <div className='col-7'>{club.aoCp}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Pays :	</b></h6>
                                                    <div className='col-7'>{club.aoPays?.intitulePa}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Ville :	</b></h6>
                                                    <div className='col-7'>{club.aoVille}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Téléphone :	</b></h6>
                                                    <div className='col-7'>{club.aoTel}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Mobile :	</b></h6>
                                                    <div className='col-7'>{club.aoMobile}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Fax :	</b></h6>
                                                    <div className='col-7'>{club.aoFax}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Email :	</b></h6>
                                                    <div className='col-7'>{club.aoEmail}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!club.agOk &&
                            <div className='row mb-4'>
                                <div className='col-lg-12'>
                                    <div className="card">
                                        <h5 className="card-header text-center text-white fs-6">Adresse de gestion du club</h5>
                                        <div className="card-body">
                                            <div id='bottomBox' className=""> {/* Bloc bas */}
                                                <div className='form-line'>
                                                    <div className='row form-line-item'>
                                                        <h6 className='col-5'><b>Adresse : </b></h6>
                                                        <div className='col-7'>{club.agAdresse}</div>
                                                    </div>
                                                    <div className='row form-line-item'>
                                                        <h6 className='col-5'><b>Code postal :</b></h6>
                                                        <div className='col-7'>{club.agCp}</div>
                                                    </div>
                                                    <div className='row form-line-item'>
                                                        <h6 className='col-5'><b>Pays :	</b></h6>
                                                        <div className='col-7'>{club.agPays?.intitulePa}</div>
                                                    </div>
                                                    <div className='row form-line-item'>
                                                        <h6 className='col-5'><b>Ville :	</b></h6>
                                                        <div className='col-7'>{club.agVille}</div>
                                                    </div>
                                                    <div className='row form-line-item'>
                                                        <h6 className='col-5'><b>Téléphone :	</b></h6>
                                                        <div className='col-7'>{club.agTel}</div>
                                                    </div>
                                                    <div className='row form-line-item'>
                                                        <h6 className='col-5'><b>Mobile :	</b></h6>
                                                        <div className='col-7'>{club.agMobile}</div>
                                                    </div>
                                                    <div className='row form-line-item'>
                                                        <h6 className='col-5'><b>Fax :	</b></h6>
                                                        <div className='col-7'>{club.agFax}</div>
                                                    </div>
                                                    <div className='row form-line-item'>
                                                        <h6 className='col-5'><b>Email :	</b></h6>
                                                        <div className='col-7'>{club.agEmail}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            }
            {!isLoading && club && club.idAdh !== 0 &&
                <FicheAdherent club={club} />
            }
            {
                error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger l'éleveur ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                </div>
            }
        </div >
    );
}

export default FicheExpoClubs;
