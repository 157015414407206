import React, { useState, useEffect } from 'react';
import {axiosCore} from "../../utils/http-core-service";

const CatInfo = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getRequests = async () => {
        setIsLoading(true)
        try {
            const response = await axiosCore.get(`/api/demandes/latest`);
            setData(response.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    const renderStatut = (value) => {
        let statut = ''
        switch (value) {
            case 'in_progress':
                statut = 'En cours de traitement'
                break;
            case 'draft':
                statut = 'Brouillons'
                break;

            default:
                statut = value
                break;
        }

        return statut
    }

    useEffect(() => {
        getRequests();
    }, []);

    return (
        <div className='request-type'>
            <div class="card mb-4">
                <h5 class="card-header text-center">Demande n°{props.demande.id}</h5>
            </div>

            <div id='middleBox' className="d-flex justify-content-between gap-4"> {/* Bloc milieu */}
                <div className="whiteBox d-flex flex-column align-items-start gap-2"> {/* Bloc DSN */}
                    <div className="d-flex gap-2 flex-wrap">
                        <h6 className="mb-0"><b>Date:</b> {props.demande.createdAt}</h6>
                    </div>
                    <div className="d-flex gap-2 align-items-center flex-wrap">
                        <h6 className="mb-0"><b>Montant:</b> {props.demande.totalCoast}</h6>
                    </div>
                </div>
                <div className="whiteBox d-flex flex-column align-items-start gap-2"> {/* Bloc Pedigree */}
                    <div className="d-flex gap-2 flex-wrap">
                        <h6 className="mb-0"><b>Statut :</b> <div className="badge bg-primary">{renderStatut(props.demande.status)}</div></h6>
                        {/* <div className="badge bg-primary">*TODO*</div> */}
                    </div>
                    <div className="d-flex gap-2 align-items-center flex-wrap">
                        <h6 className="mb-0"><b>Facture :</b></h6>
                    </div>
                </div>
            </div>

            <div class="card mb-5">
                <h5 class="card-header"></h5>
                <div class="card-body">
                    <div id='bottomBox' className=""> {/* Bloc bas */}
                        <div className='form-line'>
                            <div className='row form-line-item'>
                                <h6 className='col-4'><b>Nom du chat / N° origine :	</b></h6>
                                <div className='col-8'>
                                    <div className='d-flex'>
                                        <input className='form-control' value={`TETHY`} style={{width: '200px'}}/>
                                        <input className='form-control ms-2' value={`DE`} disabled style={{width: '50px'}}/>
                                        <input className='form-control ms-2' value={`LAF 222990`} disabled style={{maxWidth: '150px'}}/>
                                        <input className='form-control ms-2' value={`2023.7441 RIEX3`} disabled style={{maxWidth: '200px'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <h6 className='col-4'><b>Race :	</b></h6>
                                <div className='col-8'>
                                <div className='d-flex'>
                                        <input className='form-control' value={`Toyger`} style={{width: '250px'}} disabled/>
                                        <div className='text-action'>modifier race</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <h6 className='col-4'><b>Robe :	</b></h6>
                                <div className='col-8'>
                                <div className='d-flex'>
                                        <input className='form-control' value={`brown mackerel tabby`} style={{width: '250px'}} disabled/>
                                        <div className='text-action'>modifier robe</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <h6><b>Yeux : </b></h6>
                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <select className='form-select'>
                                                        <option>OR</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-8'>
                                <div className='row'>
                                        <div className='col-3'>
                                            <h6><b>Sexe : </b></h6>
                                        </div>
                                        <div className='col-5'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <select className='form-select'>
                                                        <option>Femelle</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <h6><b>Date de naissance : </b></h6>
                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <input type='date' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-8'>
                                <div className='row'>
                                        <div className='col-3'>
                                            <h6><b>Identification :	</b></h6>
                                        </div>
                                        <div className='col-5'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <input type='text' className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <h6><b>Date de décès : </b></h6>
                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <input type='date' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-8'>
                                <div className='row'>
                                        <div className='col-3'>
                                            <h6><b>Date d'envoi du pedigree :</b></h6>
                                        </div>
                                        <div className='col-5'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <input type='date' className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <h6><b>Favori :</b></h6>
                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <input type='checkbox' className='form-check-input'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-8'>
                                <div className='row'>
                                        <div className='col-3'>
                                            <h6><b>Demande DNA : </b></h6>
                                        </div>
                                        <div className='col-5'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <select className='form-select'>
                                                        <option>NON</option>
                                                        <option>OUI</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                </div>
                                <div className='col-8'>
                                <div className='row'>
                                        <div className='col-3'>
                                            <h6><b>Blacklisté :	 </b></h6>
                                        </div>
                                        <div className='col-5'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <input type='checkbox' className='form-check-input'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-5">
                <h5 class="card-header"></h5>
                <div class="card-body">
                    <div className=""> {/* Bloc bas */}
                        <div className='form-line'>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                    <h6><b>Commentaires robe (visible sur pedigree) : </b></h6>
                                </div>
                                <div className='col-8'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <input className='form-control' type='text' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-line'>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                    <h6><b>Mention DSN (visible sur Pedigree) : </b></h6>
                                </div>
                            </div>
                        </div>
                        <div className='form-line'>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                    <h6><b>Commentaire reproducteur (visible sur Pedigree) : </b></h6>
                                </div>
                                <div className='col-8'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <textarea className='form-control'></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-line'>
                            <div className='row form-line-item'>
                                <div className='col-4'>
                                    <h6><b>Commentaire reproducteur : </b></h6>
                                </div>
                                <div className='col-8'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <textarea className='form-control'></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CatInfo;
