import React, {useEffect, useState} from 'react';
import TableTemplate from "../../../components/TableTemplate/TableTemplate";
import {Typeahead} from 'react-bootstrap-typeahead';
import Pagination from "../../../components/Pagination/Pagination";
import {axiosCore} from "../../../utils/http-core-service";


const Clubs = () => {

    const header = [
        {
            "key": "idClub",
            "label": "ID"
        },
        {
            "key": "acroClub",
            "label": "Libellé"
        },
        {
            "key": "nomClub",
            "label": "Nom du club"
        },
        {
            "key": "paysClub",
            "label": "Pays du club"
        },
        {
            "key": "collegeLibelle",
            "label": "College"
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div>
                    <button type="button" className="btn btn-outline-primary rounded-5" onClick={() => {
                        window.open(`/adherents/clubs/${row.idClub}`, '_self');
                    }}>
                        Voir la fiche
                    </button>
                    <button type="button" className="btn btn-outline-primary rounded-5 ms-2" onClick={() => {
                        window.open(`/adherents/clubs/${row.idClub}`, '_blank');
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill='black'>
                            <path
                                d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
                        </svg>
                    </button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilters, setActiveFilters] = useState({});
    const [pays, setPays] = useState([]);
    const [paysSelection, setPaysSelection] = useState([]);
    const [college, setCollege] = useState([]);
    const [collegeSelection, setCollegeSelection] = useState([]);


    const getAll = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;

            if (!search) {
                response = await axiosCore.get(`/api/club/list`);
            } else {
                console.log(search)
                response = await axiosCore.get(`/api/club/search/${JSON.stringify(search)}`);
            }
            setData(response.data.reverse());
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    const getPays = async () => {
        try {
            const response = await axiosCore.get(`/api/pays/list`);
            setPays(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getAdhCollege = async () => {
        try {
            const response = await axiosCore.get(`/api/college/list`);
            setCollege(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAll();
        getPays();
        getAdhCollege();
    }, []);
    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Clubs</h5>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-3">
                    <label htmlFor="idClub" className="form-label">ID Club :</label>
                    <input type="text" className="form-control" id="idClub"  inputMode="numeric" onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                    }}/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="acroClub" className="form-label">Libellé du club :</label>
                    <input type="text" className="form-control" id="acroClub"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="nomClub" className="form-label">Nom du club :</label>
                    <input type="text" className="form-control" id="nomClub"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="paysClub" className="form-label">Pays</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="paysClub"
                        placeholder={'Rechercher un pays'}
                        options={pays.map((value) => value.intitulePays)}
                        onChange={setPaysSelection}
                        selected={paysSelection}
                        emptyLabel="Pas de pays trouvé"
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="adhCollege" className="form-label">College</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="adhCollege"
                        placeholder={'Rechercher un college'}
                        options={college.map((value) => value.libelle)}
                        placeholder={'Rechercher un college'}
                        onChange={setCollegeSelection}
                        selected={collegeSelection}
                    />
                </div>
                <div className="col-md-6"></div>
                <div className="col-12 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary d-none col-md-2" id='resetButton'
                            onClick={() => {
                                getAll();
                                setActiveFilters({});
                                document.getElementById('idClub').value = '';
                                document.getElementById('nomClub').value = '';
                                document.getElementById('acroClub').value = '';
                                setPaysSelection([]); // Reset the race selection
                                setCollegeSelection([]); // Reset the race selection
                                document.getElementById('resetButton').classList.add('d-none');
                            }}>Réinitialiser
                    </button>
                    <button type="submit" className="btn btn-primary col-md-2" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'idClub': document.getElementById('idClub').value.replace(/\s/g, ''), // Retire les espaces de la recherche
                            'nomClub': document.getElementById('nomClub').value.trim(), // Retire les espaces de début et de fin de la string
                            'acroClub': document.getElementById('acroClub').value.replace(/\s/g, ''),
                            'paysClub': pays.filter((value) => paysSelection.includes(value.intitulePays)).map((value) => value.idPays), // Get the race ID from the race name
                            'adhCollege': college.filter((value) => collegeSelection.includes(value.libelle)).map((value) => value.idCollege), // Get the race ID from the race name
                            'excludLoof': false,
                        };
                        console.log(search);
                        getAll(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher
                    </button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des clubs</h4>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{
                                width: "30px",
                                height: "30px",
                                textAlign: "center",
                                backgroundColor: "rgba(217, 217, 217, 0.50)",
                                border: "0",
                                borderRadius: ".25rem"
                            }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }}/>
                        </div>
                        <div className="ps-2">par page</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'clubs trouvés' : 'club trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default Clubs;
