import {useParams} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Toast} from "react-bootstrap";

import './CreateOrUpdateCatView.css';
import CreateOrUpdateCatForm from "../../components/Cat/CreateOrUpdateCatForm";
import * as CatService from '../../services/cat.service';
import {useState} from "react";

const CreateOrUpdateCatView = () => {
    const timeout = 1750; // ms

    const {id} = useParams();
    const [title, setToastTitle] = useState('');
    const [message, setToastMessage] = useState('');
    const [variant, setToastVariant] = useState('success');
    const [show, setToastShow] = useState(false);

    const handlers = {
        submit: (data) => {
            (id ? CatService.update(id, data) : CatService.create(data))
                .then(data => {
                    setToastTitle('Opération réussie');
                    setToastVariant('success');
                    setToastMessage('Le chat a été enregistré avec succés');
                    setTimeout(() => window.location.href = `/chats/${data.id}`, timeout);
                })
                .catch((err) => {
                    console.error(err);
                    setToastTitle('Opération échoué');
                    setToastVariant('success');
                    setToastMessage('Une erreur est survenue lors de l\'enregistrement du chat.');
                })
                .finally(() => {
                    setToastShow(true);
                });
        }
    };

    return (<main className="Content">
        <Toast className="d-inline-block m-1" bg={variant.toLowerCase()} delay={timeout} autohide={true} show={show}
               onClose={() => setToastShow(false)}>
            <Toast.Header closeButton={false}>
                <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body className="text-white">
                {message}
            </Toast.Body>
        </Toast>
        <Breadcrumb className="h4">
            <BreadcrumbItem href="/chats">
                Chats
            </BreadcrumbItem>
            <BreadcrumbItem active={true}>
                {id && `Mise à jour de la fiche du chat #${id}` || `Création d'une fiche de chat`}
            </BreadcrumbItem>
        </Breadcrumb>
        <div className="container">
            <CreateOrUpdateCatForm id={id} onSubmitHandler={handlers.submit} className="mb-5"/>
        </div>
    </main>);
}

export default CreateOrUpdateCatView;