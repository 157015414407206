import { Button, Form, FormGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import * as CatService from '../../services/cat.service';
import * as BreederService from '../../services/breeder.service';
import SearchCat from "../SearchCat/SearchCat";

const CreateOrUpdateCatForm = ({
    id = null,
    className = null,
    options = {},
    onSubmitHandler = null,
}) => {

    const [validated, validate] = useState(false);

    const [sexes, setSexes] = useState([]);
    const [sexe, setSexe] = useState('');

    const [breeds, setBreeds] = useState([]);
    const [breed, setBreed] = useState('');

    const [coats, setCoats] = useState([]);
    const [coat, setCoat] = useState('');

    const [eyes, setEyes] = useState([]);
    const [eye, setEye] = useState('');

    const [breedDefault, setBreedDefault] = useState(null);

    const [mentionsList, setMentionsList] = useState([]);
    const [mentions, setMentions] = useState([]);
    const [mention, setMention] = useState('');

    const [mothers, setMothers] = useState([]);
    const [mother, setMother] = useState(null);
    const [isMothersSearchLoading, setIsMothersSearchLoading] = useState(false);

    const [fathers, setFathers] = useState([]);
    const [father, setFather] = useState(null);
    const [isFathersSearchLoading, setIsFathersSearchLoading] = useState(false);

    const [owners, setOwners] = useState([]);
    const [owner, setOwner] = useState(null);
    const [isOwnersSearchLoading, setIsOwnersSearchLoading] = useState(false);

    options = {
        birthdate: {
            max: new Date().toLocaleDateString().split('/').reverse().join('-'),
        },
        ...options,
    }

    const handlers = {
        submit: (ev) => {
            ev.preventDefault();
            validate(true);
            const element = ev.target;
            if (element.checkValidity()) {
                const form = new FormData(element);
                form.set('IdRa', breed.id);
                form.set('IdRo', coat.id);
                form.set('IdYe', eye.id);
                form.set('IdPere', mother.id);
                form.set('IdMere', father.id);
                form.set('IdEl', owner.id);
                form.set('Sexe', sexe);
                form.set('IsBlacklisted', form.get('IsBlacklisted') === 'true');
                form.set('Repro', form.get('Favori') === 'true');
                {mention && form.set('IdMention', mention)}
                {mention && form.set('IntituleMention', mentions.find(item => item.id === Number(mention)).mention)}

                if (onSubmitHandler instanceof Function) {
                    onSubmitHandler(form);
                }
            }
        },
        search: (query, type) => {
            switch (type.toLowerCase()) {
                case 'father':
                case 'fathers':
                    setIsFathersSearchLoading(true);
                    CatService.queryByName('fathers', query)
                        .then(setFathers)
                        .finally(() => setIsFathersSearchLoading(false));
                    break;
                case 'mother':
                case 'mothers':
                    setIsMothersSearchLoading(true);
                    CatService.queryByName('mothers', query)
                        .then(setMothers)
                        .finally(() => setIsMothersSearchLoading(false));
                    break;
                case 'owner':
                case 'owners':
                    setIsOwnersSearchLoading(true);
                    BreederService.queryByName(query)
                        .then(setOwners)
                        .finally(() => setIsOwnersSearchLoading(false));
                    break;
            }
        },
        change: (ev) => {
            console.log('Trigger change form')
        }
    };

    const setSelectionOnChange = (options, callback) => {
        if (options instanceof Array && 0 < options.length) {
            callback(options[0]);
        } else {
            callback(null);
        }
    };

    const renderDisplaySelection = (id, content) => {
        return (<div className="row">
            <span className="col-2 text-end fw-bold">({id})</span>
            <span className="col-10">{content}</span>
        </div>);
    };

    const defaultFilterBy = () => true;

    useEffect(() => {
        (async () => {
            setSexes(await CatService.getSexeList());
            setBreeds(await CatService.getBreedList());
            setCoats(await CatService.getCoatList());
            setEyes(await CatService.getEyeList());
            setBreedDefault(await CatService.getBreedDefault());
            setMentions(await CatService.getBreedMention());
        })();
    }, []);

    useEffect(() => {
        if (breed && breedDefault) {
            if (breedDefault instanceof Array && 0 < breedDefault.length) {
                const defaultBreed = breedDefault.find(b => b.breedID === breed.id);
                if (defaultBreed) {
                    setCoat(coats.find(c => c.id === defaultBreed.coatID));
                    setEye(eyes.find(e => e.id === defaultBreed.eyeID));
                }
            }
        }
        if (!breed) {
            setCoat('');
            setEye('');
            setMention('');
            setMentionsList([]);
        }
    }, [breed]);

    useEffect(() => {
        if (breed && breed.id) {
            const filteredMentions = mentions.filter(mention =>
                mention.breeds.some(breedItem => breedItem.id === breed.id)
            );
            setMentionsList(filteredMentions);
        }
    }, [breed, mentions]);

    return (<>
        <Form className={`row` + (className ? ` ${className}` : '')} validated={validated} onSubmit={handlers.submit}
            onChange={handlers.change} noValidate={true}>
            <Form.Control type="hidden" name="id" value={id ?? ''} />
            <div className="col-12 col-md-6">
                <Form.FloatingLabel label={<>Nom du chat<span className="text-danger">*</span></>} className="mb-3">
                    <Form.Control type="text" name="NomChat" placeholder="Nom du chat" required={true} />
                </Form.FloatingLabel>
            </div>
            <div className="col-12 col-md-6">
                <Form.FloatingLabel label="Numéros d'origine" className="mb-3">
                    <Form.Control type="text" name="NumOrigin" placeholder="Numéros d'origine" />
                </Form.FloatingLabel>
            </div>
            <div className="col-12 col-md-6">
                <FormGroup className="mb-3">
                    <Form.Label>Sexe<span className="text-danger">*</span></Form.Label>
                    <Typeahead id="create-or-update-form-sexe-selector" options={sexes} labelKey="sexe" name="Sexe"
                        onChange={(opts) => setSelectionOnChange(opts, setSexe)}
                        placeholder="Rechercher intitulé" inputProps={{ required: true }}
                        emptyLabel="Aucun résultat." />
                </FormGroup>

                <Form.FloatingLabel label="Date de naissance" className="mb-3">
                    <Form.Control type="date" max={options.birthdate.max} name="DateNaissance"
                        placeholder="Date de naissance" />
                </Form.FloatingLabel>

                <Form.FloatingLabel label="Identification" className="mb-3">
                    <Form.Control type="text" name="NumTatouage" placeholder="Identification" />
                </Form.FloatingLabel>

                <FormGroup className="d-flex mb-3">
                    <Form.Check type="checkbox" name="IsBlacklisted" label="Blacklisté" className="col-12 col-md-6"
                        value="true" />
                </FormGroup>
                <FormGroup className="d-flex mb-3">
                    <Form.Check type="checkbox" name="Favori" label="Favori" className="col-12 col-md-6"
                        value="true" />
                </FormGroup>
            </div>
            <div className="col-12 col-md-6">
                <FormGroup className="mb-3 d-flex gap-3">
                    <div className="w-100">
                        <Form.Label>Race<span className="text-danger">*</span></Form.Label>
                        <Typeahead id="create-or-update-form-breeds-selector" options={breeds} labelKey="designation"
                            name="IdRa" onChange={(opts) => setSelectionOnChange(opts, setBreed)}
                            placeholder="Rechercher intitulé" emptyLabel="Aucun résultat."
                            inputProps={{ required: true }} />
                    </div>
                    {mentionsList.length > 0 &&
                        <div className="col-6">
                            <Form.Label>Mention<span className="text-danger">*</span></Form.Label>
                            <Form.Select name="IdMention" required={false} onChange={(ev) => setMention(ev.target.value)}>
                                <option value="" selected>-</option>
                                {mentionsList.map((mention, index) => (
                                    <option key={index} value={mention.id}>{mention.mention}</option>
                                ))}
                            </Form.Select>
                        </div>
                    }
                </FormGroup>

                <FormGroup className="mb-3">
                    <Form.Label>Robe<span className="text-danger">*</span></Form.Label>
                    <Typeahead id="create-or-update-form-coats-selector" options={coats} labelKey="designation"
                        name="IdRo" onChange={(opts) => setSelectionOnChange(opts, setCoat)}
                        placeholder="Rechercher par intitulé" emptyLabel="Aucun résultat."
                        inputProps={{ required: true }} selected={coat ? [coat] : []} />
                </FormGroup>

                <FormGroup className="mb-3">
                    <Form.Label>Yeux<span className="text-danger">*</span></Form.Label>
                    <Typeahead id="create-or-update-form-eyes-selector" options={eyes} labelKey="designation"
                        name="IdYe" onChange={(opts) => setSelectionOnChange(opts, setEye)}
                        placeholder="Rechercher par intitulé" emptyLabel="Aucun résultat."
                        inputProps={{ required: true }} selected={eye ? [eye] : []} />
                </FormGroup>
            </div>
            <div className="col-12 col-md-6 mb-3">
                <SearchCat
                    onSubmitHandler={setFather}
                    formLabel="Père du reproducteur*"
                    name={true}
                    id={true}
                    numOrigin={true}
                    numIdentification={true}
                    sex="Mâle"
                />
            </div>
            <div className="col-12 col-md-6 mb-3">
                <SearchCat
                    onSubmitHandler={setMother}
                    formLabel="Mère du reproducteur*"
                    name={true}
                    id={true}
                    numOrigin={true}
                    numIdentification={true}
                    sex="Femelle"
                />
            </div>
            <div className="col-12">
                <FormGroup className="mb-3">
                    <Form.Label>Propriétaire<span className="text-danger">*</span></Form.Label>
                    <AsyncTypeahead id="create-or-update-form-fathers-search" name="IdEl" emptyLabel="Aucun résultat."
                        placeholder="Rechercher par nom ou prénom" searchText="..." options={owners}
                        onChange={(opts) => setSelectionOnChange(opts, setOwner)}
                        labelKey="designation" isLoading={isOwnersSearchLoading} filterBy={defaultFilterBy}
                        onSearch={(q) => handlers.search(q, 'owners')} required={true}
                        renderMenuItemChildren={(opt) => renderDisplaySelection(opt['id'], opt['designation'])}
                        inputProps={{ required: true }} />
                </FormGroup>
            </div>
            <div className="col-12">
                <Form.FloatingLabel label="Commentaire sur le chat" className="mb-3">
                    <Form.Control name="CommentairesCh" placeholder="Commentaire sur le chat" as="textarea"
                        style={{ minHeight: '100px' }} />
                </Form.FloatingLabel>
            </div>
            <div className="col-12">
                <Form.FloatingLabel className="mb-3"
                    label={(<>Commentaire sur le chat (visible sur pedigree)<span
                        className="text-danger">*</span></>)}>
                    <Form.Control name="CommentaireP" required={true} as="textarea" style={{ minHeight: '100px' }}
                        placeholder="Commentaire sur le chat (visible sur pedigree)" />
                </Form.FloatingLabel>
            </div>
            <div className="col-12">
                <Form.FloatingLabel className="mb-3"
                    label={(<>Commentaire sur la robe (visible sur pedigree)<span
                        className="text-danger">*</span></>)}>
                    <Form.Control name="CommentairesRo" required={true} as="textarea" style={{ minHeight: '100px' }}
                        placeholder="Commentaire sur la robe (visible sur pedigree)" />
                </Form.FloatingLabel>
            </div>

            <div className="col-12">
                <div className="d-flex">
                    <small className="flex-fill">
                        <span className="text-danger">*</span>
                        <small className="text-muted">Champs obligatoires</small>
                    </small>
                    <Button type="submit">
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        <span className="ms-1">Enregistrer</span>
                    </Button>
                </div>
            </div>
        </Form>
    </>);
}

export default CreateOrUpdateCatForm;