import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
import { useState, useEffect } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import {axiosCore} from "../../utils/http-core-service";

const Agria = () => {
    const { id } = useParams();
    const header = [
        {
            "key": "id",
            "label": "Numéro"
        },
        {
            "key": "eleveur",
            "label": "Eleveur",
            "render": (value, row, index) => {
                const url = '/demandes/' + row.id;
                return <div>{row.eleveur.prenom} {row.eleveur.nom}</div>
            }
        },
        {
            "key": "type",
            "label": "Type"
        },
        {
            "key": "dateFin",
            "label": "Objet"
        },
        {
            "key": "quantite",
            "label": "Quantité"
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                const url = '/agria/fiche/' + row.id;
                return <div>
                    <Link className="btn btn-outline-primary rounded-5" to={url}>Voir la fiche</Link>
                    <Link className="btn btn-outline-primary rounded-5 ms-2" to={url} target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill='black'><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                    </Link>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const getAgria = async (search = null) => {
        setIsLoading(true)
        try {
            let response = await axiosCore.get(`/api/agria_trans/${id}/agria`, {
                params: search === null ? {} : search,
            });
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearch = (event) => {
        
    }

    const reinitSearch = () => {
        
    }

    useEffect(() => {
        getAgria();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>
                    <NavLink className='link' to={`/agria`}>Export Agria</NavLink>
                </h5>
                <div className='mx-2'> &gt; </div>
                <h5 className='mb-0'>Agria N {id}</h5>
            </div>
            <div className="searchBox whiteBox request">
                <form onSubmit={handleSearch}>
                    <div className="d-flex mt-4 justify-content-end">
                        <button className='btn btn-primary mx-3' type='submit'>Filtrer</button>
                        <button className='btn btn-danger' type='button' onClick={reinitSearch}>Réinitialiser</button>
                    </div>
                </form>
            </div>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>EXPORT / TRANMISSION AGRIA N° {id}</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary">Créer un export</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'demandes trouvées' : 'demande trouvée'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default Agria;
