import React from 'react';
import { isEmpty } from 'lodash';
import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';

const GeneralInfos = ({ pedigreeInfos }) => {
  const raceChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.intituleRace) || '';
  const couleurChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.intituleRobe) || '';
  const commentairesRobe = (!isEmpty(pedigreeInfos) && pedigreeInfos.commentairesRobe) || '';
  const codeChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.codeEMS) || '';
  const dob = (!isEmpty(pedigreeInfos) && pedigreeInfos.dateNaissanceChat) || '';
  const sexeChat = (!isEmpty(pedigreeInfos) && pedigreeInfos.sexeChat) || '';
  const numLOOF = (!isEmpty(pedigreeInfos) && pedigreeInfos.numOrigineChat) || '';
  const id = (!isEmpty(pedigreeInfos) && pedigreeInfos.numIdentificationChat) || '';

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.title}>Race / Breed :</Text>
          <Text style={styles.value}>{raceChat}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Couleur / Colour :</Text>
          <Text style={styles.value}>{couleurChat} {commentairesRobe && `(${commentairesRobe})`}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.title}>Code :</Text>
          <Text style={styles.value}>{codeChat}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          {sexeChat === 'Mâle' ? (
            <Text style={styles.title}>Né le / DoB :</Text>
          ) : (
            <Text style={styles.title}>Née le / DoB :</Text>
          )}
          <Text style={styles.value}>{dob}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Sexe / Sex :</Text>
          <Text style={styles.value}>{sexeChat}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.title}>N° :</Text>
          <Text style={styles.value}>{numLOOF}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Id :</Text>
          <Text style={styles.value}>{id}</Text>
        </View>
      </View>
    </View>
  );
};

export default GeneralInfos;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  section: {
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    fontSize: 10,
    marginBottom: 5,
  },
  title: {
    fontFamily: 'Lato Bold',
    marginRight: 5,
  },
});
