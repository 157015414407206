import React, {useEffect, useState} from 'react';
import TableTemplate from '../TableTemplate/TableTemplate';
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {axiosCore} from "../../utils/http-core-service";

const StatistiqueDSNPerUserPerMois = () => {
    const todayYear = new Date().getFullYear();
    const getYearsPeriod = () => {
        const howManyYearsWeWant = 5;
        let years = []

        for (let i = 0; i < howManyYearsWeWant; i++) {
            years.push((todayYear - i).toString());
        }

        console.log(years)
        return years;
    };

    const getLastMonth = () => {
        // Get today month (+1 because it counts from 0)
        const todayMonth = new Date().getMonth() + 1;
        let lastMonth;

        // Si on est en janvier on passe en décembre
        if (todayMonth - 1 === 0) {
            lastMonth = 12;
        } else {
            lastMonth = todayMonth - 1;
        }
        lastMonth = lastMonth.toString();

        return lastMonth.length === 1 ? '0' + lastMonth : lastMonth;
    }

    // Si le mois est en décembre, alors c'est qu'on est en janvier et donc l'année par défaut doit être à n-1
    // si vous revenez ici parce qu'on est en janvier, c'est que ça doit pas avoir marché, désolé o7
    const defaultYear = getLastMonth() === '12' ? new Date().getFullYear() - 1 : new Date().getFullYear();

    const years = getYearsPeriod();
    const months = [
        {value: '01', label: 'Janvier'},
        {value: '02', label: 'Février'},
        {value: '03', label: 'Mars'},
        {value: '04', label: 'Avril'},
        {value: '05', label: 'Mai'},
        {value: '06', label: 'Juin'},
        {value: '07', label: 'Juillet'},
        {value: '08', label: 'Août'},
        {value: '09', label: 'Septembre'},
        {value: '10', label: 'Octobre'},
        {value: '11', label: 'Novembre'},
        {value: '12', label: 'Décembre'},
    ];
    const header = [
        {
            "key": "first_name",
            "label": "Prénom"
        },
        {
            "key": "last_name",
            "label": "Nom"
        }
    ]

    for (let index = 1; index <= 31; index++) {
        const number = index.toString().padStart(2, '0')
        header.push({key: `dsn${number}`, label: number});
    }

    // Couleurs de chart
    const chartBarColors = [
        "#2196f3",
        "#f44336",
        "#9c27b0",
        "#4caf50",
        "#ffc107",
        "#ff5722",
        "#009688",
    ]

    // Au chargement de la page, récupère les données du dernier mois/année
    useEffect(() => {
        getStats(defaultYear, getLastMonth());
    }, []);

    // Map les données du back en données utilisables par les charts
    const mapApiDataToChartsFormat = (data) => {
        const mappedData = [];

        data.forEach((item) => {
            mappedData.push({name: `${item.first_name} ${item.last_name}`, value: 0});
        })

        data.map((item, index) => {
            // remove first & last name from datas & push everything into "value"
            Object.keys(item).forEach(function(key) {
                if (key !== "first_name" && key !== "last_name") {
                    mappedData[index].value += parseInt(item[key]);
                }
            })
        })

        return mappedData;
    }

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getStats = async (year, month) => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/stats-dsn-per-user-per-month/${year}/${month}`;
        try {
            const response = await axiosCore.get(url);
            console.log(response.data)
            setData(response.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        if ('' === formJson.year || '' === formJson.month) {
            return;
        }

        getStats(formJson.year, formJson.month)
      }

    return (
        <div>
            <h4 className='stat-title'>
                Nombre de DSN par utilisateur par mois
            </h4>

            <form method="post" onSubmit={handleSubmit} className='stat-filter d-flex justify-content-end'>
                <select defaultValue={getLastMonth()} className="form-select form-select-sm me-2" style={{maxWidth: '15rem'}} name='month'>
                    {months.map((month, index) => (
                    <option key={index} value={month.value}>
                        {month.label}
                    </option>
                    ))}
                </select>
                <select defaultValue={defaultYear} className="form-select form-select-sm me-2" style={{maxWidth: '15rem'}} name='year'>
                    {years.map((year, index) => (
                    <option key={index} value={year}>
                        {year}
                    </option>
                    ))}
                </select>
                <button type="submit" className="btn btn-secondary btn-sm">Chercher</button>
            </form>
            {(data && data.length > 0 && !isLoading) && (
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={mapApiDataToChartsFormat(data)}>
                        <XAxis dataKey={'name'}/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="4"/>
                        <Tooltip/>
                        <Bar name={'DSN ce mois'} dataKey={'value'} >
                            {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={chartBarColors[index%7]}/>
                                ))
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            )}
            <br/>
            <TableTemplate
                theadData={header}
                tbodyData={data}
                isLoading={isLoading}
            />
        </div>
    );
}

export default StatistiqueDSNPerUserPerMois;
