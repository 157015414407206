import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';
import { axiosCore } from "../../../utils/http-core-service";
import TableTemplate from "../../../components/TableTemplate/TableTemplate";
import { putComment } from "../../../utils/utils";
import OpenLink from "../../../components/OpenLink/OpenLink";

const FicheExpo = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [expo, setExpo] = useState(null);
    const [error, setError] = useState(null);
    const emptyInfo = <i class="text-muted">Non renseigné</i>;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosCore.get(`/api/expo/${id}`);
                console.log("Data fetched: ", response.data);
                setExpo(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log("Error fetching data: ", error);
                setIsLoading(false);
                setError(error);
            }
        }
        fetchData();
    }, [id]);

    useEffect(() => {
        console.log("Expo: ", expo);
    }, [expo]);

    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && expo &&
                <div>
                    <div className='fil-arenae d-flex align-items-center mb-3'>
                        <h5 className='mb-0'>
                            <NavLink className='link' to={`/expositions/expositions`}>Expo</NavLink>
                        </h5>
                        <div className='mx-2'> &gt; </div>
                        <h5 className='mb-0'>Fiche Expo</h5>
                    </div>
                    <div className="d-flex flex-column gap-2">

                        {/* {
    "id": 722,
    "numExpo": "",
    "publi": false,
    "publiDate": null,
    "ville": "WÖRTH",
    "dept": "",
    "lieu": "",
    "pays": {
        "id": 1,
        "codePays": "DE",
        "intitulePa": "ALLEMAGNE"
    },
    "dateDebut": "27/04/2024",
    "dateFin": "28/04/2024",
    "description": "",
    "civiliteContact": "",
    "nomContact": "",
    "prenomContact": "",
    "mail": "",
    "telephone": "",
    "mobile": "",
    "recCatalog": null,
    "recResultats": null,
    "commentaires": "",
    "createDate": "29/04/2024",
    "updateDate": "29/04/2024",
    "clubs": [
        {
            "id": 99,
            "intituleCl": "KURPFÄLZER RASSEKATZEN VERBAND"
        }
    ],
    "expoJours": [
        {
            "id": 1171,
            "idEx": 722,
            "jour": "2024-04-27T00:00:00+00:00",
            "nbChatsAnnonces": 0,
            "nbChatsReels": 0,
            "valide": true,
            "commentaire": ""
        },
        {
            "id": 1172,
            "idEx": 722,
            "jour": "2024-04-28T00:00:00+00:00",
            "nbChatsAnnonces": 0,
            "nbChatsReels": 0,
            "valide": true,
            "commentaire": ""
        }
    ]
} */}

                        <div className='row mb-4'>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>N° exposition : </b></h6>
                                                    <div className='col-7'>{expo.numExpo ? expo.numExpo : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Ville :</b></h6>
                                                    <div className='col-7'>{expo.ville ? expo.ville : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Lieu :	</b></h6>
                                                    <div className='col-7'>{expo.lieu ? expo.lieu : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Date de début : </b></h6>
                                                    <div className='col-7'>{expo.dateDebut ? expo.dateDebut : emptyInfo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Publiée / Date de publication : </b></h6>
                                                    <div className='col-7'>{expo.publi !== null ? (expo.publi ? 'Oui' : 'Non') : emptyInfo} {expo.publiDate ? `/ ${expo.publiDate}` : ''}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Departement : </b></h6>
                                                    <div className='col-7'>{expo.dept ? expo.dept : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Pays :	</b></h6>
                                                    <div className='col-7'>{expo.pays?.intitulePa ? expo.pays.intitulePa : emptyInfo}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-5'><b>Date de fin :</b></h6>
                                                    <div className='col-7'>{expo.dateFin ? expo.dateFin : emptyInfo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <TableTemplate
                                    theadData={[
                                        { key: 'jour', label: 'Jour' },
                                        { key: 'nbChatsAnnonces', label: 'Nombre de chats annoncés' },
                                        { key: 'nbChatsReels', label: 'Nombre de chats réels' },
                                        { key: 'valide', label: 'Validé', type: 'bool' },
                                        { key: 'commentaire', label: 'Commentaire' },
                                    ]}
                                    tbodyData={expo.expoJours.map(expoJour => ({
                                        jour: expoJour.jour ? new Date(expoJour.jour).toLocaleDateString() : '',
                                        nbChatsAnnonces: expoJour.nbChatsAnnonces !== null ? expoJour.nbChatsAnnonces : '',
                                        nbChatsReels: expoJour.nbChatsReels !== null ? expoJour.nbChatsReels : '',
                                        valide: expoJour.valide !== null ? (expoJour.valide ? 'Oui' : 'Non') : '',
                                        commentaire: expoJour.commentaire !== null ? expoJour.commentaire : '',
                                    }))}
                                />
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='row'>
                                                <h6 className='col-3'><b>Description : </b></h6>
                                                <div className='col-9'>
                                                    {expo.description ? putComment(expo.description) : emptyInfo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-3'><b>Club(s) organisateur(s) : </b></h6>
                                                    <div className='col-9'>
                                                        {expo.clubs.map(club => (
                                                            <OpenLink
                                                                url={`/adherents/clubs/${club.id}`}
                                                                content={club.intituleCl}
                                                                divClassName='mr-2'
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                {/* [
    {
        "nom": "KELLER",
        "prenom": "Béatrice Anne",
        "civilite": "Mme",
        "codePays": "CH"
    }
] */}
                                                <div className='row'>
                                                    <h6 className='col-3'><b>Juge(s) : </b></h6>
                                                    <div className='col-9'>
                                                        {expo.juges.map(juge => (
                                                            <div>
                                                                {juge.civilite ? juge.civilite : emptyInfo} {juge.prenom ? juge.prenom : emptyInfo} {juge.nom ? juge.nom : emptyInfo} [{juge.codePays ? juge.codePays : emptyInfo}]
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='row'>
                                                <h6 className='col-3'><b>Civilite / Nom contact : </b></h6>
                                                <div className='col-9'>
                                                    {expo.civiliteContact ? expo.civiliteContact : emptyInfo} {expo.nomContact ? expo.nomContact : emptyInfo}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <h6 className='col-3'><b>Prenom contact : </b></h6>
                                                <div className='col-9'>
                                                    {expo.prenomContact ? expo.prenomContact : emptyInfo}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <h6 className='col-3'><b>Téléphone : </b></h6>
                                                <div className='col-9'>
                                                    {expo.telephone ? expo.telephone : emptyInfo}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <h6 className='col-3'><b>Mobile : </b></h6>
                                                <div className='col-9'>
                                                    {expo.mobile ? expo.mobile : emptyInfo}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <h6 className='col-3'><b>Email : </b></h6>
                                                <div className='col-9'>
                                                    {expo.mail ? expo.mail : emptyInfo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger l'éleveur ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                </div>
            }
        </div>
    );
}

export default FicheExpo;
