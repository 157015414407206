import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import DiplomasLogo from './assets/DiplomasLogo.png';

const Diplomas = ({ pedigreeInfos, pedColor }) => {
    const qualification = (!isEmpty(pedigreeInfos) && pedigreeInfos.qualification) || '';
    const titres = (!isEmpty(pedigreeInfos) && pedigreeInfos.titres) || [];
    const titreDist = (!isEmpty(pedigreeInfos) && pedigreeInfos.titreDist) || [];
    const allTitles = [];

    titres.map((item) => {
        allTitles.push(item);
    });
    titreDist.map((item) => {
        let hasAdd = false;
        allTitles.forEach((itemAll) => {
            if (item.federation === itemAll.federation) {
                itemAll.titres = [...itemAll.titres, ...item.titres];
                hasAdd = true;
            }
        })
        if (!hasAdd) {
            allTitles.push(item);
        }
    });


    const styles = StyleSheet.create({
        container: {
            width: '100%',
            height: '100%',
            border: 1,
            borderColor: pedColor,
            justifyContent: 'space-between',
            padding: 5,
            fontSize: 8,
        },
        section: {
            marginBottom: 3,
        },
        title: {
            fontFamily: 'Lato Bold',
            color: pedColor,
        },
        value: {
            flexDirection: 'column',
        },
        affixe: {
            textTransform: 'uppercase',
        },
        bold: {
            fontFamily: 'Lato Bold',
        },
        boldRed: {
            color: 'red',
        },
        imageSection: {
            position: 'absolute',
            left: -7,
            top: -7,
        },
        image: {
            height: 15,
            width: 'auto',
        },
    });

    const renderDistCGA = (titles) => {
        return titles
            .map((item, index) => (
                <View key={index}>
                    {item.titres.length > 0 && (
                        item.titres.map((title, idx) => (
                            <Text key={idx} style={styles.bold}>
                                {title.titreFull.includes('CGA') && (
                                    <Text style={styles.boldRed}>{title.titreFull}</Text>
                                )}
                            </Text>
                        ))
                    )}
                </View>
            ));
    };

    const renderTitlesLoof = (titles) => {
        return titles
            .filter((item) => item.titres.length > 0 && item.federation === 'LOOF')
            .map((item, index) => (
                <Text key={index} style={styles.bold}>
                    <Text>{item.titres[0].titreFull}</Text>
                </Text>
            ))
    };

    const renderTitles = (titles) => {
        return titles
            .filter((item) => item.titres.length > 0 && item.federation !== 'LOOF')
            .map((item, key) => {
                return (
                <Text key={key} style={styles.bold}>
                    {
                        item.titres.map((title, idx) => (
                            !title.titreFull.includes('CGA') && (
                                <Text key={idx} >
                                    {idx !== 0 ? ', ' : ''}
                                    {title.titreFull}
                                    {idx + 1 === item.titres.length ? (' (' + item.federation + ')') : ''}
                                </Text>
                            )
                        ))
                    }

                </Text>
            )});
    };

    return (
        <View style={styles.container}>
            <View style={styles.imageSection}>
                <Image src={DiplomasLogo} style={styles.image} />
            </View>
            <View style={styles.section}>
                <View style={styles.value}>
                    {qualification && (
                        <View style={styles.section}>
                            <Text style={styles.title}>SQR : <Text style={styles.boldRed}>{qualification}</Text></Text>
                        </View>
                    )}
                    {titreDist.length > 0 && (
                        <View style={styles.section}>
                            {renderDistCGA(titreDist)}
                        </View>
                    )}
                    {renderTitlesLoof(titres).length > 0 && (
                        <View style={styles.section}>
                            <Text style={styles.title}>Championnat LOOF :</Text>
                            {renderTitlesLoof(titres)}
                        </View>
                    )}
                    {renderTitles(allTitles).length > 0 && (
                        <View style={styles.section}>
                            <Text style={styles.title}>Autres championnats :</Text>
                            {renderTitles(allTitles)}
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};

export default Diplomas;
