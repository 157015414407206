import React from 'react';
import { useEffect, useState } from 'react';
import { getCount } from '../../components/GetCount/GetCount';
import IsLoading from '../../components/IsLoading/IsLoading';
import './Accueil.scss';
import {NavLink} from "react-router-dom";
import {statsCurrentYear} from "../../services/accueil.service";
import {forEach} from "react-bootstrap/ElementChildren";
import {Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {mappingMonth} from "../../utils/utils";
import TableTemplate from "../../components/TableTemplate/TableTemplate";

const Accueil = () => {
    const [loadingDossiers, setLoadingDossiers] = useState(true);
    const [loadingEleveurs, setLoadingEleveurs] = useState(true);
    const [loadingChats, setLoadingChats] = useState(true);
    const [nbDossiers, setNbDossiers] = useState(null);
    const [nbEleveurs, setNbEleveurs] = useState(null);
    const [nbChats, setNbChats] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [statsData, setStatsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let statsDsn = [];
            let statsPed = [];
            let statsAffixe = [];
            let statsCertif = [];
            let statsDiplomes = [];
            let statsGenetique = [];
            try {
                setIsLoading(true);
                await getCount('dossier', setNbDossiers, setLoadingDossiers);
                await getCount('eleveur', setNbEleveurs, setLoadingEleveurs);
                await getCount('chat', setNbChats, setLoadingChats);
                await Promise.all([
                    statsCurrentYear('saillie').then((result) => {
                        statsDsn = result;
                    }),
                    statsCurrentYear('reproducteur').then((result) => {
                        statsPed = result;
                    }),
                    statsCurrentYear('affixe').then((result) => {
                        statsAffixe = result;
                    }),
                    statsCurrentYear('certif').then((result) => {
                        statsCertif = result;
                    }),
                    statsCurrentYear('asso-repro-titre').then((result) => {
                        statsDiplomes = result;
                    }),
                    statsCurrentYear('gen-test').then((result) => {
                        statsGenetique = result;
                    })]
                );

                let globalStats = [];
                globalStats = mapStats(statsDsn, globalStats, 'DSN');
                globalStats = mapStats(statsPed, globalStats, 'Pedigrées');
                globalStats = mapStats(statsAffixe, globalStats, 'Affixes');
                globalStats = mapStats(statsGenetique, globalStats, 'Génétiques');
                globalStats = mapStats(statsDiplomes, globalStats, 'Diplômes');
                globalStats = mapStats(statsCertif, globalStats, 'Certificats');
                globalStats
                    .sort((a, b) => a.mois > b.mois ? 1 : -1)
                    .map((stat) => {
                    stat.mois = mappingMonth(stat.mois);
                })
                setIsLoading(false);
                setStatsData(globalStats);
            } catch (error) {
                console.log("Error fetching data: ", error);
            }
        }
        fetchData();

    }, []);

    const mapStats = (stats, globalStats, paramName) => {
        let newStatsData = [...globalStats];
        stats?.forEach(function (stat) {
            let element = globalStats && globalStats.find((data) => data.mois === stat.mois);
            if (element === undefined) {
                let newStat = {
                    mois: stat.mois,
                }
                newStat[paramName] = stat.nombre;
                newStatsData.push(newStat)
            } else {
                let indexElement = globalStats.findIndex((data) => data.mois === element.mois);
                element = {
                    ...element,
                }
                element[paramName] = stat.nombre;
                newStatsData[indexElement] = element;
            }
        });
        return newStatsData;
    };

    //Partie tableau
    const header = [
        {
            "key": "mois",
            "label": "2024"
        },
        {
            "key": "DSN",
            "label": "DSN"
        },
        {
            "key": "Pedigrées",
            "label": "Pedigrées"
        },
        {
            "key": "Affixes",
            "label": "Affixes"
        },

        {
            "key": "Génétiques",
            "label": "Génétiques"
        },
        {
            "key": "Diplômes",
            "label": "Diplômes"
        },
        {
            "key": "Certificats",
            "label": "Certificats"
        }
    ]

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Accueil</h5>
            </div>
            <div className="d-flex gap-5">
                <div className="whiteBox d-flex justify-content-around align-items-center gap-5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="50"><path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z"></path></svg>
                    <div className="d-flex flex-column align-items-center">
                        {loadingDossiers && <IsLoading />}
                        {!loadingDossiers && <h3 className='m-0 mb-1'>{nbDossiers.toLocaleString()}</h3>}
                        <p className='m-0'>Dossiers</p>
                    </div>
                </div>
                <div className="whiteBox d-flex justify-content-around align-items-center gap-5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="50"><path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z"></path></svg>
                    <div className="d-flex flex-column align-items-center">
                        {loadingEleveurs && <IsLoading />}
                        {!loadingEleveurs && <h3 className='m-0 mb-1'>{nbEleveurs.toLocaleString()}</h3>}
                        <p className='m-0'>Eleveurs</p>
                    </div>
                </div>
                <div className="whiteBox d-flex justify-content-around align-items-center gap-5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="50"><path d="M320 192h17.1c22.1 38.3 63.5 64 110.9 64c11 0 21.8-1.4 32-4v4 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V339.2L280 448h56c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-53 0-96-43-96-96V192.5c0-16.1-12-29.8-28-31.8l-7.9-1c-17.5-2.2-30-18.2-27.8-35.7s18.2-30 35.7-27.8l7.9 1c48 6 84.1 46.8 84.1 95.3v85.3c34.4-51.7 93.2-85.8 160-85.8zm160 26.5v0c-10 3.5-20.8 5.5-32 5.5c-28.4 0-54-12.4-71.6-32h0c-3.7-4.1-7-8.5-9.9-13.2C357.3 164 352 146.6 352 128v0V32 12 10.7C352 4.8 356.7 .1 362.6 0h.2c3.3 0 6.4 1.6 8.4 4.2l0 .1L384 21.3l27.2 36.3L416 64h64l4.8-6.4L512 21.3 524.8 4.3l0-.1c2-2.6 5.1-4.2 8.4-4.2h.2C539.3 .1 544 4.8 544 10.7V12 32v96c0 17.3-4.6 33.6-12.6 47.6c-11.3 19.8-29.6 35.2-51.4 42.9zM432 128a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm48 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"></path></svg>
                    <div className="d-flex flex-column align-items-center ">
                        {loadingChats && <IsLoading />}
                        {!loadingChats && <h3 className='m-0 mb-1'>{nbChats.toLocaleString()}</h3>}
                        <p className='m-0'>Chats</p>
                    </div>
                </div>
            </div>
            <div className="whiteBox d-flex flex-column align-items-start">
                <h4>Actions Rapides</h4>
                <hr style={{width: '100%'}} className='mb-5' />
                <div className="d-flex justify-content-around gap-5 w-100">
                    <div className="d-flex flex-column align-items-center rounded-circle roundButton text-center gap-2">
                        <NavLink to={`/eleveurs`}
                                 data-path="/eleveurs">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="35">
                                <path
                                    d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z"/>
                            </svg>
                            <p>Éleveurs</p>
                        </NavLink>
                    </div>
                    <div className="d-flex flex-column align-items-center rounded-circle roundButton text-center gap-2">
                        <NavLink to={`/chats`}
                                 data-path="/chats">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 576 512" height="35">
                                <path
                                    d="M320 192h17.1c22.1 38.3 63.5 64 110.9 64c11 0 21.8-1.4 32-4v4 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V339.2L280 448h56c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-53 0-96-43-96-96V192.5c0-16.1-12-29.8-28-31.8l-7.9-1c-17.5-2.2-30-18.2-27.8-35.7s18.2-30 35.7-27.8l7.9 1c48 6 84.1 46.8 84.1 95.3v85.3c34.4-51.7 93.2-85.8 160-85.8zm160 26.5v0c-10 3.5-20.8 5.5-32 5.5c-28.4 0-54-12.4-71.6-32h0c-3.7-4.1-7-8.5-9.9-13.2C357.3 164 352 146.6 352 128v0V32 12 10.7C352 4.8 356.7 .1 362.6 0h.2c3.3 0 6.4 1.6 8.4 4.2l0 .1L384 21.3l27.2 36.3L416 64h64l4.8-6.4L512 21.3 524.8 4.3l0-.1c2-2.6 5.1-4.2 8.4-4.2h.2C539.3 .1 544 4.8 544 10.7V12 32v96c0 17.3-4.6 33.6-12.6 47.6c-11.3 19.8-29.6 35.2-51.4 42.9zM432 128a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm48 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"></path>
                            </svg>
                            <p>Chats</p>
                        </NavLink>
                    </div>
                    <div className="d-flex flex-column align-items-center rounded-circle roundButton text-center gap-2">
                        <NavLink to={`/demandes`}
                                 data-path="/demandes">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="40">
                                <path
                                    d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/>
                            </svg>
                            <p>Demandes</p>
                        </NavLink>
                    </div>
                    <div className="d-flex flex-column align-items-center rounded-circle roundButton text-center gap-2">
                        <NavLink to={`/demandes/dsn`}
                                 data-path="/demandes/dsn">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="40">
                                <path
                                    d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/>
                            </svg>
                            <p>DSN</p>
                        </NavLink>
                    </div>
                    <div className="d-flex flex-column align-items-center rounded-circle roundButton text-center gap-2">
                        <NavLink to={`/demandes/pedigree`}
                                 data-path="/demandes/pedigree">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="40">
                                <path
                                    d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/>
                            </svg>
                            <p>Pédigrée</p>
                        </NavLink>
                    </div>
                    {/* <div className="d-flex flex-column align-items-center rounded-circle roundButton text-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="50"><path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z"></path></svg>
                        <p>Créer une DSN</p>
                    </div>
                    <div className="d-flex flex-column align-items-center rounded-circle roundButton text-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="50"><path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z"></path></svg>
                        <p>Créer un Eleveur</p>
                    </div>
                    <div className="d-flex flex-column align-items-center rounded-circle roundButton text-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="50"><path d="M320 192h17.1c22.1 38.3 63.5 64 110.9 64c11 0 21.8-1.4 32-4v4 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V339.2L280 448h56c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-53 0-96-43-96-96V192.5c0-16.1-12-29.8-28-31.8l-7.9-1c-17.5-2.2-30-18.2-27.8-35.7s18.2-30 35.7-27.8l7.9 1c48 6 84.1 46.8 84.1 95.3v85.3c34.4-51.7 93.2-85.8 160-85.8zm160 26.5v0c-10 3.5-20.8 5.5-32 5.5c-28.4 0-54-12.4-71.6-32h0c-3.7-4.1-7-8.5-9.9-13.2C357.3 164 352 146.6 352 128v0V32 12 10.7C352 4.8 356.7 .1 362.6 0h.2c3.3 0 6.4 1.6 8.4 4.2l0 .1L384 21.3l27.2 36.3L416 64h64l4.8-6.4L512 21.3 524.8 4.3l0-.1c2-2.6 5.1-4.2 8.4-4.2h.2C539.3 .1 544 4.8 544 10.7V12 32v96c0 17.3-4.6 33.6-12.6 47.6c-11.3 19.8-29.6 35.2-51.4 42.9zM432 128a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm48 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"></path></svg>
                        <p>Créer un Chat</p>
                    </div> */}
                </div>
            </div>

            <div className='home-tables d-flex'>
                <div className='whiteBox home-table-child'>
                    <h4>Tableaux de bord {(new Date().getFullYear())}</h4>
                    <hr style={{width: '100%'}} className='mb-5'/>
                    {statsData && <ResponsiveContainer className="chart-accueil" width="100%" height={500}>
                        <BarChart
                            width={500}
                            height={300}
                            data={statsData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="mois" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="DSN" fill="#2196f3"  />
                            <Bar dataKey="Pedigrées" fill="#f44336" />
                            <Bar dataKey="Affixes" fill="#9c27b0" />
                            <Bar dataKey="Génétiques" fill="#4caf50"  />
                            <Bar dataKey="Diplômes" fill="#ffc107"  />
                            <Bar dataKey="Certificats" fill="#ff5722" />
                        </BarChart>
                    </ResponsiveContainer>
                    }
                    {statsData &&
                        <TableTemplate
                            theadData={header}
                            tbodyData={statsData}
                            isLoading={isLoading}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default Accueil;
