import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
// import './Affixes.css';
import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import {axiosCore} from "../../utils/http-core-service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const Affixes = () => {
    const header = [
        {
            "key": "idAffixe",
            "label": "ID"
        },
        {
            "key": "nomAffixe",
            "label": "Nom de l'affixe"
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div>
                    <NavLink className='btn btn-outline-primary rounded-5' to={`/affixes/${row.idAffixe}`}>
                        Voir la fiche
                    </NavLink>
                    <button type="button" className="btn btn-outline-primary rounded-5 ms-2" onClick={() => {
                        window.open(`/affixes/${row.idAffixe}`, '_blank');
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill='black'><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                    </button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilters, setActiveFilters] = useState({});

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/affixe/list`);
            } else {
                response = await axiosCore.get(`/api/affixe/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Affixe</h5>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-3">
                    <label htmlFor="idAffixe" className="form-label">ID Affixe</label>
                    <input type="text" className="form-control" id="idAffixe" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="numAffixe" className="form-label">Numéro de l'affixe</label>
                    <input type="text" className="form-control" id="numAffixe" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="nomAffixe" className="form-label">Nom de l'affixe</label>
                    <input type="text" className="form-control" id="nomAffixe" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="beginsBy" className="form-label">Commence par</label>
                    <input type="text" className="form-control" id="beginsBy" />
                </div>
                <div className="col-md-6">
                    {Object.keys(activeFilters).length > 0 && (
                        <small className="text-muted">
                            <b>Filtre actif :&nbsp;</b>
                            {Object.entries(activeFilters).map(([key, value], index) => (
                                <span key={index}>
                                    {key === 'idAffixe' && value && `ID : ${value}`}
                                    {key === 'nomAffixe' && value && `Nom affixe : ${value}`}
                                    {key === 'numAffixe' && value && `Numéro de l'affixe : ${value}`}
                                    {key === 'beginsBy' && value && `Commençant par : ${value}`}
                                    {value && index < Object.keys(activeFilters).length - 1 && ',  '}
                                </span>
                            ))}
                        </small>
                    )}
                    {Object.keys(activeFilters).length === 0 && (
                        <small className="text-muted"><b>Filtre actif :</b> Aucun</small>
                    )}
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton' onClick={() => {
                        getData();
                        setActiveFilters({});
                        document.getElementById('idAffixe').value = '';
                        document.getElementById('nomAffixe').value = '';
                        document.getElementById('numAffixe').value = '';
                        document.getElementById('beginsBy').value = '';
                        document.getElementById('resetButton').classList.add('d-none');
                    }}>Réinitialiser</button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'idAffixe': document.getElementById('idAffixe').value.replace(/\s/g, ''), // Retire les espaces de la recherche
                            'nomAffixe': document.getElementById('nomAffixe').value.replace(/\s/g, ''),
                            'numAffixe': document.getElementById('numAffixe').value.replace(/\s/g, ''),
                            'beginsBy': document.getElementById('beginsBy').value.replace(/\s/g, ''),
                        };
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher</button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des affixes</h4>
                    </div>
                    <div className='ms-auto'>
                        <Link to="/affixes/new" className="btn btn-primary">
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="ms-1">Insérer un affixe</span>
                        </Link>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'affixes trouvés': 'affixe trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{ width: "100%" }}>
                    <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default Affixes;
