import React, { useState, useEffect } from 'react';
import {axiosCore} from "../../utils/http-core-service";

const DsnInfo = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getRequests = async () => {
        setIsLoading(true)
        try {
            const response = await axiosCore.get(`/api/demandes/latest`);
            setData(response.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    const renderStatut = (value) => {
        let statut = ''
        switch (value) {
            case 'in_progress':
                statut = 'En cours de traitement'
                break;
            case 'draft':
                statut = 'Brouillons'
                break;

            default:
                statut = value
                break;
        }

        return statut
    }

    useEffect(() => {
        getRequests();
    }, []);

    return (
        <div className='request-type'>
            <div class="card mb-4">
                <h5 class="card-header text-center">Demande n°{props.demande.id}</h5>
            </div>
            <div id='middleBox' className="d-flex justify-content-between gap-4"> {/* Bloc milieu */}
                <div className="whiteBox d-flex flex-column align-items-start gap-2"> {/* Bloc DSN */}
                    <div className="d-flex gap-2 flex-wrap">
                        <h6 className="mb-0"><b>Date:</b> {props.demande.createdAt}</h6>
                    </div>
                    <div className="d-flex gap-2 align-items-center flex-wrap">
                        <h6 className="mb-0"><b>Montant:</b> {props.demande.totalCoast}</h6>
                    </div>
                    <div className="d-flex gap-2 align-items-center flex-wrap">
                        <h6 className="mb-0"><b>Pièce(s) jointe(s) :</b> Aucune piece jointe pour cette demande.	</h6>
                    </div>
                </div>
                <div className="whiteBox d-flex flex-column align-items-start gap-2"> {/* Bloc Pedigree */}
                    <div className="d-flex gap-2 flex-wrap">
                        <h6 className="mb-0"><b>Statut :</b> <div className="badge bg-primary">{renderStatut(props.demande.status)}</div></h6>
                        {/* <div className="badge bg-primary">*TODO*</div> */}
                    </div>
                    <div className="d-flex gap-2 align-items-center flex-wrap">
                        <h6 className="mb-0"><b>Facture :</b></h6>
                    </div>
                </div>
            </div>

            <div class="card mb-5">
                <h5 class="card-header text-center">CRÉATION DSN</h5>
                <div class="card-body">
                    <div id='bottomBox' className="">
                        <div className='form-line'>
                            <div className='row form-line-item'>
                                <h6 className='col-6'><b>Propriétaire de la femelle :</b></h6>
                                <div className='col-6'>
                                    <input className='form-control' value={`RIBEIRO-FLAHAUT Diolinda`} style={{width: '300px'}} disabled/>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <h6 className='col-6'><b>Affixe du propriétaire :</b></h6>
                                <div className='col-6'>
                                    <select className='form-select' style={{width: '300px'}}>
                                        <option>De la saude</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <h6><b>Date réception formulaire :</b></h6>
                                        </div>
                                        <div className='col-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type='date' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <h6><b>Date de saillie déclarée :</b></h6>
                                        </div>
                                        <div className='col-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type='date' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <h6 className='col-6'><b>Propriétaire de la femelle :</b></h6>
                                <div className='col-6'>
                                    <input className='form-control' value={`RIBEIRO-FLAHAUT Diolinda`} style={{width: '300px'}} disabled/>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <h6 className='col-6'><b>Etalon :</b></h6>
                                <div className='col-6'>
                                    <textarea className='form-control' style={{width: '300px'}}></textarea>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <h6 className='col-6'><b>Femelle :</b></h6>
                                <div className='col-6'>
                                    <textarea className='form-control' style={{width: '300px'}}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-5">
                <h5 class="card-header text-center"></h5>
                <div class="card-body">
                    <div id='bottomBox' className="">
                        <div className='form-line'>
                            <div className='row form-line-item'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <h6><b>Date de naissance :</b></h6>
                                        </div>
                                        <div className='col-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type='date' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <h6><b>Durée de gestation calculée :</b></h6>
                                        </div>
                                        <div className='col-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type='text' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <h6><b>Nb de chatons :</b></h6>
                                        </div>
                                        <div className='col-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type='text' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <h6><b>Nb total de chatons :</b></h6>
                                        </div>
                                        <div className='col-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type='text' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <h6><b>Nb de chatons décédés :</b></h6>
                                        </div>
                                        <div className='col-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type='text' className='form-control'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-line-item'>
                                <h6 className='col-6'><b>Commentaire DSN :</b></h6>
                                <div className='col-6'>
                                    <textarea className='form-control' style={{width: '500px'}}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DsnInfo;
