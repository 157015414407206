import React, {useEffect, useState} from 'react';
import TableTemplate from '../TableTemplate/TableTemplate';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {axiosCore} from "../../utils/http-core-service";

const StatistiquePedMois = () => {
    const todayYear = new Date().getFullYear();
    const getYearsPeriod = () => {
        const howManyYearsWeWant = 5;
        let years = []

        for (let i = 0; i < howManyYearsWeWant; i++) {
            years.push((todayYear - i).toString());
        }

        console.log(years)
        return years;
    };

    const years = getYearsPeriod();

    const header = [
        {
            "key": "seuil_depasse",
            "label": "Seuil Dépassé"
        },
        {
            "key": "pedl01",
            "label": "Janvier"
        },
        {
            "key": "pedl02",
            "label": "Février"
        },
        {
            "key": "pedl03",
            "label": "Mars"
        },
        {
            "key": "pedl04",
            "label": "Avril"
        },
        {
            "key": "pedl05",
            "label": "Mai"
        },
        {
            "key": "pedl06",
            "label": "Juin"
        },
        {
            "key": "pedl07",
            "label": "Juillet"
        },
        {
            "key": "pedl08",
            "label": "Août"
        },
        {
            "key": "pedl09",
            "label": "Septembre"
        }
        ,
        {
            "key": "pedl10",
            "label": "Octobre"
        },
        {
            "key": "pedl11",
            "label": "Novembre"
        },
        {
            "key": "pedl12",
            "label": "Décembre"
        }
    ]

    // Couleurs de chart
    const chartBarColors = [
        "#2094fa",
        "#457BD0",
        "#6A63A7",
        "#904A7D",
        "#B53153",
        "#DA192A",
        "#ff0000",
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Map les données du back en données utilisables par les charts
    const mapApiDataToChartsFormat = (data) => {
        const map = [
            {name: "Janvier", value: []},
            {name: "Février", value: []},
            {name: "Mars", value: []},
            {name: "Avril", value: []},
            {name: "Mai", value: []},
            {name: "Juin", value: []},
            {name: "Juillet", value: []},
            {name: "Août", value: []},
            {name: "Septembre", value: []},
            {name: "Octobre", value: []},
            {name: "Novembre", value: []},
            {name: "Décembre", value: []},
        ];

        data.map((item) => {
            map[0].value.push(item.pedl01);
            map[1].value.push(item.pedl02);
            map[2].value.push(item.pedl03);
            map[3].value.push(item.pedl04);
            map[4].value.push(item.pedl05);
            map[5].value.push(item.pedl06);
            map[6].value.push(item.pedl07);
            map[7].value.push(item.pedl08);
            map[8].value.push(item.pedl09);
            map[9].value.push(item.pedl10);
            map[10].value.push(item.pedl11);
            map[11].value.push(item.pedl12);
        })

        return map;
    }

    // Au chargement de la page, récupère les données de l'année en cours
    useEffect(() => {
        getStats(todayYear);
    }, []);

    const getStats = async (year) => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/stats-nbr-ped-sent-per-month/${year}`;
        try {
            const response = await axiosCore.get(url);
            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <h4 className='stat-title'>Nombre de nouveaux pédigrés envoyés par mois</h4>

            <div className='stat-filter d-flex justify-content-end'>
            <select defaultValue={todayYear} className="form-select form-select-sm me-2" style={{maxWidth: '15rem'}} onChange={e => getStats(e.target.value)} >
                {years.map((year, index) => (
                <option key={index} value={year}>
                    {year}
                </option>
                ))}
            </select>
            </div>
            {(data && data.length > 0 && !isLoading) && (
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={mapApiDataToChartsFormat(data)}>
                        <XAxis dataKey={'name'}/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="4"/>
                        <Tooltip/>
                        <Bar name={"0+ jours de délai"} dataKey={'value[0]'} stackId="a" fill={chartBarColors[0]} />
                        <Bar name={"15+ jours de délai"} dataKey={'value[1]'} stackId="a" fill={chartBarColors[1]} />
                        <Bar name={"30+ jours de délai"} dataKey={'value[2]'} stackId="a" fill={chartBarColors[2]} />
                        <Bar name={"40+ jours de délai"} dataKey={'value[3]'} stackId="a" fill={chartBarColors[3]} />
                        <Bar name={"50+ jours de délai"} dataKey={'value[4]'} stackId="a" fill={chartBarColors[4]} />
                        <Bar name={"60+ jours de délai"} dataKey={'value[5]'} stackId="a" fill={chartBarColors[5]} />
                        <Bar name={"100+ jours de délai"} dataKey={'value[6]'} stackId="a" fill={chartBarColors[6]} />
                    </BarChart>
                </ResponsiveContainer>
            )}
            <br/>
            <TableTemplate
                theadData={header}
                tbodyData={data}
                isLoading={isLoading}
            />
        </div>
    );
}

export default StatistiquePedMois;
