import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../components/IsLoading/IsLoading';
import Update from '../../components/DataManagement/Update';
import {axiosCore} from "../../utils/http-core-service";

const FicheLabo = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [labo, setLabo] = useState(null);
    const [error, setError] = useState(null);
    const [isModifyPopupOpen, setModifyPopupOpen] = useState(false);

    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        try {
            const response = await axiosCore.get(`/api/labo/${id}`);
            setLabo(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log("Error fetching data: ", error);
            setIsLoading(false);
            setError(error);
        }
    }

    const openModifyPopup = () => {
        setModifyPopupOpen(true);
    };

    const closeModifyPopup = () => {
        setModifyPopupOpen(false);
        fetchData();
    };

    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && labo &&
            <div>
                <div className='fil-arenae d-flex align-items-center mb-4'>
                    <h5 className='mb-0'>
                        <NavLink className='link' to={`/expositions/expositions`}>Labo</NavLink>
                    </h5>
                    <div className='mx-2'> &gt; </div>
                    <h5 className='mb-0'>Fiche Labo</h5>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div id='topBox' className="whiteBox">
                        <div className="d-flex flex-column gap-2 align-items-start">
                            <h5><b>Nom d'labo / N° d'labo : </b> {labo.nom}</h5>
                            <h6 className='mb-1'><b>Création en date du : </b> {labo.createDate}</h6>
                            <h6 className='mb-0'><b>Modification en date du : </b> {labo.updateDate}</h6>
                        </div>
                    </div>

                    <div className='row mb-4'>
                        <div className='col-lg-12'>
                            <div className="card">
                                <h5 className="card-header"></h5>
                                <div className="card-body">
                                    <div id='bottomBox' className=""> {/* Bloc bas */}
                                        <div className='form-line'>
                                            <div className='row form-line-item'>
                                                <h6 className='col-4'><b>Nom :	</b></h6>
                                                <div className='col-8'>{labo.nom}</div>
                                            </div>
                                            <div className='row form-line-item'>
                                                <h6 className='col-4'><b>Sigle : </b></h6>
                                                <div className='col-8'>{labo.sigle}</div>
                                            </div>
                                            <div className='row form-line-item'>
                                                <h6 className='col-4'><b>Adresse :	</b></h6>
                                                <div className='col-8'>{labo.adresse}</div>
                                            </div>
                                            <div className='row form-line-item'>
                                                <h6 className='col-4'><b>Téléphone : </b></h6>
                                                <div className='col-8'>{labo.tel}</div>
                                            </div>
                                            <div className='row form-line-item'>
                                                <h6 className='col-4'><b>Email :</b></h6>
                                                <div className='col-8'>{labo.mail}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='d-flex gap-3 justify-content-end mb-3'>
                        <button className='btn btn-primary btn-danger btn-sm'>Supprimer</button>
                        <button className='btn btn-primary btn-sm' onClick={() => {
                            openModifyPopup();
                        }}>Modifier</button>
                    </div>
            </div>
            }
            {isModifyPopupOpen && (
                    <Update
                        onClose={closeModifyPopup}
                        title={"Modifier le labo " + labo.nom}
                        modifyDatas={[
                            {"key": "nom", "label": "Nom", "required": true,  "value": labo.nom,},
                            {"key": "sigle", "label": "Sigle", "required": true, "value": labo.sigle,},
                            {"key": "adresse", "label": "Adresse", "required": true, "value": labo.adresse,},
                            {"key": "telephone", "label": "Téléphone", "required": true, "value": labo.tel,},
                            {"key": "email", "label": "Email", "required": true, "value": labo.mail,}
                        ]}
                        modifyRoute={`api/labo/update/${labo.id}`}
                    />
                )}
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger l'éleveur ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                </div>
            }
        </div>
    );
}

export default FicheLabo;
