import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useState, useEffect } from 'react';
import {axiosCore} from "../../utils/http-core-service";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons'


const Chats = () => {
    const header = [
        {
            "key": "idChat",
            "label": "ID"
        },
        {
            "key": "nomChat",
            "label": "Nom"
        },
        {
            "key": "raceChat",
            "label": "Race"
        },
        {
            "key": "robeChat",
            "label": "Robe"
        },
        {
            "key": "identificationChat",
            "label": "Identification"
        },
        {
            "key": "numOrigine",
            "label": "Numéro d'origine"
        },
        {
            "key": "qualification",
            "label": "Qualification"
        },
        {
            "key": "titre",
            "label": "Titre"
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div>
                    <button type="button" className="btn btn-outline-primary rounded-5" onClick={() => {
                        window.open(`/chats/${row.idChat}`, '_self');
                    }}>
                        Voir la fiche
                    </button>
                    <button type="button" className="btn btn-outline-primary rounded-5 ms-2" onClick={() => {
                        window.open(`/chats/${row.idChat}`, '_blank');
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill='black'><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                    </button>
                </div>
            }
        }
    ]

    const listSexes = ["XXXXX", "Mâle", "Femelle", "Mâle neutre", "Femelle neutre"];

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilters, setActiveFilters] = useState({});
    const [raceSelection, setRaceSelection] = useState([]);
    const [races, setRaces] = useState([]);
    const [robeSelection, setRobeSelection] = useState([]);
    const [robes, setRobes] = useState([]);
    const [yeux, setYeux] = useState([]);

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/reproducteur/list`);
            } else {
                console.log(search)
                response = await axiosCore.get(`/api/reproducteur/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'raceChat' && search[key].length > 0) { // If the key is 'raceEleveeEleveur' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'robeChat' && search[key].length > 0) { // If the key is 'robeEleveeEleveur' and the value is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getRaces = async () => {
        try {
            const response = await axiosCore.get(`/api/race/list`);
            setRaces(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getRobes = async () => {
        try {
            const response = await axiosCore.get(`/api/robe/list`);
            setRobes(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getYeux = async () => {
        try {
            const response = await axiosCore.get(`/api/yeux/list`);
            setYeux(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
        getRaces();
        getRobes();
        getYeux();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Chats</h5>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-3">
                    <label htmlFor="idChat" className="form-label">ID Chat</label>
                    <input type="text" className="form-control" id="idChat"  inputMode="numeric" onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                    }}/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="nomChat" className="form-label">Nom</label>
                    <input type="text" className="form-control" id="nomChat"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="identification" className="form-label">Numéro d'identification</label>
                    <input type="text" className="form-control" id="identification"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="numOrigine" className="form-label">Numéro d'origine</label>
                    <input type="text" className="form-control" id="numOrigine"/>
                </div>
                <div className="col-md-3">
                    <label htmlFor="raceChat" className="form-label">Race</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="raceChat"
                        placeholder={'Rechercher une race'}
                        options={races.map((value) => value.intituleRa)}
                        onChange={setRaceSelection}
                        selected={raceSelection}
                    />
                </div>
                <div className="col-md-3">
                    <label htmlFor="robeChat" className="form-label">Robe</label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="robeChat"
                        placeholder={'Rechercher une robe'}
                        options={robes.map((value) => value.intituleRo)}
                        onChange={setRobeSelection}
                        selected={robeSelection}
                    />
                </div>
                <div className="col-md-3 d-flex flex-column">
                    <label htmlFor="pedigreesNonSigne" className="form-label">Pedigrees non signés</label>
                    <input type="checkbox" className="form-check-input" id="pedigreesNonSigne" />
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    {Object.keys(activeFilters).length > 0 && (
                        <small className="text-muted">
                            <b>Filtre actif :&nbsp;</b>
                            {Object.entries(activeFilters).map(([key, value], index) => (
                                <span key={index}>
                                    {key === 'idChat' && value && `ID : ${value}`}
                                    {key === 'nomChat' && value && `Nom : ${value}`}
                                    {key === 'identification' && value && `Numéro d'identification : ${value}`}
                                    {key === 'numOrigine' && value && `Numéro d'origine : ${value}`}
                                    {key === 'pedigreesNonSigne' && value && `Pedigrees non signés`}
                                    {key === 'raceChat' && value.length > 0 && `Race(s) : ${races.filter((value1) => value.includes(value1.id)).map((value2) => value2.codeRa).join(', ')}`} {/* Get the race code from race ID */}
                                    {key === 'robeChat' && value.length > 0 && `Robe(s) : ${robes.filter((value1) => value.includes(value1.id)).map((value2) => value2.intituleRo).join(', ')}`} {/* Get the robe name from robe ID */}
                                    {value && index < Object.keys(activeFilters).length - 1 && ',  '}
                                </span>
                            ))}
                        </small>
                    )}
                    {Object.keys(activeFilters).length === 0 && (
                        <small className="text-muted"><b>Filtre actif :</b> Aucun</small>
                    )}
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton'
                            onClick={() => {
                                getData();
                                setActiveFilters({});
                                document.getElementById('idChat').value = '';
                                document.getElementById('nomChat').value = '';
                                document.getElementById('identification').value = '';
                                document.getElementById('numOrigine').value = '';
                                document.getElementById('pedigreesNonSigne').checked = false;
                                setRaceSelection([]); // Reset the race selection
                                setRobeSelection([]); // Reset the robe selection
                                document.getElementById('resetButton').classList.add('d-none');
                            }}>Réinitialiser
                    </button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'idChat': document.getElementById('idChat').value.replace(/\s/g, ''), // Retire les espaces de la recherche
                            'nomChat': document.getElementById('nomChat').value.trim(), // Retire les espaces de début et de fin de la string
                            'identification': document.getElementById('identification').value.replace(/\s/g, ''),
                            'numOrigine': document.getElementById('numOrigine').value.trim(),
                            'pedigreesNonSigne': document.getElementById('pedigreesNonSigne').checked,
                            'raceChat': races.filter((value) => raceSelection.includes(value.intituleRa)).map((value) => value.id), // Get the race ID from the race name
                            'robeChat': robes.filter((value) => robeSelection.includes(value.intituleRo)).map((value) => value.id), // Get the robe ID from the robe name
                        };
                        console.log(search);
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher
                    </button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des chats</h4>
                    </div>
                    <div className='ms-auto'>
                        <Link to="/chats/new" className="btn btn-primary">
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="ms-1">Ajouter un nouveau chat</span>
                        </Link>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{
                                width: "30px",
                                height: "30px",
                                textAlign: "center",
                                backgroundColor: "rgba(217, 217, 217, 0.50)",
                                border: "0",
                                borderRadius: ".25rem"
                            }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }}/>
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'chats trouvés' : 'chat trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{width: "100%"}}>
                    <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default Chats;
