import {axiosCore} from "../../utils/http-core-service";

export const getCount = async (route, setCount, setIsLoading) => {
    try {
        setIsLoading(true);
        const response = await axiosCore.get(`/api/count/${route}`);
        setCount(response.data);
        if (response.data === -1) {
            return;
        }
        setIsLoading(false);
    } catch (error) {
        console.log("Error fetching data: ", error);
    }
}
