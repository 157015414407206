import React from 'react';
import TableTemplate from '../../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../../components/Pagination/Pagination.js';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {Typeahead} from "react-bootstrap-typeahead";
import {axiosCore} from "../../../utils/http-core-service";
import {forEach} from "react-bootstrap/ElementChildren";

const Expo = () => {
    const header = [
        {
            "key": "id",
            "label": "CODE PAYS",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    {row.pays?.codePays}
                </div>
            }
        },
        {
            "key": "numExpo",
            "label": "N° EXPO",
        },
        {
            "key": "dateDebut",
            "label": "DATE DE DÉBUT	",
        },
        {
            "key": "dateFin",
            "label": "DATE DE FIN",
        },
        {
            "key": "ville",
            "label": "VILLE",
        },
        {
            "key": "club",
            "label": "CLUB",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    {row.clubs.map(r => r.intituleCl).join(' - ')}
                </div>
            }
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div className="d-flex gap-2 align-items-center justify-content-center">
                    <NavLink className='btn btn-outline-primary rounded-5' to={`/expositions/expositions/${row.id}`}>
                        Voir la fiche
                    </NavLink>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [villes, setVilles] = useState([]);
    const [pays, setPays] = useState([]);
    const [villeSelection, setVilleSelection] = useState([]);
    const [paysSelection, setPaysSelection] = useState([]);

    const getPays = async () => {
        try {
            const response = await axiosCore.get(`/api/pays/list`);
            setPays(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getVille = async (data) => {
        let cities = [];
        data.forEach((expo) => {
            if(expo.ville !== null && !cities.includes(expo.ville)) {
                cities.push(expo.ville)
            }
        })
        setVilles(cities);
    };

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/expo/list`);
            } else {
                response = await axiosCore.get(`/api/expo/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            getVille(response.data)
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData(null)
        getPays();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    const handleSearch = (event) => {
        event.preventDefault();
        // getAll(search)
    }

    const reinitSearch = () => {
        // setSearch('')
        // getAll()
        getData(null)
    }

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-3'>
                <h5 className='mb-0'>Expositions</h5>
            </div>
                <form className="row g-3 whiteBox searchBox">
                    <div className="col-md-3">
                        <label htmlFor="numExpo" className="form-label">Numéro de l'exposition</label>
                        <input type="text" className="form-control" id="numExpo"/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="dateExpo" className="form-label">Date</label>
                        <input type="date" className="form-control" id="dateExpo"/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="villeExpo" className="form-label">Ville</label>
                        <Typeahead
                            emptyLabel={"Aucune ville trouvée"}
                            id="villeExpo"
                            labelKey="villeExpo"
                            placeholder={'Rechercher une ville'}
                            options={villes.map((value) => value)}
                            onChange={setVilleSelection}
                            selected={villeSelection}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="paysExpo" className="form-label">Pays</label>
                        <Typeahead
                            emptyLabel={"Aucun pays trouvé"}
                            placeholder={'Rechercher un pays'}
                            id="basic-typeahead-single"
                            labelKey="paysExpo"
                            options={pays.map((value) => value.intitulePays)}
                            onChange={setPaysSelection}
                            selected={paysSelection}
                        />
                    </div>
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6 d-flex gap-3 justify-content-end">
                        <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton'
                                onClick={() => {
                                    getData();
                                    document.getElementById('numExpo').value = '';
                                    document.getElementById('dateExpo').value = '';
                                    setVilleSelection([]);
                                    setPaysSelection([]);
                                    document.getElementById('resetButton').classList.add('d-none');
                                }}>Réinitialiser
                        </button>
                        <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                            event.preventDefault();
                            const search = {
                                'numExpo': document.getElementById('numExpo').value.replace(/\s/g, ''), // Retire les espaces de la recherche
                                'dateExpo': document.getElementById('dateExpo').value.replace(/\s/g, ''),
                                'villeExpo': villes.filter((value) => value === villeSelection[0])[0],
                                'paysExpo': pays.filter((value) => value.intitulePays === paysSelection[0])[0]?.idPays,
                            };
                            getData(search);
                            document.getElementById('resetButton').classList.remove('d-none');
                        }}>Rechercher
                        </button>
                    </div>
                </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des expositions</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary">Insérer</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{
                                width: "30px",
                                height: "30px",
                                textAlign: "center",
                                backgroundColor: "rgba(217, 217, 217, 0.50)",
                                border: "0",
                                borderRadius: ".25rem"
                            }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }}/>
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'expositions trouvées' : 'exposition trouvée'}</p>
                    </div>

                </div>
                <div className="p-2" style={{width: "100%"}}>
                    <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default Expo;
