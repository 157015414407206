import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';
import { axiosCore } from "../../../utils/http-core-service";
import Delete from '../../../components/Delete/Delete';

const FicheMaladie = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [maladie, setMaladie] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosCore.get(`/api/maladie/${id}`);
                setMaladie(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log("Error fetching data: ", error);
                setIsLoading(false);
                setError(error);
            }
        }
        fetchData();
    }, [id]);
    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && maladie &&
                <div>
                    <div className="fil-arenae d-flex align-items-center mb-3 justify-content-between">
                        <div className="d-flex align-items-center">
                            <h5 className='mb-0'>
                                <NavLink className='link' to={`/referentiels/maladies`}>Maladie</NavLink>
                            </h5>
                            <div className='mx-2'> &gt; </div>
                            <h5 className='mb-0'>Fiche Maladie</h5>
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                            <Delete
                                id={id}
                                deleteRoute={`/api/maladie/delete/${id}`}
                                redirectTo='/referentiels/maladies'
                                context={'la maladie'}
                                content={`${maladie.nom}`}
                            />
                            <NavLink className='btn btn-outline-primary' to={`/referentiels/maladies/${id}/edit`}>Modifier</NavLink>
                        </div>
                    </div>
                    <div className='fil-arenae d-flex align-items-center mb-3'>

                    </div>
                    <div className="d-flex flex-column gap-2">
                        <div className='row mb-4'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <h5 className="card-header"></h5>
                                    <div className="card-body">
                                        <div id='bottomBox' className=""> {/* Bloc bas */}
                                            <div className='form-line'>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Nom :	</b></h6>
                                                    <div className='col-8'>{maladie.nom}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Nom Anglais :	</b></h6>
                                                    <div className='col-8'>{maladie.nomEn}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Sigle de la maladie :	</b></h6>
                                                    <div className='col-8'>{maladie.sigle}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Mode de transmission :	</b></h6>
                                                    <div className='col-8'>{maladie.modeTrans}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Races concernées :	</b></h6>
                                                    <div className='col-8'>{maladie?.races?.map((race, index) => {
                                                        return <span key={index}>{race.intituleRa} <br /></span>
                                                    })}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Sigle gène :</b></h6>
                                                    <div className='col-8'>{maladie.sigleGene}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Sigle de la mutation :	</b></h6>
                                                    <div className='col-8'>{maladie.sigleMutation}</div>
                                                </div>
                                                <div className='row form-line-item'>
                                                    <h6 className='col-4'><b>Laboratoire(s) :	</b></h6>
                                                    <div className='col-8'>{maladie?.labo?.map((lab, index) => {
                                                        return <span key={index}>{lab.nom} <br /></span>
                                                    })}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger l'éleveur ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                </div>
            }
        </div>
    );
}

export default FicheMaladie;
