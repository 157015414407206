import {axiosCore as http} from "../utils/http-core-service";

const create = async (data) => {
     return http.post(`/api/reproducteur`, data)
        .then(response => response.data)
        .catch(console.error);
}

const update = async (id, data) => {
    // Wait for update user story
}

const getOneById = async (id) => {
    return http.get(`/api/reproducteur/one/${id}`)
        .then(response => response.data)
        .catch(console.error);
}

const getSexeList = async () => {
    return http.get(`/api/reproducteur/sexe/list`)
        .then(response => response.data)
        .catch(console.error);
}

const getBreedList = async () => {
    return http.get(`/api/race/list`)
        .then(
            response => response.data.map(breed => ({id: breed.id, designation: breed.intituleRa}))
        )
        .catch(console.error);
}

const getCoatList = async () => {
    return http.get(`/api/robe/list`)
        .then(
            response => response.data.map(coat => ({id: coat.id, designation: coat.intituleRo}))
        )
        .catch(console.error);
}

const getEyeList = async () => {
    return http.get(`/api/yeux/list`)
        .then(
            response => response.data.map(eye => ({id: eye.idYeux, designation: eye.intituleYeux}))
        )
        .catch(console.error);
}

const getBreedDefault = async () => {
    return http.get(`/api/race-default/list`)
        .then(
            response => response.data.map(RD => ({id: RD.id, breedID: RD.idRa, coatID: RD.idRo, eyeID: RD.idYe}))
        )
        .catch(console.error);
}

const getBreedMention = async () => {
    return http.get(`/api/race-mention/list`)
        .then(
            response => response.data.map(RM => ({id: RM.id, mention: RM.mention, mentionGroupID: RM.group, breeds: RM.races}))
        )
        .catch(console.error);
}

const queryByName = async (type, query) => {
    const params = new FormData();
    params.set('query', query);
    return http.get(`/api/reproducteur/query/${type}?${new URLSearchParams(params).toString()}`)
        .then(response => response.data)
        .catch(console.error);
}

const queryBy = async (type, query, sex) => {
    const params = new FormData();
    params.set('query', query);
    params.set('sex', sex);
    return http.get(`/api/reproducteur/query-by/${type}?${new URLSearchParams(params).toString()}`)
        .then((response) => response.data)
        .catch(console.error);
}

export {getOneById, getCoatList, getEyeList, getBreedDefault, getBreedMention, getSexeList, getBreedList, queryByName, create, update, queryBy};
