import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';

const PorteeCatteryBreeder = ({ breederInfos, porteeInfos, catInfos }) => {
  const portee = (!isEmpty(porteeInfos) && porteeInfos) || '';
  const affixe = (!isEmpty(breederInfos) && breederInfos.affixe) || '';
  const breeder = (!isEmpty(breederInfos) &&
    [
      breederInfos.prenomEl + ' ' + breederInfos.nomEl,
      (breederInfos.adresseEl && breederInfos.adresseEl) || null,
      ((breederInfos.codePostalEl && breederInfos.villeEl) && `${breederInfos.codePostalEl} ${breederInfos.villeEl}`) || null,
    ]) || '';
  const owner = (!isEmpty(breederInfos) &&
    [
      ((breederInfos.prenomOwner && breederInfos.nomOwner) && `${breederInfos.prenomOwner} ${breederInfos.nomOwner}`) || null,
      (breederInfos.adresseOwner && breederInfos.adresseOwner) || null,
      ((breederInfos.codePostalOwner && breederInfos.villeOwner) && `${breederInfos.codePostalOwner} ${breederInfos.villeOwner}`) || null,
    ]) || '';
  const mentions = (!isEmpty(catInfos) && catInfos.mentionPedigree) || '';
  const commentairePedigree = (!isEmpty(catInfos) && catInfos.commentairePedigree) || '';

  useEffect(() => {
    console.log('portee:', portee);
  }, [portee]);

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={{ ...styles.title, width: 'auto' }}>
            <Text>Portée :</Text>
          </View>
          <View style={styles.value}>
            {portee && portee.map((chaton, index) => (
              <Text>{chaton.nb} {chaton.sexeChat} {chaton.codeEMS}</Text>
            ))}
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.title}>
            <Text>Chatterie / Cattery :</Text>
          </View>
          <View style={{ ...styles.value, textTransform: 'uppercase' }}>
            <Text>{affixe}</Text>
          </View>
        </View>
        {(breeder && owner) ? (
          <>
            <View style={styles.row}>
              <View style={styles.title}>
                <Text>Eleveur / Breeder :</Text>
              </View>
              <View style={styles.value}>
                {breeder.map((info, index) => (
                  <Text key={index}>{info}</Text>
                ))}
              </View>
            </View>
            {owner[0] ? (
              <View style={styles.row}>
                <View style={styles.title}>
                  <Text>Propriétaire / Owner :</Text>
                </View>
                <View style={styles.value}>
                  {owner.map((info, index) => (
                    <Text key={index}>{info}</Text>
                  ))}
                </View>
              </View>
            ) : null}
          </>
        ) : null}
      </View>
      <View style={styles.mentions}>
        <View style={styles.row}>
          <View style={styles.value}>
            {mentions ? (
              <Text style={styles.mentions}>{mentions}</Text>
            ) : commentairePedigree ? (
              <Text>{commentairePedigree}</Text>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PorteeCatteryBreeder;

const styles = StyleSheet.create({
  container: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {
    marginBottom: 10,
    width: '50%',
  },
  mentions: {
    width: '100%',
    position: 'absolute',
    bottom: -25,
  },
  row: {
    flexDirection: 'row',
  },
  title: {
    fontFamily: 'Lato Bold',
    marginRight: 10,
    fontSize: 8,
    width: '40%',
  },
  value: {
    fontSize: 8,
    marginRight: 10,
  },
});
