import React from 'react';
import TableTemplate from '../../components/TableTemplate/TableTemplate.js';
import Pagination from '../../components/Pagination/Pagination.js';
import { useState, useEffect } from 'react';
import { axiosCore } from "../../utils/http-core-service";
import { NavLink, useParams } from 'react-router-dom';


const Dossiers = () => {
    const header = [
        {
            "key": "id",
            "label": "ID"
        },
        {
            "key": "numDossier",
            "label": "N° Dossier"
        },
        {
            "key": "numPortee",
            "label": "N° Portée"
        },
        {
            "key": "dateNaissance",
            "label": "Date de naissance de la portée"
        },
        {
            "key": "dateDemandePedig",
            "label": "Date de demande de pedigree"
        },
        {
            "key": "btn",
            "label": "Action",
            "render": (value, row, index) => {
                return <div>
                    <button type="button" className="btn btn-outline-primary rounded-5" onClick={() => {
                        window.open(`/dossiers/${row.id}`, '_self');
                    }}>
                        Voir la fiche
                    </button>
                    <button type="button" className="btn btn-outline-primary rounded-5 ms-2" onClick={() => {
                        window.open(`/dossiers/${row.id}`, '_blank');
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={15} fill='black'><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
                    </button>
                </div>
            }
        }
    ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilters, setActiveFilters] = useState({});
    const [isClickedDisabled, setIsClickedDisabled] = useState(true);

    const toggleClickedCheckbox = () => {
        setIsClickedDisabled(prevState => !prevState);
    };

    const getData = async (search = null) => {
        setIsLoading(true);
        try {
            let response = null;
            if (!search) {
                response = await axiosCore.get(`/api/saillie/list`);
            } else {
                response = await axiosCore.get(`/api/saillie/search/${JSON.stringify(search)}`);
            }
            setData(response.data);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.length / limit));
            setCurrentPage(1);
            if (search) {
                const filteredSearch = Object.keys(search).reduce((acc, key) => { // Remove empty keys
                    if (search[key]) { // If the key is not empty
                        acc[key] = search[key]; // Add it to the accumulator
                    } else if (key === 'clicked' && search[key] === false) { // If the key is 'clicked' and the value is false
                        acc[key] = search[key]; // Add it to the accumulator
                    }
                    return acc; // Return the accumulator
                }, {}); // Initialize the accumulator as an empty object
                setActiveFilters(filteredSearch); // Set the active filters
            } else {
                setActiveFilters({}); // Reset the active filters
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTotalPages(Math.ceil(data.length / limit));
    };

    return (
        <div className="Content">
            <div className="fil-arenae d-flex align-items-center mb-3 justify-content-between">
                <div className="d-flex align-items-center">
                    <h5 className="mb-0">
                        Dossiers
                    </h5>
                </div>
                <div className="d-flex align-items-center gap-2">
                    <NavLink className="btn btn-outline-primary" to="/dossiers/services/couleurs-chatons">Service couleurs chatons</NavLink>
                </div>
            </div>
            <form className="row g-3 whiteBox searchBox">
                <div className="col-md-3">
                    <label htmlFor="idDossier" className="form-label">ID Dossier</label>
                    <input type="text" className="form-control" id="idDossier" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="numDossier" className="form-label">Numéro de dossier</label>
                    <input type="text" className="form-control" id="numDossier" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="numDemande" className="form-label">Numéro de demande</label>
                    <input type="text" className="form-control" id="numDemande" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="numTemporaire" className="form-label">Numéro temporaire</label>
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">ND-</span>
                        <input type="text" className="form-control" id="numTemporaire" />
                    </div>
                </div>
                <div className="col-md-3">
                    <label htmlFor="numPortee" className="form-label">Numéro de portée</label>
                    <input type="text" className="form-control" id="numPortee" />
                </div>
                <div className="col-md-3 d-flex flex-column">
                    <label htmlFor="dossierNonSigne" className="form-label">Dossiers non signés</label>
                    <input className="form-check-input" type="checkbox" id="dossierNonSigne" />
                </div>
                <div className="col-md-6">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="clicked" disabled={isClickedDisabled} />
                        <label className="form-check-label" htmlFor="clicked">
                            Dossier non cliqués *TODO* (fonctionne à moitié)
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="activateClicked" onClick={toggleClickedCheckbox} />
                        <label className="form-check-label" htmlFor="activateClicked">
                            {isClickedDisabled ? "Activer " : "Désactiver "}
                            le filtre "Dossier non cliqués"
                        </label>
                    </div>
                </div>
                <div className="col-md-6">
                    {Object.keys(activeFilters).length > 0 && (
                        <small className="text-muted">
                            <b>Filtre actif :&nbsp;</b>
                            {Object.entries(activeFilters).map(([key, value], index) => (
                                <span key={index}>
                                    {key === 'idDossier' && value && `ID Dossier : ${value}`}
                                    {key === 'numDossier' && value && `Numéro de dossier : ${value}`}
                                    {key === 'numDemande' && value && `Numéro de demande : ${value}`}
                                    {key === 'numTemporaire' && value && `Numéro temporaire : ${value}`}
                                    {key === 'numPortee' && value && `Numéro de portée : ${value}`}
                                    {key === 'dossierNonSigne' && value && 'Dossiers non signés'}
                                    {key === 'clicked' && value === false && !isClickedDisabled && 'Dossier cliqués'}
                                    {key === 'clicked' && value === true && !isClickedDisabled && 'Dossier non cliqués'}
                                    {value && index < Object.keys(activeFilters).length - 1 && ',  '}
                                </span>
                            ))}
                        </small>
                    )}
                    {Object.keys(activeFilters).length === 0 && (
                        <small className="text-muted"><b>Filtre actif :</b> Aucun</small>
                    )}
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end">
                    <button type="button" className="btn btn-outline-primary w-50 d-none" id='resetButton' onClick={() => {
                        getData();
                        setActiveFilters({});
                        document.getElementById('idDossier').value = '';
                        document.getElementById('numDossier').value = '';
                        document.getElementById('numDemande').value = '';
                        document.getElementById('numTemporaire').value = '';
                        document.getElementById('numPortee').value = '';
                        document.getElementById('dossierNonSigne').checked = false;
                        document.getElementById('clicked').checked = false;
                        setIsClickedDisabled(true);
                        document.getElementById('activateClicked').checked = false;
                        document.getElementById('resetButton').classList.add('d-none');
                    }}>Réinitialiser</button>
                    <button type="submit" className="btn btn-primary w-50" onClick={(event) => {
                        event.preventDefault();
                        const search = {
                            'idDossier': document.getElementById('idDossier').value.replace(/\s/g, ''), // Retire les espaces de la recherche
                            'numDossier': document.getElementById('numDossier').value.replace(/\s/g, ''),
                            'numDemande': document.getElementById('numDemande').value.replace(/\s/g, ''),
                            'numTemporaire': document.getElementById('numTemporaire').value.replace(/\s/g, ''),
                            'dossierNonSigne': document.getElementById('dossierNonSigne').checked,
                            'numPortee': document.getElementById('numPortee').value.replace(/\s/g, ''),
                            ...(isClickedDisabled ? {} : { 'clicked': document.getElementById('clicked').checked })
                        };
                        console.log(search);
                        getData(search);
                        document.getElementById('resetButton').classList.remove('d-none');
                    }}>Rechercher</button>
                </div>
            </form>
            <div className="dossierList vstack gap-3 whiteBox">
                <div className="p-2 hstack">
                    <div>
                        <h4>Liste des dossiers</h4>
                    </div>
                    <div className='ms-auto'>
                        <button type="button" className="btn btn-outline-primary">Insérer</button>
                    </div>
                </div>
                <div className="p-2 hstack">
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type="text" placeholder="10" style={{ width: "30px", height: "30px", textAlign: "center", backgroundColor: "rgba(217, 217, 217, 0.50)", border: "0", borderRadius: ".25rem" }} onChange={e => {
                                setLimit(e.target.value ? e.target.value : 10);
                                setTotalPages(Math.ceil(data.length / (e.target.value ? e.target.value : 10)));
                            }} />
                        </div>
                        <div className="ps-2">par pages</div>
                    </div>
                    <div className='ms-auto'>
                        <p>{data.length} {data.length > 1 ? 'dossiers trouvés' : 'dossiers trouvé'}</p>
                    </div>
                </div>
                <div className="p-2" style={{ width: "100%" }}>
                    <TableTemplate
                        theadData={header}
                        tbodyData={data.slice((currentPage - 1) * limit, currentPage * limit)}
                        isLoading={isLoading}
                        limit={limit}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                />
            </div>
        </div>
    );
}

export default Dossiers;
