// Code: frontend/src/pages/Dossiers/Fiche/Fiche.js

import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';
import './Fiche.css';
import LogoICAD from '../../../assets/images/LogoICAD';
import TableTemplate from '../../../components/TableTemplate/TableTemplate';
import { NavLink } from 'react-router-dom';
import DOMPurify from 'dompurify';
import OpenLink from '../../../components/OpenLink/OpenLink';
import { axiosCore } from "../../../utils/http-core-service";
import { showICAD } from '../../../utils/utils';

const Fiche = () => {
    const { id } = useParams();
    const [dossier, setDossier] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [icadResultChaton, setIcadResultChaton] = useState([]);
    const [motherICADInfos, setMotherICADInfos] = useState(null);
    const [fatherICADInfos, setFatherICADInfos] = useState(null);
    const [hasPedigree, setHasPedigree] = useState(false);
    const [commentaires, setCommentaires] = useState(null);
    const [toggleComments, setToggleComments] = useState(false);
    const [isDSNOpened, setisDSNOpened] = useState(false);
    const [isPedigreeOpened, setisPedigreeOpened] = useState(false);
    const affixePortee = dossier && dossier?.infosGenerales?.articleAffixe !== "NEANT" && dossier?.infosGenerales?.nomAffixe;
    const [myLoofRequests, setMyLoofRequests] = useState(null);
    const [originColor, setOriginColor] = useState({
        "RIEX1": "badge bg-riex",
        "RIEX2": "badge bg-riex",
        "RIEX3": "badge bg-riex",
        "RIEX4": "badge bg-riex",
        "RIEX": "badge bg-riex",
        "RIA1": "badge bg-ria",
        "RIA2": "badge bg-ria",
        "RIA3": "badge bg-ria",
        "RIA4": "badge bg-ria",
        "RF": "badge bg-rf",
    });

    const setIcadResultChatonAtIndex = (index) => {
        return (result) => {
            const newIcadResultChaton = [...icadResultChaton];
            newIcadResultChaton[index] = result;
            setIcadResultChaton(newIcadResultChaton);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosCore.get(`/api/saillie/${id}`);
                setDossier(response.data);
                const myLoofRequests = await axiosCore.get(`/api/demandes/dossier/${id}`);
                setMyLoofRequests(myLoofRequests.data);
                setIsLoading(false);
            } catch (error) {
                console.log("Error fetching data: ", error);
                setIsLoading(false);
                setError(error);
            }
        }
        fetchData();
    }, [id]);

    useEffect(() => {
        console.log(dossier);
        if (dossier && (dossier.pedigree.dateReceptionFormulairePedigree !== "01/01/1970" && dossier.pedigree.dateReceptionFormulairePedigree !== "00/00/0000")) {
            setHasPedigree(true);
        }
        if (dossier && dossier.infosGenerales.commentaires) {
            dossier.infosGenerales.commentaires = dossier.infosGenerales.commentaires.replace(/\n\n/g, "\n"); // Supprime les doubles sauts de ligne
            setCommentaires(dossier.infosGenerales.commentaires.split("\n")); // Split les commentaires à chaque saut de ligne
        }
    }, [dossier]);

    useEffect(() => {
        if (document.getElementById('DSN') && document.getElementById('Pedigree')) {
            if (isDSNOpened) {
                document.getElementById('Pedigree').style.display = 'none';
            } else {
                document.getElementById('Pedigree').style.display = 'flex';
            }
            if (isPedigreeOpened) {
                document.getElementById('DSN').style.display = 'none';
            }
            else {
                document.getElementById('DSN').style.display = 'flex';
            }
        }
    }, [isDSNOpened, isPedigreeOpened]);

    const checkICAD = (icad) => {
        console.log(icad);
        const res = true; //tmp TODO
        if (res) {
            return { backgroundColor: 'rgba(0, 255, 0, 0.4)' };
        } else {
            return { backgroundColor: 'rgba(255, 0, 0, 0.3)' };
        }
        // TODO, mettre un message d'erreur si icad injoignable
    }

    const checkLoofStatus = (status) => {
        switch (status) {
            case "Transmise":
                return <div className="">Nouvelle</div>
            case "Prise en compte":
                return <div className="badge bg-warning">Prise en compte</div>
            case "En cours de traitement":
                return <div className="badge bg-primary">En cours de traitement</div>
            case "En attente de réponse":
                return <div className="badge bg-warning">En attente de réponse</div>
            case "Validée":
                return <div className="badge bg-success">Validée</div>
            case "Refusée":
                return <div className="badge bg-danger">Refusée</div>
            default:
                return <div className="badge bg-secondary">{status}</div>
        }
    }

    const maximizeDiv = (div) => {
        if (div === 'DSN') {
            setisDSNOpened(true);
            setisPedigreeOpened(false);
        } else if (div === 'Pedigree') {
            setisDSNOpened(false);
            setisPedigreeOpened(true);
        }
    }

    const normalizeDiv = (div) => {
        if (div === 'DSN') {
            setisDSNOpened(false);
            setisPedigreeOpened(false);
        } else if (div === 'Pedigree') {
            setisDSNOpened(false);
            setisPedigreeOpened(false);
        }
    }

    const findOrigin = (origin) => {
        const originKey = Object.keys(originColor).find(key => origin.includes(key));
        const loofKey = Object.keys(originColor).find(key => origin.includes('LOOF'));
        if (originKey && loofKey) {
            return <div className='d-flex'><div className={`${originColor[originKey]}`}>{originKey}</div>&nbsp;{origin}</div>
        } else {
            return origin;
        }
    }

    const goToRequest = (idRequest) => {
        if (idRequest) {
            return () => {
                window.open(`/demandes/${idRequest}`, '_blank');
            }
        } else {
            return () => {
                // TODO à rediriger vers le pdf scanné s'il y en a un
                console.log("TODO: Rediriger vers le pdf scanné");
            }
        }
    }

    return (
        <div className="Content">
            <div className='fil-arenae d-flex align-items-center mb-4'>
                <h5 className='mb-0'>
                    <NavLink className='link' to={`/dossiers`}>Dossier</NavLink>
                </h5>
                <div className='mx-2'> &gt; </div>
                <h5 className='mb-0'>Fiche Dossier</h5>
            </div>
            <a href={`/dossiers/` + (parseInt(id) - 1)}>dossier précédent / </a>
            <a href={`/dossiers/` + (parseInt(id) + 1)}>dossier suivant</a>
            {isLoading && <IsLoading />}
            {!isLoading && dossier &&
                <div className="d-flex flex-column gap-2">
                    <div id='topBox' className="whiteBox d-flex justify-content-between"> {/* Bloc haut */}
                        <div className="d-flex flex-column gap-2 align-items-start">
                            <h5>Dossier n°{dossier.header.numDossier}</h5>
                            <h5>N° de portée : {dossier.header.numPortee}</h5>
                            <h6>N° temporaire : {dossier.header.numTemporaire}</h6>
                        </div>
                        <div className="d-flex flex-column gap-2 align-items-end">
                            <h5>Date de naissance : {dossier.header.dateNaissance}</h5>
                            <code className="text-muted">ID : {dossier.header.id}</code>
                        </div>
                    </div>
                    <div id='middleBox' className="d-flex justify-content-between gap-4"> {/* Bloc milieu */}
                        <div id='DSN' className="whiteBox flex-column align-items-start gap-2"> {/* Bloc DSN */}
                            <div className="d-flex justify-content-between align-items-center w-100 mb-3 flex-wrap">
                                <div className="d-flex gap-2 align-items-center">
                                    <h5 className='mb-0'>DSN</h5>
                                    {!isDSNOpened && <button type="button" className="btn btn-outline-primary btn-sm" style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                                        onClick={() => maximizeDiv('DSN')}>+</button>}
                                    {isDSNOpened && <button type="button" className="btn btn-outline-primary btn-sm" style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                                        onClick={() => normalizeDiv('DSN')}>-</button>}
                                    {isDSNOpened &&
                                        <div className="d-flex actionButtons gap-2">
                                            <button type="button" className="btn btn-primary btn-sm badge">Modifier</button>
                                            <button type="button" className="btn btn-primary btn-sm badge">Fichiers</button>
                                            <button type="button" className="btn btn-primary btn-sm badge">Voir le reçu</button>
                                            <button type="button" className="btn btn-primary btn-sm badge">Demande de pedigree</button>
                                            <button type="button" className="btn btn-primary btn-sm badge">Vérifier</button>
                                            <button type="button" className="btn btn-primary btn-sm badge">Couleurs chatons</button>
                                            <button type="button" className="btn btn-primary btn-sm badge">Voir la facture</button>
                                            <button type="button" className="btn btn-primary btn-sm badge">Echanges avec l'éleveur</button>
                                            <button type="button" className="btn btn-primary btn-sm badge">Historique</button>
                                        </div>}
                                </div>
                                <img src="https://via.placeholder.com/30" alt="notifications" />
                            </div>
                            <div className="d-flex flex-row gap-5 w-100">
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex gap-2 flex-wrap">
                                        <h6 className="mb-0">Type :</h6>
                                        <p className="mb-0">{(!myLoofRequests.DSN.id) ? "Papier" : "MyLOOF"}</p>
                                        <button type="button" className="btn btn-primary btn-sm badge" style={{ height: 'fit-content' }} onClick={goToRequest(myLoofRequests.DSN?.id)}>{
                                            (!myLoofRequests.DSN.id)
                                                ? "Voir la pièce jointe"
                                                : "Demande n°" + myLoofRequests.DSN?.id}
                                        </button>
                                    </div>
                                    <div className="d-flex gap-2 flex-wrap">
                                        <h6 className="mb-0">Statut :</h6>
                                        {dossier.DSN.statusRequestDSN && checkLoofStatus(dossier.DSN.statusRequestDSN)}
                                        {!dossier.DSN.statusRequestDSN && checkLoofStatus("à insérer *tmp TODO*")} {/* TODO "à insérer" tmp */}
                                    </div>
                                    <div className="d-flex gap-2 align-items-center flex-wrap">
                                        <h6 className="mb-0">Date de réception formulaire :</h6>
                                        <p className="mb-0">{(dossier.DSN.dateReceptionFormulaireDSN === "01/01/1970") ? "Non réceptionné" : dossier.DSN.dateReceptionFormulaireDSN}</p>
                                    </div>
                                </div>
                                {isDSNOpened &&
                                    <div className="d-flex flex-row flex-wrap gap-5">
                                        <div className="d-flex gap-4 flex-column">
                                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                                <h6 className="mb-0">Date de saillie déclarée :</h6>
                                                <p className="mb-0">{dossier.DSN.dateSaillieDeclaree}</p>
                                            </div>
                                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                                <h6 className="mb-0">Durée de gestation calculée :</h6>
                                                <p className="mb-0">{dossier.DSN.dureeGestation} jours</p>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-4 flex-column">
                                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                                <h6 className="mb-0">Nombre de chatons :</h6>
                                                <p className="mb-0">{dossier.DSN.nbChaton}</p>
                                            </div>
                                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                                <h6 className="mb-0">Nombre de chatons décédés :</h6>
                                                <p className="mb-0">{dossier.DSN.nbDeces}</p>
                                            </div>
                                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                                <h6 className="mb-0">Total :</h6>
                                                <p className="mb-0">{(dossier.DSN.nbChaton - dossier.DSN.nbDeces)}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-4 flex-column">
                                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                                <h6 className="mb-0">Chatons avec pedigree :</h6>
                                                <p className="mb-0">{dossier.DSN.nbPedigrees}</p>
                                            </div>
                                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                                <h6 className="mb-0">Chatons sans pedigree :</h6>
                                                <p className="mb-0">{(dossier.DSN.nbChaton - dossier.DSN.nbPedigrees)}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="d-flex justify-content-between align-items-end mt-3 flex-wrap flex-row" style={{ width: '100%' }}>
                                <div className='d-flex flex-column' {...!isDSNOpened && { style: { width: '100%' } }} {...isDSNOpened && { style: { width: 'auto' } }}> {/* Bloc de profil */}
                                    {isDSNOpened &&
                                        <div className='d-flex flex-row gap-2'>
                                            <div className="d-flex gap-2 align-items-center" style={{ fontSize: '0.8rem' }}>
                                                <p className="mb-0" style={{ fontWeight: '600' }}>Créé le :</p>
                                                <p className="mb-0">{dossier.DSN.createDateDSN}</p>
                                            </div>
                                            <div className='profileBadge'
                                                onMouseEnter={(event) => {
                                                    const profileBadgeDetails = event.currentTarget.querySelector('.profileBadgeDetails');
                                                    profileBadgeDetails.style.opacity = '1';
                                                    profileBadgeDetails.style.transform = 'translateY(100%) translateX(-40%)';
                                                }}
                                                onMouseLeave={(event) => {
                                                    const profileBadgeDetails = event.currentTarget.querySelector('.profileBadgeDetails');
                                                    profileBadgeDetails.style.opacity = '0';
                                                    profileBadgeDetails.style.transform = 'translateY(50%) translateX(-40%)';
                                                }}
                                            >
                                                {dossier.DSN.createByDSN}
                                                <div className="profileBadgeDetails">{dossier.DSN.createByDSNFirstname} {dossier.DSN.createByDSNLastname}</div>
                                            </div>
                                        </div>
                                    }
                                    <div className='d-flex flex-row gap-2 justify-content-between'>
                                        <div className="d-flex gap-2 align-items-center" style={{ fontSize: '0.8rem' }}>
                                            <p className="mb-0" style={{ fontWeight: '600' }}>Dernière modification :</p>
                                            <p className="mb-0">{dossier.DSN.updateDateDSN}</p>
                                        </div>
                                        <div className='profileBadge'
                                            onMouseEnter={(event) => {
                                                const profileBadgeDetails = event.currentTarget.querySelector('.profileBadgeDetails');
                                                profileBadgeDetails.style.opacity = '1';
                                                profileBadgeDetails.style.transform = 'translateY(100%) translateX(-40%)';
                                            }}
                                            onMouseLeave={(event) => {
                                                const profileBadgeDetails = event.currentTarget.querySelector('.profileBadgeDetails');
                                                profileBadgeDetails.style.opacity = '0';
                                                profileBadgeDetails.style.transform = 'translateY(50%) translateX(-40%)';
                                            }}
                                        >
                                            {dossier.DSN.updateByDSN}
                                            <div className="profileBadgeDetails">{dossier.DSN.updateByDSNFirstname} {dossier.DSN.updateByDSNLastname}</div>
                                        </div>
                                    </div>
                                </div>
                                {isDSNOpened && hasPedigree &&
                                    <div className='d-flex flex-row gap-2'>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => maximizeDiv('Pedigree')}>Voir la fiche pedigree</button>
                                    </div>
                                }
                                {isDSNOpened && !hasPedigree &&
                                    <div className='d-flex flex-row gap-2'>
                                        <button type="button" className="btn btn-primary btn-sm">Ajouter un pedigree</button>
                                    </div>
                                }
                            </div>
                        </div>
                        {hasPedigree &&
                            <div id='Pedigree' className="whiteBox flex-column align-items-start gap-2"> {/* Bloc Pedigree */}
                                <div className="d-flex justify-content-between align-items-center w-100 mb-3 flex-wrap">
                                    <div className="d-flex gap-2 align-items-center">
                                        <h5 className='mb-0'>Pedigree</h5>
                                        {!isPedigreeOpened && <button type="button" className="btn btn-outline-primary btn-sm" style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                                            onClick={() => maximizeDiv('Pedigree')}>+</button>}
                                        {isPedigreeOpened && <button type="button" className="btn btn-outline-primary btn-sm" style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                                            onClick={() => normalizeDiv('Pedigree')}>-</button>}
                                        {isPedigreeOpened &&
                                            <div className="d-flex actionButtons gap-2">
                                                <button type="button" className="btn btn-primary btn-sm badge">Modifier</button>
                                                <button type="button" className="btn btn-primary btn-sm badge">Fichiers</button>
                                                <button type="button" className="btn btn-primary btn-sm badge">*TODO*</button>
                                            </div>}
                                    </div>
                                    <img src="https://via.placeholder.com/30" alt="notifications" />
                                </div>
                                <div className="d-flex flex-row gap-5 w-100">
                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex gap-2 flex-wrap">
                                            <h6 className="mb-0">Type :</h6>
                                            <p className="mb-0">{(!myLoofRequests.PEDIGREE.id) ? "Papier" : "MyLOOF"}</p>
                                            <button type="button" className="btn btn-primary btn-sm badge" style={{ height: 'fit-content' }} onClick={goToRequest(myLoofRequests.PEDIGREE?.id)}>{
                                                (!myLoofRequests.PEDIGREE.id)
                                                    ? "Voir la pièce jointe"
                                                    : "Demande n°" + myLoofRequests.PEDIGREE?.id}
                                            </button>
                                        </div>
                                        <div className="d-flex gap-2 flex-wrap">
                                            <h6 className="mb-0">Statut :</h6>
                                            {dossier.pedigree.statusRequestPedigree && checkLoofStatus(dossier.pedigree.statusRequestPedigree)}
                                            {!dossier.pedigree.statusRequestPedigree && checkLoofStatus("à insérer *tmp TODO*")} {/* TODO "à insérer" tmp */}
                                        </div>
                                        <div className="d-flex gap-2 align-items-center flex-wrap">
                                            <h6 className="mb-0">Date de réception formulaire :</h6>
                                            <p className="mb-0">{(dossier.pedigree.dateReceptionFormulairePedigree === "01/01/1970") ? "Non réceptionné" : dossier.pedigree.dateReceptionFormulairePedigree}</p>
                                        </div>
                                    </div>
                                    {isPedigreeOpened &&
                                        <div className="d-flex gap-5 flex-row">
                                            <div className="d-flex gap-2 flex-column">
                                                <div className="d-flex gap-2 align-items-center flex-wrap">
                                                    <h6 className="mb-0">Date d'enregistrement des pedigrees :</h6>
                                                    <p className="mb-0">{(dossier.pedigree.dateEnregistrementPedigree === "01/01/1970") ? "Non enregistré" : dossier.pedigree.dateEnregistrementPedigree}</p>
                                                </div>
                                                <div className="d-flex gap-2 align-items-center flex-wrap">
                                                    <h6 className="mb-0">Date d'envoi des pedigrees :</h6>
                                                    <p className="mb-0">{(dossier.pedigree.dateEnvoiPedigree === "01/01/1970") ? "Non envoyés" : dossier.pedigree.dateEnvoiPedigree}</p>
                                                </div>
                                                <div className="d-flex gap-2 align-items-center flex-wrap" style={{ maxWidth: '300px' }}>
                                                    <h6 className="mb-0">Mention :</h6>
                                                    <p className="mb-0"><i>{(dossier.pedigree.mentionPedigree === "") ? "Pas de mention" : dossier.pedigree.mentionPedigree}</i></p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-2 flex-row flex-wrap">
                                                <div className="d-flex gap-2 align-items-start flex-wrap">
                                                    <h6 className="mb-0">Pedigrees enregistrés :</h6>
                                                    <select className="form-select w-auto" aria-label="Default select">
                                                        <option selected value="1972">1972</option>
                                                        <option value="1971">1971</option>
                                                        <option value="1970">1970</option>
                                                    </select>
                                                </div>
                                                <div className="d-flex flex-column gap-2" style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                                    <ul className="list-group">
                                                        <button type="button" className="list-group-item d-flex justify-content-center align-items-center btn btn-primary">
                                                            02/02/1972 - 12:00
                                                        </button>
                                                        <button type="button" className="list-group-item d-flex justify-content-center align-items-center btn btn-primary">
                                                            26/03/1972 - 16:00
                                                        </button>
                                                        <button type="button" className="list-group-item d-flex justify-content-center align-items-center btn btn-primary">
                                                            12/12/1972 - 08:00
                                                        </button>
                                                        <button type="button" className="list-group-item d-flex justify-content-center align-items-center btn btn-primary">
                                                            12/12/1972 - 08:00
                                                        </button>
                                                        <button type="button" className="list-group-item d-flex justify-content-center align-items-center btn btn-primary">
                                                            12/12/1972 - 08:00
                                                        </button>
                                                        <button type="button" className="list-group-item d-flex justify-content-center align-items-center btn btn-primary">
                                                            12/12/1972 - 08:00
                                                        </button>
                                                        <button type="button" className="list-group-item d-flex justify-content-center align-items-center btn btn-primary">
                                                            12/12/1972 - 08:00
                                                        </button>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {isPedigreeOpened &&
                                    <div className="d-flex gap-2 mt-4 w-100 flex-column"> {/* Bloc chatons */}
                                        <h6 className="mb-0">Chatons :</h6>
                                        <div style={{ overflowX: 'auto' }}> {/* Tableau chatons */}
                                            <TableTemplate
                                                theadData={[
                                                    { key: 'nomChat', label: 'Nom' },
                                                    { key: 'intituleRace', label: 'Race' },
                                                    { key: 'intituleRobe', label: 'Robe' },
                                                    { key: 'intituleYeux', label: 'Yeux' },
                                                    { key: 'sexeChaton', label: 'Sexe' },
                                                    { key: 'numOrigineChaton', label: 'N° Origine' },
                                                    { key: 'numIdentificationChaton', label: 'Identification' },
                                                    { key: 'verifICAD', label: 'Vérif. ICAD' },
                                                    { key: 'demDnaChaton', label: 'Dem DNA' },
                                                    { key: 'genetiqueChaton', label: 'Gen' },
                                                ]}
                                                tbodyData={dossier.chatons.map((chaton, index) => {
                                                    return {
                                                        ...chaton,
                                                        verifICAD: <button type="button" className="btn btn-outline-light p-1"
                                                            style={{ ...checkICAD(chaton.numIdentificationChaton), border: '1px solid black' }}
                                                            onClick={() => icadResultChaton[index] !== undefined ? showICAD({ infos: icadResultChaton[index] }) : showICAD({ numIdentification: chaton.numIdentificationChaton, setIcadResult : setIcadResultChatonAtIndex(index) })}>
                                                            <LogoICAD width="50" height="auto" />
                                                        </button>
                                                    }
                                                }
                                                )}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="d-flex justify-content-between align-items-end mt-3 flex-wrap flex-row" style={{ width: '100%' }}>
                                    <div className='d-flex flex-column' {...!isPedigreeOpened && { style: { width: '100%' } }} {...isPedigreeOpened && { style: { width: 'auto' } }}> {/* Bloc de profil */}
                                        {isPedigreeOpened &&
                                            <div className='d-flex flex-row gap-2'>
                                                <div className="d-flex gap-2 align-items-center" style={{ fontSize: '0.8rem' }}>
                                                    <p className="mb-0" style={{ fontWeight: '600' }}>Créé le :</p>
                                                    <p className="mb-0">{(dossier.pedigree.createDatePedigree === null) ? <i>Non implémenté au moment de la création du dossier</i> : dossier.pedigree.createDatePedigree}</p>
                                                </div>
                                                <div className='profileBadge'
                                                    onMouseEnter={(event) => {
                                                        const profileBadgeDetails = event.currentTarget.querySelector('.profileBadgeDetails');
                                                        profileBadgeDetails.style.opacity = '1';
                                                        profileBadgeDetails.style.transform = 'translateY(100%) translateX(-40%)';
                                                    }}
                                                    onMouseLeave={(event) => {
                                                        const profileBadgeDetails = event.currentTarget.querySelector('.profileBadgeDetails');
                                                        profileBadgeDetails.style.opacity = '0';
                                                        profileBadgeDetails.style.transform = 'translateY(50%) translateX(-40%)';
                                                    }}
                                                >
                                                    {(dossier.pedigree.createByPedigreeInitials === null) ? <i>NULL</i> : dossier.pedigree.createByPedigreeInitials}
                                                    <div className="profileBadgeDetails">{(dossier.pedigree.createByPedigreeFirstname === null && dossier.pedigree.createByPedigreeLastname === null) ? <i>Non implémenté au moment de la création du dossier</i> : (dossier.pedigree.updateByPedigreeFirstname + " " + dossier.pedigree.createByPedigreeLastname)}</div>
                                                </div>
                                            </div>
                                        }
                                        <div className='d-flex flex-row gap-2 justify-content-between'>
                                            <div className="d-flex gap-2 align-items-center" style={{ fontSize: '0.8rem' }}>
                                                <p className="mb-0" style={{ fontWeight: '600' }}>Dernière modification :</p>
                                                <p className="mb-0">{(dossier.pedigree.updateDatePedigree === null) ? <i>Non implémenté au moment de la création du dossier</i> : dossier.pedigree.updateDatePedigree}</p>
                                            </div>
                                            <div className='profileBadge'
                                                onMouseEnter={(event) => {
                                                    const profileBadgeDetails = event.currentTarget.querySelector('.profileBadgeDetails');
                                                    profileBadgeDetails.style.opacity = '1';
                                                    profileBadgeDetails.style.transform = 'translateY(100%) translateX(-40%)';
                                                }}
                                                onMouseLeave={(event) => {
                                                    const profileBadgeDetails = event.currentTarget.querySelector('.profileBadgeDetails');
                                                    profileBadgeDetails.style.opacity = '0';
                                                    profileBadgeDetails.style.transform = 'translateY(50%) translateX(-40%)';
                                                }}
                                            >
                                                {(dossier.pedigree.updateByPedigreeInitials === null) ? <i>NULL</i> : dossier.pedigree.updateByPedigreeInitials}
                                                <div className="profileBadgeDetails">{(dossier.pedigree.updateByPedigreeFirstname === null && dossier.pedigree.updateByPedigreeLastname === null) ? <i>Non implémenté au moment de la création du dossier</i> : (dossier.pedigree.updateByPedigreeFirstname + " " + dossier.pedigree.updateByPedigreeLastname)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {isPedigreeOpened &&
                                        <div className='d-flex flex-row gap-2'>
                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => maximizeDiv('DSN')}>Voir la fiche DSN</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {!hasPedigree &&
                            <div id='Pedigree' className="creationPed flex-column align-items-center justify-content-center gap-2">
                                <button type="button" className="btn btn-outline-primary btn-lg">+ Ajouter un pedigree</button>
                            </div>
                        }
                    </div>
                    <div className="d-flex gap-2 flex-column flex-wrap mb-4 commentContainer"> {/* Bloc commentaires dossier */}
                        <div className="d-flex gap-2 flex-column flex-wrap">
                            <div className="d-flex gap-2 flex-row align-items-center">
                                <h5 className="mb-0">Commentaires dossier :</h5>
                                <button type="button" className="btn btn-primary badge">Ajouter +</button>
                                <button type="button" className="btn btn-primary badge" onClick={() => setToggleComments(!toggleComments)}>{toggleComments ? "Voir les 3 derniers commentaires" : "Voir tous les commentaires"}</button>
                            </div>
                            <div className='commentBoxFiche' style={{ height: 'auto' }}>
                                {!toggleComments &&
                                    <p className="mb-0">{commentaires ? commentaires.slice(0, 3).map((comment, index) => {
                                        return <p key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment) }}></p> // Affiche les 3 derniers commentaire (avec DOMPurify pour éviter les injections de code)
                                    }
                                    ) : <i>Aucun commentaire</i>}</p> // Affiche les 2 derniers commentaire
                                }
                                {toggleComments &&
                                    <p className="mb-0">{commentaires ? commentaires.map((comment, index) => {
                                        return <p key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment) }}></p> // Affiche tous les commentaires (avec DOMPurify pour éviter les injections de code)
                                    }
                                    ) : <i>Aucun commentaire</i>}</p> // Affiche tous les commentaires
                                }
                            </div>
                        </div>
                    </div>
                    <div id='bottomBox' className="whiteBox"> {/* Bloc bas */}
                        <div className='row w-100 h-100 m-0 gap-3'>
                            <div className='col p-0'> {/* Bloc mère */}
                                <div className='d-flex gap-2 align-items-start flex-wrap'>
                                    <div className='title'>Femelle :</div>
                                    <OpenLink url={`/chats/${dossier.femelle.id}`} content={dossier.femelle.nomChat} />
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Race :</div>
                                    <div>{dossier.femelle.intituleRace}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Robe :</div>
                                    <div>{dossier.femelle.intituleRobe}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>N° d'origine :</div>
                                    {findOrigin(dossier.femelle.numOrigine)}
                                </div>
                                {dossier.femelle.titre &&
                                    <div className='d-flex gap-1 align-items-start flex-wrap'>
                                        <div className='title'>Titre(s) :</div>
                                        {dossier.femelle.titre}
                                    </div>
                                }
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Propriétaire :</div>
                                    <OpenLink url={`/eleveurs/${dossier.femelle.idEleveur}`} content={dossier.femelle.nomEleveur} />
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>N° d'identification :</div>
                                    <div>{dossier.femelle.numIdentification}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <button type="button" className="btn btn-outline-light p-1" style={{ border: '1px solid black', ...checkICAD(dossier.femelle.numIdentification) }}
                                        onClick={() => motherICADInfos !== null ? showICAD({ infos: motherICADInfos }) : showICAD({ numIdentification: dossier.femelle.numIdentification, setIcadResult : setMotherICADInfos })}>

                                        <LogoICAD width="50" height="auto" />
                                    </button>
                                </div>
                            </div>
                            <div className='col p-0'> {/* Bloc père */}
                                <div className='d-flex gap-2 align-items-start flex-wrap'>
                                    <div className='title'>Étalon :</div>
                                    <OpenLink url={`/chats/${dossier.etalon.id}`} content={dossier.etalon.nomChat} />
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Race :</div>
                                    <div>{dossier.etalon.intituleRace}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Robe :</div>
                                    <div>{dossier.etalon.intituleRobe}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>N° d'origine :</div>
                                    {findOrigin(dossier.etalon.numOrigine)}
                                </div>
                                {dossier.etalon.titre &&
                                    <div className='d-flex gap-1 align-items-start flex-wrap'>
                                        <div className='title'>Titre(s) :</div>
                                        {dossier.etalon.titre}
                                    </div>
                                }
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Propriétaire :</div>
                                    <OpenLink url={`/eleveurs/${dossier.etalon.idEleveur}`} content={dossier.etalon.nomEleveur} />
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>N° d'identification :</div>
                                    <div>{dossier.etalon.numIdentification}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <button type="button" className="btn btn-outline-light p-1" style={{ border: '1px solid black', ...checkICAD(dossier.etalon.numIdentification) }}
                                        onClick={() => fatherICADInfos !== null ? showICAD({ infos: fatherICADInfos }) : showICAD({ numIdentification: dossier.etalon.numIdentification, setIcadResult : setFatherICADInfos })}>
                                        <LogoICAD width="50" height="auto" />
                                    </button>
                                </div>
                            </div>
                            <div className='col p-0'> {/* Bloc infos générales */}
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Date de réception DSN :</div>
                                    <div>{dossier.DSN.dateReceptionFormulaireDSN}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Date de réception Pedigree :</div>
                                    <div>{(dossier.pedigree.dateReceptionFormulairePedigree === "01/01/1970") ? "Non réceptionné" : dossier.pedigree.dateReceptionFormulairePedigree}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Date de saillie déclarée :</div>
                                    <div>{dossier.DSN.dateSaillieDeclaree}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Date de naissance :</div>
                                    <div>{dossier.infosGenerales.dateNaissance}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Nombre de chatons :</div>
                                    <div>{dossier.infosGenerales.nbChaton}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Groupement de race de la portée :</div>
                                    <div>{dossier.infosGenerales.nomRaceGroupe}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Publication autorisée :</div>
                                    <div>{dossier.infosGenerales.publicationAutorisee ? "oui" : "non"}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Accord Agria :</div>
                                    <div>{dossier.infosGenerales.accordAgria ? "oui" : "non"}</div>
                                </div>
                                <div className='d-flex gap-1 align-items-start flex-wrap'>
                                    <div className='title'>Affixe de la portée :</div>
                                    <OpenLink url={`/affixes/${dossier.infosGenerales.idAffixe}`} content={affixePortee} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger le dossier ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                    {/* <p><b>Message de l'API:</b> {error.response.data.error}</p> */}
                    <p><b>Message de l'API:</b> {error.response.data.error.split("\n").map((item, key) => {
                        return <span key={key}>{item}<br /></span>
                    }
                    )}</p>
                </div>
            }
        </div>
    )
}

export default Fiche;
