import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IsLoading from '../../../components/IsLoading/IsLoading';
import {axiosCore} from "../../../utils/http-core-service";

const FicheAgria = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [affixe, setAffixe] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosCore.get(`/api/affixe/${id}`);

                setAffixe(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log("Error fetching data: ", error);
                setIsLoading(false);
                setError(error);
            }
        }
        fetchData();
    }, [id]);
    return (
        <div className="Content">
            {isLoading && <IsLoading />}
            {!isLoading && affixe &&
            <div>
                <h2 className='mb-4'>Fiche Agria</h2>
                <div className="d-flex flex-column gap-2">
                    <div id='topBox' className="whiteBox">
                        <div className="d-flex flex-column gap-2 align-items-start">
                            <h5><b>Nom d'Affixe / N° d'Affixe: </b> {affixe.nomAffixe} / {affixe.numAffixe}</h5>
                        </div>
                    </div>
                </div>
            </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <p>Impossible de charger l'éleveur ID {id}</p>
                    <p><b>Erreur:</b> {error.message}</p>
                </div>
            }
        </div>
    );
}

export default FicheAgria;
